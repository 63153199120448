<div
  class="modal fade in"
  *ngIf="tripEvents && tripEvents.length !== 0"
  id="sortModal"
  [style.display]="display"
  [style.opacity]="opacity"
  tabindex="-1"
  aria-labelledby="sortModal"
  aria-hidden="false"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="sortModalLabel"
          i18n="Title for @LoadUnload"
        >
          Loads and Unloads
        </h6>
        <app-spinner *ngIf="!existingRow"></app-spinner>
        <button
          type="button"
          class="btn-close"
          (click)="obliterate()"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="form-floating mb-3">
          <div
            style="width: auto; overflow-x: scroll; overflow-y: scroll"
            class="table-responsive custom-scroll"
          >
            <table
              style="margin-top: 15px"
              class="table table-hover table-bordered"
              [class.darkModeCustom]="isDarkMode"
            >
              <thead>
                <tr>
                  <th
                    class="text-center align-middle hoverable"
                    scope="col"
                    [hidden]="!item.isVisible"
                    *ngFor="let item of simpleLoadUnloadColumns; let i = index"
                  >
                    {{ item.danskName }}
                  </th>
                </tr>
              </thead>
              <tbody [hidden]="!existingRow">
                <tr
                  id="orderingunload{{ i }}"
                  [class.bg-even-grey]="i % 2"
                  *ngFor="let item of tripEvents; let i = index"
                >
                  <td id="Approved{{ i }}" class="text-center align-middle">
                    {{ item.isApproved ? "Behandlet" : "Ubehandlet" }}
                  </td>
                  <td id="Type{{ i }}" class="text-center align-middle">
                    {{ item.type && getDanishEventType(item.type) }}
                  </td>
                  <td id="Note{{ i }}" class="text-center align-middle">
                    {{ item.note }}
                  </td>
                  <td id="ClientID{{ i }}" class="text-center align-middle">
                    {{ item.clientIdNumber }}
                  </td>
                  <td id="ClientName{{ i }}" class="text-center align-middle">
                    {{ item.clientName }}
                  </td>
                  <td id="Numberplate{{ i }}" class="text-center align-middle">
                    {{ item.carNumber }}
                  </td>
                  <td id="Totaltime{{ i }}" class="text-center align-middle">
                    {{ item.tripTime }}
                  </td>
                  <td id="TripTotalKm{{ i }}" class="text-center align-middle">
                    {{ item.tripKm }}
                  </td>
                  <td id="DriverID{{ i }}" class="text-center align-middle">
                    {{ item.driverName }}
                  </td>
                  <td id="Trailer{{ i }}" class="text-center align-middle">
                    {{ item.trailerNumber }}
                  </td>
                  <td
                    id="ProductNameUnloading{{ i }}"
                    class="text-center align-middle"
                  >
                    {{ item.productName }}
                  </td>
                  <td
                    id="Weightrelieved{{ i }}"
                    class="text-center align-middle"
                  >
                    {{ item.weight }}
                  </td>
                  <td id="VendorID{{ i }}" class="text-center align-middle">
                    {{ item.vendorIdNumber }}
                  </td>
                  <td id="VendorName{{ i }}" class="text-center align-middle">
                    {{ item.vendorName }}
                  </td>
                  <td id="Reference{{ i }}" class="text-center align-middle">
                    {{ item.reference }}
                  </td>
                  <td id="Orderinfo{{ i }}" class="text-center align-middle">
                    {{ item.comment }}
                  </td>
                  <td
                    id="Unloadingphotos{{ i }}"
                    class="text-center align-middle"
                  >
                    <span *ngIf="item?.photos">
                      <a
                        *ngIf="(item?.photos)!.length > 0"
                        (click)="getUrls(item?.photos, item)"
                        class="btn btn-sm btn-link py-0 p-md-0"
                      >
                        <i class="bi bi-card-image"></i>
                      </a>
                    </span>
                    <br />
                    <span *ngIf="muted">
                      <a class="btn btn-sm btn-link py-0 p-md-0">
                        <label for="img" class="hoverable" i18n="Label for img"
                          ><i
                            class="bi bi-plus-lg"
                            *ngIf="item.tripEventId"
                            (click)="selectedId = item.tripEventId"
                          ></i
                        ></label>
                        <input
                          type="file"
                          accept="image/*"
                          id="img"
                          (change)="addImage($event)"
                          style="display: none"
                          multiple
                        />
                      </a>
                    </span>
                  </td>
                  <td
                    id="TimeofUnloading{{ i }}"
                    class="text-center align-middle"
                  >
                    {{ item.eventTime }}
                  </td>
                  <td
                    id="KmofUnloading{{ i }}"
                    class="text-center align-middle"
                  >
                    {{ item.km }}
                  </td>
                  <td
                    [hidden]="!muted"
                    id="EditofUnloading{{ i }}"
                    class="text-center align-middle"
                  >
                    <a
                      class="btn btn-sm btn-link py-0 p-md-0"
                      (click)="navigateTo(item.tripEventId!)"
                    >
                      <i class="bi bi-pencil me-1"></i>
                      <ng-container i18n="@@edit">Edit</ng-container>
                    </a>
                  </td>
                  <td id="UnloadingGPS{{ i }}" class="text-center align-middle">
                    <a target="_blank" href="{{ item.position }}">Link</a>
                  </td>
                  <td id="Cleaning{{ i }}" class="text-center align-middle">
                    {{ item.cleaning }}
                  </td>
                  <td id="Accessory{{ i }}" class="text-center align-middle">
                    {{ item.accessoriesName }}
                  </td>
                  <td id="Time{{ i }}" class="text-center align-middle">
                    {{ item.duration }}
                  </td>
                  <td id="DiffKm{{ i }}" class="text-center align-middle">
                    {{ item.diffKm }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-image-slider
  id="photoModal"
  [urls]="urls"
  [modalEvents]="usualEvents"
  (modalPhotoEmmiter)="getModalPhotos($event)"
></app-image-slider>
