<div class="row mb-4">
  <div class="col">
    <h2
      *ngIf="!isParkedOverview"
      i18n="Title for the list of company's drivers"
    >
      Drivers logs(Trips)
    </h2>
    <h2 *ngIf="isParkedOverview" i18n="Title for the time logs overview parked">
      Driver logs(Trips log) - Parked
    </h2>
  </div>
</div>
<div class="row mb-4"></div>
<div class="row mb-4">
  <div class="col">
    <app-spinner
      *ngIf="!checkIfDataLoaded()"
      [showWithText]="true"
    ></app-spinner>
    <app-sort-table
      *ngIf="checkIfDataLoaded()"
      (takePhotos)="addPhotos($event)"
      (tenantNoteEmmiter)="updateTenantNote($event)"
      (usualPhotoEmmiter)="updatePhotoEvent($event)"
      (weighPhotoEmmiter)="updatePhotoEvent($event)"
      (modalPhotoEmmiter)="updatePhotoEvent($event)"
      [dateType]="dateType"
      [products]="products"
      [clients]="clients"
      [drivers]="drivers"
      [timeLogs]="repo.trips$"
      [columns]="columns"
      [parkedOverviewSet]="isParkedOverview"
    ></app-sort-table>
  </div>
</div>
