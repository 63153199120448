import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Column, ColumnsRepository } from 'src/app/state/columns.repository';
import { ColumnsService } from 'src/app/state/columns.service';
import {
  CompaniesRepository,
  Company,
} from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { TripEventsService } from 'src/app/state/tripevents.service';
import {
  Product,
  ProductsRepository,
} from '../../../../state/products.repository';
import { ProductsService } from '../../../../state/products.service';
import {
  PhotoEventDto,
  PhotoModalEventDto,
  TenantNoteEvent,
  Trip,
  TripEventFile,
  TripsRepository,
} from '../../../../state/trips.repository';
import { TripsService } from '../../../../state/trips.service';
import { User, UsersRepository } from '../../../../state/users.repository';
import { UsersService } from '../../../../state/users.service';
import { AccessoriesService } from 'src/app/state/accessory.service';
import { CleaningsService } from 'src/app/state/cleaning.service';

@Component({
  selector: 'app-driver-logs-page',
  templateUrl: './driver-logs-page.component.html',
  styleUrls: ['./driver-logs-page.component.scss'],
})
export class DriverLogsPageComponent implements OnInit {
  dateType?: 'day' | ' week' | 'month';
  drivers: User[] | null = null;
  clients: Company[] | null | any = null;
  products: Product[] | null | any = null;
  trips: Trip[] | null | any = null;
  tripsFiltered: Trip[] | null | any = null;

  constructor(
    public authRepo: AuthRepository,
    public repo: TripsRepository,
    public service: TripsService,
    public router: ActivatedRoute,
    public usersRepo: UsersRepository,
    public usersService: UsersService,
    private companyService: CompaniesService,
    public productsRep: ProductsRepository,
    public productsService: ProductsService,
    public companyRepo: CompaniesRepository,
    private columnsService: ColumnsService,
    public columnsRepo: ColumnsRepository,
    private tripEventService: TripEventsService,
    private route: Router,
    private accessoryService: AccessoriesService,
    private cleaningService: CleaningsService,
    private companiesService: CompaniesService
  ) {
    this.router.url.subscribe((x) => {
      x.map((f) => {
        if (f.path.includes('parkedtrips')) {
          this.isParkedOverview = true;
        }
        this.isParkedLoaded = true;
      });
    });
  }
  columns: Column[] | null = null;
  isColumnsLoaded: boolean = false;
  isClientsLoaded: boolean = false;
  isProductsLoaded: boolean = false;
  isParkedLoaded: boolean = false;

  isParkedOverview: boolean = false;

  ngOnInit(): void {
    this.tripEventService.reloadPage().subscribe();

    this.service
      .loadOverview()
      .subscribe((x) => (this.trips = this.repo.trips$));
    this.dateType = this.router.snapshot.data.dateType;
    this.usersService.loadDrivers().subscribe((x) => (this.drivers = x));

    this.companyService.loadAllAsList().subscribe((x) => {
      this.clients = x.filter((f) => f.canBeClient);
      this.isClientsLoaded = true;
    });

    this.productsService.loadAllAsList().subscribe((x) => {
      this.products = x;
      this.isProductsLoaded = true;
    });

    this.columnsService.loadAll().subscribe((x) => {
      this.columns = x;
      this.isColumnsLoaded = true;
    });

    this.accessoryService.loadDeleted().subscribe();
    this.companiesService.loadDeletedAsListwithRepo().subscribe();
    this.productsService.loadDeletedAsListwithRepo().subscribe();
    this.cleaningService.loadDeleted().subscribe();
  }

  checkIfDataLoaded() {
    return (
      this.isColumnsLoaded &&
      this.isClientsLoaded &&
      this.isProductsLoaded &&
      this.isParkedLoaded
    );
  }

  updateTenantNote($event: TenantNoteEvent) {
    this.tripEventService.updateTenantNote($event).subscribe({
      complete: () => {
        this.service.loadOverview().subscribe({
          complete: () => {
            var path = this.isParkedOverview ? '/parkedtrips' : 'trips';
            this.route.navigate([`/drivers/${path}`]);
          },
        });
      },
    });
  }

  addPhotos($event: TripEventFile) {
    this.service.addPhoto($event.id, $event.files).subscribe({
      complete: () => {
        this.service.loadOverview().subscribe({
          complete: () => {
            var path = this.isParkedOverview ? '/parkedtrips' : 'trips';
            this.route.navigate([`/drivers/${path}`]);
          },
        });
      },
    });
  }

  updatePhotoEvent($event: PhotoEventDto | PhotoModalEventDto) {
    if ($event.url) {
      this.service.changePhotoEvent($event.url).subscribe({
        complete: () => {
          this.service.loadOverview().subscribe({
            complete: () => {
              var path = this.isParkedOverview ? '/parkedtrips' : 'trips';
              this.route.navigate([`/drivers/${path}`]);
            },
          });
        },
      });
    }
  }
}
