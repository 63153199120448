<div
  class="modal fade in"
  *ngIf="title && title.length !== 0"
  id="textBoxModal"
  [style.display]="display"
  [style.opacity]="opacity"
  tabindex="-1"
  aria-labelledby="textBoxModal"
  aria-hidden="false"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="textBoxModalLabel"
          i18n="Title for @@textBoxModal"
        >
          {{ title }}
        </h6>
        <button
          type="button"
          class="btn-close"
          (click)="obliterate()"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <form
        [formGroup]="clientsForm"
        [class.isDisabled]="theTrip?.exportedAt"
        class="modal-body py-0"
        (ngSubmit)="save()"
        *ngIf="clientsForm"
      >
        <div class="row mb-3 mt-2 ms-1">
          <div class="col-8 col-md-6 col-lg-4">
            <strong i18n="Title for @@type">Type</strong>
          </div>
          <div class="col-8 col-md-6 col-lg-4">
            <strong i18n="Title for @@client"></strong>
          </div>
          <div class="col-8 col-md-6 col-lg-4">
            <strong i18n="Title for @@editClient">Edit client</strong>
          </div>
        </div>
        <div formArrayName="clientInfos">
          <div class="row" *ngFor="let tripEvent of tevents; let i = index">
            <div class="col-8 col-md-6 col-lg-4">
              {{ getDanishEventType(tripEvent.type!) }}
            </div>
            <div class="col-8 col-md-6 col-lg-4">
              {{ tripEvent.clientName }}
            </div>
            <div class="col-8 col-md-6 col-lg-4">
              <div
                class="form-group form-floating mb-3 me-3"
                *ngIf="clientOptions"
              >
                <app-datalist-select
                  [isCompanies]="true"
                  [isDisabled]="theTrip?.exportedAt !== ''"
                  *ngIf="clientsForm.value.clientInfos[i]?.clientId"
                  [options]="clientOptions"
                  [multiple]="false"
                  [activeValue]="clientsForm.value.clientInfos[i]?.clientId"
                  [optionsDeleted]="clientOptionsDeleted"
                  (activeValueChange)="
                    updateClientFormValue('clientId', $event, i)
                  "
                  label="Clients"
                  i18n-label="Clients label"
                ></app-datalist-select>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!theTrip?.exportedAt" class="form-floating mt-4">
          <div
            style="width: auto; overflow-x: scroll; overflow-y: scroll"
            class="table-responsive custom-scroll"
          >
            <div class="modal-footer justify-content-center">
              <button
                class="btn btn-primary me-2"
                style="width: 45%"
                type="submit"
                i18n="Save button"
              >
                Save
              </button>
              <button
                class="btn btn-secondary"
                style="width: 45%"
                (click)="obliterate()"
                i18n="@@cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
