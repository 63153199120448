import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PipeTransform,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import {
  BulkEProduct,
  ClientEventInfoDto,
  EconomicInfoExtended,
  PhotoEventDto,
  PhotoModalEventDto,
  PushedInvoiceResponse,
  SearchResult,
  SearchState,
  TenantNoteEvent,
  Trip,
  TripDto,
  TripEvent,
  TripEventDto,
  TripLoadDto,
  TripPhoto,
  TripsRepository,
  TripUnloadDto,
} from '../../../../state/trips.repository';
import {
  SortColumn,
  SortDirection,
  SortEvent,
  NgbdSortableHeader,
} from '../sortable.directive';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  tap,
  debounceTime,
  switchMap,
  delay,
  Subscription,
} from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { User } from '../../../../state/users.repository';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import {
  CompaniesRepository,
  Company,
} from 'src/app/state/companies.repository';
import {
  Product,
  ProductsRepository,
} from '../../../../state/products.repository';
import * as dayjs from 'dayjs';
import { UiRepository } from '../../../../state/ui.repository';
import { TripsService } from '../../../../state/trips.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { FilterOptions, WorkdayEvent } from 'src/app/state/workdays.repository';
import { Column, SimpleColumn } from 'src/app/state/columns.repository';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { EProductsRepository } from 'src/app/state/eproducts.repository';
import { PriceType } from 'src/app/state/pricetypes.repository';
import { ClientUpdateModalComponent } from '../client-update-modal/client-update-modal.component';
import { EProductsService } from 'src/app/state/eproducts.service';
import { BaseService } from 'src/app/base-service';
import { ProductsService } from 'src/app/state/products.service';
import { OfficeNoteFormComponent } from '../office-note-form/office-note-form.component';
import { OfficeAddressFormComponent } from '../office-address-form/office-address-form.component';
import { WorkDayEventsService } from 'src/app/state/workdayevents.service';

// function dateFormater(dt: Date): string {
//   return `${dt.getDate().toString().padStart(2, '0')}-${(dt.getMonth() + 1).toString().padStart(2, '0')}
//              ${dt.getFullYear().toString().padStart(4, '0')} ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}:${dt.getSeconds().toString().padStart(2, '0')}`;
// }

function dateFormater(dt: Date): string {
  const year = dt.getFullYear().toString().padStart(4, '0');
  const month = (dt.getMonth() + 1).toString().padStart(2, '0');
  const date = dt.getDate().toString().padStart(2, '0');
  const hours = dt.getHours().toString().padStart(2, '0');
  const minutes = dt.getMinutes().toString().padStart(2, '0');
  const seconds = dt.getSeconds().toString().padStart(2, '0');
  return `${date}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

const compare = (v1: string | number | any, v2: string | number | any) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function convertDurationToSeconds(durationString: string | undefined) {
  var hours = 0;
  var minutes = 0;
  var seconds = 0;

  if (durationString) {
    var parts = durationString.split('. ');
    for (var i = 0; i < parts.length; i++) {
      var part = parts[i];
      if (part.indexOf('h') !== -1) {
        hours = parseInt(part);
      } else if (part.indexOf('m') !== -1) {
        minutes = parseInt(part);
      } else if (part.indexOf('s') !== -1) {
        seconds = parseInt(part);
      }
    }

    var totalSeconds = hours * 60 * 60 + minutes * 60 + seconds;
    return totalSeconds;
  }
  return undefined;
}

function convertDateStringSeconds(dateString: string | undefined) {
  if (dateString) {
    const dateComponents = dateString.split(/[ :\-]/);
    const year = parseInt(dateComponents[2], 10);
    const month = parseInt(dateComponents[1], 10) - 1;
    const day = parseInt(dateComponents[0], 10);
    const hour = parseInt(dateComponents[3], 10);
    const minute = parseInt(dateComponents[4], 10);
    const second = parseInt(dateComponents[5], 10);
    const date = new Date(year, month, day, hour, minute, second);
    const seconds = isNaN(date.getTime()) ? NaN : date.getTime() / 1000;
    return seconds;
  }
  return undefined;
}

function sort(
  dtos: TripDto[],
  column: SortColumn,
  direction: string,
  unloadPosition: string
): TripDto[] {
  let coulumn1: string = '';
  let coulumn2: string = '';

  switch (column) {
    case 'ClientName':
      coulumn1 = 'clientNameStart';
      coulumn2 = 'clientNameEnd';
      break;
    case 'ClientID':
      coulumn1 = 'clientNameStart';
      coulumn2 = 'clientNameEnd';
      break;
    case 'Address':
      coulumn1 = 'pickUpAddress';
      coulumn2 = 'pickUpAddress';
      break;
    case 'chosen':
      coulumn1 = 'exportedAt';
      coulumn2 = 'exportedAt';
      break;
    case 'TripTotalKm':
      coulumn1 = 'tripKm';
      coulumn2 = 'tripKm';
      break;
    case 'Exported':
      coulumn1 = 'exportedAt';
      coulumn2 = 'exportedAt';
      break;
    case 'Weight':
      coulumn1 = 'weightStart';
      coulumn2 = 'weightEnd';
      break;
    case 'TripStartKm':
      coulumn1 = 'kmStart';
      coulumn2 = 'kmStart';
      break;
    case 'TenantNote':
      coulumn1 = 'tenantNote';
      coulumn2 = 'tenantNote';
      break;
    case 'TripEndKm':
      coulumn1 = 'kmEnd';
      coulumn2 = 'kmEnd';
      break;
    case 'ProductName':
      coulumn1 = 'productNameStart';
      coulumn2 = 'productNameStartEnd';
      break;
    case 'Cleaning':
      coulumn1 = 'cleaningStart';
      coulumn2 = 'cleaningStartEnd';
      break;
    case 'Note':
      coulumn1 = 'noteStart';
      coulumn2 = 'noteStartEnd';
      break;
    case 'TripStarttime':
      coulumn1 = 'startEventTime';
      coulumn2 = 'startEventTime';
      break;
    case 'TripEndTime':
      coulumn1 = 'endEventTime';
      coulumn2 = 'endEventTime';
      break;
    case 'Totaltime':
      coulumn1 = 'tripTime';
      coulumn2 = 'tripTime';
      break;
    case 'Reference':
      coulumn1 = 'referenceStart';
      coulumn2 = 'referenceStartEnd';
      break;
    case 'Orderinfo':
      coulumn1 = 'commentStart';
      coulumn2 = 'commentStartEnd';
      break;
    case 'VendorName':
      coulumn1 = 'vendorNameStart';
      coulumn2 = 'vendorNameStartEnd';
      break;
    case 'Accessory':
      coulumn1 = 'accessoryNameStart';
      coulumn2 = 'accessoryNameStartEnd';
      break;

    default:
      coulumn1 = column;
      coulumn2 = column;
      break;
  }
  if (direction === '') {
    return dtos;
  } else {
    return [...dtos].sort((a: any, b: any) => {
      if (column === 'Totaltime') {
        let afirst = convertDurationToSeconds(a[coulumn1]);
        let asecond = convertDurationToSeconds(a[coulumn2]);
        let bfirst = convertDurationToSeconds(b[coulumn1]);
        let bsecond = convertDurationToSeconds(b[coulumn2]);
        const res = compare(
          (afirst === undefined ? 0 : afirst) +
            (asecond === undefined ? 0 : asecond),
          (bfirst === undefined ? 0 : bfirst) +
            (bsecond === undefined ? 0 : bsecond)
        );
        return direction === 'asc' ? res : -res;
      }
      if (column === 'TripStarttime' || column === 'TripEndTime') {
        let afirst = convertDateStringSeconds(a[coulumn1]);
        let asecond = convertDateStringSeconds(a[coulumn2]);
        let bfirst = convertDateStringSeconds(b[coulumn1]);
        let bsecond = convertDateStringSeconds(b[coulumn2]);
        const res = compare(
          (afirst === undefined ? 0 : afirst) +
            (asecond === undefined ? 0 : asecond),
          (bfirst === undefined ? 0 : bfirst) +
            (bsecond === undefined ? 0 : bsecond)
        );
        return direction === 'asc' ? res : -res;
      }

      const res = compare(
        (a[coulumn1] === undefined ? '' : a[coulumn1]) +
          (a[coulumn2] === undefined ? '' : a[coulumn2]),
        (b[coulumn1] === undefined ? '' : b[coulumn1]) +
          (b[coulumn2] === undefined ? '' : b[coulumn2])
      );

      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(dto: TripDto, term: string, pipe: PipeTransform) {
  if (dto.clientNameStart || dto.clientNameEnd) {
    return (
      dto.clientNameStart?.toLowerCase().includes(term.toLowerCase()) ||
      dto.clientNameEnd?.toLowerCase().includes(term.toLowerCase()) ||
      dto.driverName?.toLowerCase().includes(term.toLowerCase()) ||
      dto.carNumber?.toLowerCase().includes(term.toLowerCase()) ||
      dto.kmStart?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.kmEnd?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.weightStart?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.weightEnd?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.referenceEnd?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.referenceStart
        ?.toString()
        .toLowerCase()
        .includes(term.toLowerCase()) ||
      dto.vendorNameStart
        ?.toString()
        .toLowerCase()
        .includes(term.toLowerCase()) ||
      dto.vendorNameEnd?.toString().toLowerCase().includes(term.toLowerCase())
    );
  }
  return null;
}

const EXPORT_ERROR = $localize`:Export error label for economic:Number of red marked trips have not been exported to Economic`;

@Component({
  selector: 'app-economic-table',
  templateUrl: './economic-table.component.html',
  styleUrls: ['./economic-table.component.scss'],
})
export class EconomicTableComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  @ViewChild('notecontainer', { read: ViewContainerRef })
  notecontainer?: ViewContainerRef;
  @ViewChild('addresscontainer', { read: ViewContainerRef })
  addresscontainer?: ViewContainerRef;
  componentNoteRef?: ComponentRef<any>;
  componentAddressRef?: ComponentRef<any>;
  saveOfficeNoteSub: any;
  cancelOfficeNoteSub: any;
  saveOfficeAddressSub: any;
  cancelOfficeAddressSub: any;
  tripsGroupUpdate = new Array<Trip>();
  productIds: (string | undefined)[] | null = null;
  clientIds: (string | undefined)[] | null = null;
  driverIds: (string | undefined)[] | null = null;
  tripsEconomicExport = new Array<Trip>();
  submitErrors: string[] | null = null;
  pushedResponses: PushedInvoiceResponse[] | null = null;
  eproductSub: Subscription | null = null;
  allSelected: boolean = false;
  @Input() set columns(value: Column[] | null) {
    if (!value) {
      this.columns = null;
    } else {
      this.standartVariableNames = value
        .filter(
          (x) => x.isStandartColumn && x.name != 'Trip Info' && x.name != 'Time'
        )
        .map((x) => x.name.replace(/\s/g, '').split(',')[0])
        .filter((x) => !x.includes('Edit'));
      let dataLink = new SimpleColumn();
      dataLink.name = 'modal';
      dataLink.danskName = 'Vis alle data';
      dataLink.isVisible = true;
      let chosen = new SimpleColumn();
      chosen.name = 'chosen';
      chosen.danskName = 'Vælg';
      chosen.isVisible = true;
      this.simpleColumns = [chosen, dataLink];
      value
        .filter((x) => !x.name.includes('Edit'))
        .filter((x) => x.isStandartColumn)
        .map((x) =>
          this.simpleColumns?.push({
            name: x.name.replace(/\s/g, '').split(',')[0],
            danskName: x.danskName.split(',')[0],
            isVisible: x.isVisible,
          })
        );
      this.simpleColumns = this.simpleColumns.filter((x) => x.name != 'Time');
      let clientNameIndex = this.simpleColumns.findIndex(
        (x) => x.name === 'ClientName'
      );
      let eproduct = new SimpleColumn();
      eproduct.name = 'Product';
      eproduct.danskName = 'Produktet';
      eproduct.isVisible = true;
      this.simpleColumns.splice(clientNameIndex + 1, 0, eproduct);
      let address = new SimpleColumn();
      address.name = 'Address';
      address.danskName = 'Adresse';
      address.isVisible = true;
      this.simpleColumns.splice(clientNameIndex + 2, 0, address);
      let exported = new SimpleColumn();
      exported.name = 'Exported';
      exported.danskName = 'Eksporteret';
      exported.isVisible = true;
      this.simpleColumns.splice(1, 0, exported);
      this.standartVariableNames = [
        chosen.name,
        exported.name,
        dataLink.name,
      ].concat(this.standartVariableNames);

      this.standartVariableNames.splice(clientNameIndex + 2, 0, eproduct.name);
      this.standartVariableNames.splice(clientNameIndex + 3, 0, address.name);

      let approved = this.simpleColumns.find((x) => x.name === 'Approved');
      if (!this.isAdminPage && approved) {
        let index = this.simpleColumns.indexOf(approved);
        if (index !== -1) {
          this.simpleColumns.splice(index, 1);
        }
      }

      let tenantNote = this.simpleColumns.find((x) => x.name === 'TenantNote');
      if (!this.isAdminPage && tenantNote) {
        let index = this.simpleColumns.indexOf(tenantNote);
        if (index !== -1) {
          this.simpleColumns.splice(index, 1);
        }
      }
      this.simpleLoadUnloadColumns = [];
      value
        .filter((x) => !x.name.includes('Edit'))
        .filter((x) => x.isUnloadColumn)
        .map((x) =>
          this.simpleLoadUnloadColumns?.push({
            name:
              x.name.replace(/\s/g, '').split(',')?.length > 1
                ? x.name.replace(/\s/g, '').split(',')[1]
                : x.name.replace(/\s/g, '').split(',')[0],
            danskName:
              x.danskName.replace(/\s/g, '').split(',')?.length > 1
                ? x.danskName
                    .split(',')[1]
                    .replace('losning', '')
                    .replace('Losning', '')
                    .replace('aflosset', '')
                    .replace('fotos', 'Fotos')
                : x.danskName.split(',')[0],
            isVisible: x.isVisible,
          })
        );
    }
  }
  eProductOptions: DatalistOption[] | null = null;

  standartHeaderNames: string[] | null = null;
  simpleColumns: SimpleColumn[] | null = null;
  simpleLoadUnloadColumns: SimpleColumn[] | null = null;
  standartVariableNames!: string[] | [''];

  @Input() timeLogs: Observable<Trip[]> | undefined;
  @Input() driverId: string | null = null;
  urls = new Array<string>();
  clickedRows = new Array<number>();
  @Input() isAdminPage: boolean = true;
  tripToUpdate = {} as TripDto;
  @Input() set drivers(value: User[] | null) {
    if (!value) {
      this.driverOptions = null;
    } else {
      this.driverOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
      this.ref.markForCheck();
    }
  }
  @Input() priceTypes: PriceType[] | null = null;
  @Input() clientSort: Company[] | null = null;
  @Input() prodSort: Product[] | null = null;
  @Input() driverSort: User[] | null = null;
  workEvents: WorkdayEvent[] | null = null;

  @Input() set clients(value: Company[] | null | any) {
    if (!value) {
      this.clientOptions = null;
    } else {
      this.clientOptions = value.map((x: any) => ({
        value: x.id,
        label: x.name,
        isActive: x.isActive,
      }));
    }
  }

  @Input() set products(value: Product[] | null) {
    if (!value) {
      this.productsOptions = null;
    } else {
      this.productsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  eprods = new Array<string>();
  isTiming = false;
  @Input() dateType?: string;
  @Output() tripApprove = new EventEmitter<TripDto>();
  driverSelected?: string;
  clientSelected?: string;
  driverOptions: DatalistOption[] | null = [];
  clientOptions: DatalistOption[] | null = null;
  productSelected?: string[];
  productsOptions: DatalistOption[] | null = null;
  tripDtos: TripDto[] | null = null;
  numbersForUnloadLoop: number[] | null = null;
  numbersForLoadLoop: number[] | null = null;
  dateFrom?: string;
  dateTo?: string;
  isApprovedSearch?: boolean;
  showAllData?: boolean;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _countries$ = new BehaviorSubject<TripDto[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  OutPutVales: TripDto[] | null = null;
  weighEvents = new Array<TripDto>();
  usualEvents = new Array<TripDto>();
  tripUnloads = new Array<TripUnloadDto>();
  tripLoads = new Array<TripLoadDto>();
  tripEvents = new Array<TripEventDto>();
  titleTenantNote: string | null = null;
  textTenantNote = new Array<string>();
  eventIdToUpdateTenantNote: string | null = null;
  @Output() tenantNoteEmmiter = new EventEmitter<TenantNoteEvent>();
  options?: FilterOptions;
  exportFactory = () => this.service.featuredExport(this.options);
  exportPhotoFactory = () => this.service.featuredPhotosExport(this.options);
  @Output() weighPhotoEmmiter = new EventEmitter<PhotoEventDto>();
  @Output() usualPhotoEmmiter = new EventEmitter<PhotoEventDto>();
  @Output() modalPhotoEmmiter = new EventEmitter<PhotoModalEventDto>();
  @Output() economicEmmiter = new EventEmitter<EconomicInfoExtended>();
  pages: number[] = [1];

  private _state: SearchState = {
    page: 1,
    pageSize: 20,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    unloadPosition: '',
  };

  changeShow() {
    localStorage.setItem('showAllData', JSON.stringify(this.showAllData));
  }

  @ViewChildren(NgbdSortableHeader) headers:
    | QueryList<NgbdSortableHeader>
    | undefined;

  unexportCandidate: TripDto | null = null;
  destroySub: any;
  constructor(
    private pipe: DecimalPipe,
    private formBuilder: UntypedFormBuilder,
    public eProdRepo: EProductsRepository,
    public eProdService: EProductsService,
    public prodRepo: ProductsRepository,
    public prodService: ProductsService,
    public ngDay: DayjsService,
    public ui: UiRepository,
    public service: TripsService,
    public repos: TripsRepository,
    public authRepo: AuthRepository,
    public companyRepo: CompaniesRepository,
    private readonly resolver: ComponentFactoryResolver,
    private readonly injector: Injector,
    private readonly baseService: BaseService,
    private ref: ChangeDetectorRef,
    private workEventService: WorkDayEventsService
  ) {
    this.clientSub = this.baseService.clientEmit$.subscribe((x) => {
      this.updateClients(x);
    });

    this.destroySub = this.baseService.destroyModal$.subscribe((x) => {
      if (x == 'client') {
        this.clientcomponentRef?.destroy();
      } else if (x == 'product') {
        this.productcomponentRef?.destroy();
      } else if (x == 'note') {
        this.componentNoteRef?.destroy();
      } else if (x == 'address') {
        this.componentAddressRef?.destroy();
      }
    });

    this.eProdService.loadDeleted().subscribe();
    this.eProdService.load().subscribe();
    this.eProdRepo.allDeleted$.subscribe((x) => {
      this.deletedProducts = x;
    });
    this.eProdRepo.all$.subscribe((x) => {
      this.eprodsT = x;
    });
    // this._state.searchTerm = localStorage.getItem('searchTerm')?.toString() || "";
    this._state.page = Number(localStorage.getItem('page')) || 1;
    this._state.sortColumn =
      localStorage.getItem('sortColumn')?.toString() || '';
    this._state.sortDirection =
      (localStorage.getItem('sortDirection')?.toString() as SortDirection) ||
      '';
    this.dateFrom = localStorage.getItem('dateFrom')?.toString() || '';
    this.dateTo = localStorage.getItem('dateTo')?.toString() || '';
    this.clientSelected =
      localStorage.getItem('clientSelected')?.toString() || '';
    this.driverSelected =
      localStorage.getItem('driverSelected')?.toString() || '';
    this.isApprovedSearch = JSON.parse(
      localStorage.getItem('isApprovedSearch')?.toString() || 'false'
    );
    if (localStorage.getItem('productSelected') != undefined) {
      this.productSelected = JSON.parse(
        localStorage.getItem('productSelected') || ''
      );
    }
    this.showAllData = JSON.parse(
      localStorage.getItem('showAllData')?.toString() || 'false'
    );

    this._search(true);
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(300),
        switchMap(() => this._search()),
        delay(1000),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result: any) => {
        this._countries$.next(result.countries);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  eprodsT: any;
  clientSub: any;

  ngOnDestroy(): void {
    this.eproductSub?.unsubscribe();
    this.saveOfficeNoteSub?.unsubscribe();
    this.cancelOfficeNoteSub?.unsubscribe();
    this.clientSub?.unsubscribe();
    this.destroySub?.unsubscribe();
    this.cancelOfficeAddressSub?.unsubscribe();
    this.saveOfficeAddressSub?.unsubscribe();
  }
  get countries$() {
    return this._countries$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<SearchState>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  @Output() clientsEmmiter = new EventEmitter<ClientEventInfoDto[]>();

  @ViewChildren(ClientUpdateModalComponent)
  clientsForm: QueryList<ClientUpdateModalComponent> | null = null;
  @ViewChild('closeModal') closeModal: ElementRef | null = null;
  @ViewChild('closeOfficeModal') closeOfficeModal: ElementRef | null = null;

  @Input() clientsDeleted: Company[] | null = [];
  @Input() updateInProgress: boolean = false;

  @Input() set eproducts(value: Product[] | null) {
    if (!value) {
      this.eproductOptions = null;
      this.eproductSortOptions = null;
    } else {
      this.eproductOptions = value
        .filter((x) => x.priceType)
        .map((x) => ({
          value: x.id,
          label: x.name,
          sublabel: x.priceType
            ? x.priceType.name.replace('_', ' ')
            : x.description,
        }));
      this.eproductSortOptions = value
        .filter((x) => x.priceType)
        .map((x) => ({
          value: x.id,
          label: x.name,
          sublabel: x.priceType
            ? x.priceType.name.replace('_', ' ')
            : x.description,
        }));
    }
  }
  eproductOptions: DatalistOption[] | null = null;

  eproductSortOptions: DatalistOption[] | null = null;

  updateValue(value: string[]) {
    this.eprods = value;
  }

  resetChild() {
    if (this.componentNoteRef !== undefined) {
      this.componentNoteRef.instance.startNote = null;
      this.componentNoteRef.instance.endNote = null;
      this.componentNoteRef.instance.officeNote = null;
      this.componentNoteRef.destroy();
      this.componentNoteRef = undefined;
    }
  }

  resetOfficeChild() {
    if (this.componentAddressRef !== undefined) {
      this.componentAddressRef.instance.tripId = null;
      this.componentAddressRef.instance.pickUpAddress = null;
      this.componentAddressRef.instance.deliveryAddress = null;
      this.componentAddressRef.destroy();
      this.componentAddressRef = undefined;
    }
  }

  loadDataForPopUp(timelog?: TripDto) {
    this.componentNoteRef?.instance.reset();

    const injector = Injector.create({
      providers: [],
      parent: this.injector,
    });
    const factory = this.resolver.resolveComponentFactory(
      OfficeNoteFormComponent
    );
    this.componentNoteRef = this.notecontainer?.createComponent(
      factory,
      undefined,
      injector
    );
    if (this.componentNoteRef?.instance) {
      this.componentNoteRef.instance.startNote = timelog?.noteStart;
      this.componentNoteRef.instance.endNote = timelog?.tripAllNotes;
      this.componentNoteRef.instance.officeNote = timelog?.officeNote;
      this.componentNoteRef.instance.tripId = timelog?.tripId;
      if (!this.saveOfficeNoteSub) {
        this.saveOfficeNoteSub = this.baseService.saveEvent$.subscribe((x) => {
          if (x) {
            this.service
              .updateOfficeNote(x.id, x.note)
              .subscribe(() => this.service.loadOverview().subscribe());
            if (this.closeModal) {
              this.closeModal.nativeElement.click();
            }
            this.baseService.sendSaveEvent(undefined);
          }
        });
      }

      if (!this.cancelOfficeNoteSub) {
        this.cancelOfficeNoteSub = this.baseService.cancelEvent$.subscribe(
          (x) => {
            if (x) {
              this.componentNoteRef?.destroy();
              this.componentNoteRef = undefined;
              this.resetChild();
              if (this.closeModal) {
                this.closeModal.nativeElement.click();
              }
            }
          }
        );
      }
    }
  }

  loadDataForAddressPopUp(timelog?: TripDto) {
    this.componentAddressRef?.instance.reset();
    const injector = Injector.create({
      providers: [],
      parent: this.injector,
    });
    const factory = this.resolver.resolveComponentFactory(
      OfficeAddressFormComponent
    );
    this.componentAddressRef = this.addresscontainer?.createComponent(
      factory,
      undefined,
      injector
    );
    if (this.componentAddressRef?.instance) {
      this.componentAddressRef.instance.pickUpAddress = timelog?.pickUpAddress;
      this.componentAddressRef.instance.deliveryAddress =
        timelog?.deliveryAddress;
      this.componentAddressRef.instance.tripId = timelog?.tripId;
      if (!this.saveOfficeAddressSub) {
        this.saveOfficeAddressSub =
          this.baseService.saveOfficeAddressEvent$.subscribe((x) => {
            if (x) {
              this.service
                .updateOfficeAddress(x.id, x.pickUpAddress, x.deliveryAddress)
                .subscribe(() => this.service.loadOverview().subscribe());
              if (this.closeOfficeModal) {
                this.closeOfficeModal.nativeElement.click();
              }
              this.baseService.saveOfficeAddressEvent(undefined);
            }
          });
      }

      if (!this.cancelOfficeAddressSub) {
        this.cancelOfficeAddressSub =
          this.baseService.cancelOfficeEvent$.subscribe((x) => {
            if (x) {
              this.componentAddressRef?.destroy();
              this.componentAddressRef = undefined;
              this.resetOfficeChild();
              if (this.closeOfficeModal) {
                this.closeOfficeModal.nativeElement.click();
              }
            }
          });
      }
    }
  }

  bulkUpdate() {
    if (this.tripsGroupUpdate.length > 0 && this.eprods.length > 0) {
      let obj = {
        tripIds: this.tripsGroupUpdate.map((x) => x.id),
        productIds: this.eprods,
      } as BulkEProduct;
      this.service.bulkUpdate(obj).subscribe();
      this.tripsGroupUpdate = [];
      this.eprods = [];
    }
  }

  handleUnexportClick(id?: string) {
    if (id) {
      this.service.unexport(id).subscribe((trip) => {
        this.OutPutVales?.forEach((x) => {
          if (x.tripId == trip.id) {
            x.exportedAt = undefined;
          }
        });
      });
    }
  }

  changeStringToUtc(str?: string) {
    if (str) {
      return str.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2-$1-$3');
    }
    return '';
  }

  isWorkEwentsLoaded: boolean = false;
  getDataToProductGroupModal(trip?: TripDto) {
    if (trip && trip.tripEvents) {
      if (trip.driverId && trip.tripId) {
        this.workEventService.loadForTrip(trip.tripId).subscribe((x) => {
          this.workEvents = x;
          this.isWorkEwentsLoaded = true;
        });
      }
    }
  }

  getDataClientsModal(trip?: TripDto) {
    if (trip && trip?.tripEvents && this.clientsForm) {
      this.clientsForm?.map((c) => {
        if (c.clientsForm) {
          c.clientsForm = this.formBuilder.group({
            clientInfos: this.formBuilder.array([]),
          });
          let control = c.clientsForm?.controls.clientInfos as UntypedFormArray;
          trip?.tripEvents
            ?.filter((a) => a.type !== 'TripStart')
            ?.forEach((x) => {
              control.push(
                this.formBuilder.group({
                  tripEventId: [x.tripEventId],
                  clientId: [x.isClientFromEconomic ? x.clientId : ''],
                })
              );
            });
        }
      });
    }
  }

  onSort({ column, direction, unloadPosition }: SortEvent) {
    this._state.unloadPosition = unloadPosition;
    this._state.sortColumn = column;
    this._state.sortDirection = direction;
    // resetting other headers
    if (this.headers) {
      this.headers.forEach((header) => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });
    }
    this.changeState();
  }

  changePage(page: number) {
    this.page = page;
  }

  checkIfChosen(tripId: string) {
    return this.tripsGroupUpdate.find((x) => x.id === tripId);
  }

  checkIfReadyToExport(tripId: string) {
    return this.tripsEconomicExport.find((x) => x.id === tripId);
  }

  checkAll() {
    var check = true;
    this.OutPutVales?.forEach((x) => {
      if (!x.exportedAt && !this.checkIfChosen(x.tripId!)) {
        check = false;
      }
    });
    return check;
  }

  changeAll() {
    this.allSelected = !this.allSelected;
    this.OutPutVales?.forEach((x) => {
      if (!x?.exportedAt) {
        this.changeAllChosen(x, this.allSelected);
      }
    });
  }

  checkIfAllTripsValid(tripsEconomicExport: Trip[]) {
    let notFiltered = new Array<TripEvent>();
    tripsEconomicExport.forEach((x) => {
      notFiltered.concat(x.tripEvents);
    });
    let events = notFiltered!.filter((x) => x.type !== 'TripStart');
    let noEco = events.filter((x) => !x.economics);
    let zeroEco = events.filter((x) => x.economics?.length === 0);
    let blankEco = events.filter((e) => e.economics?.find((a) => !a.productId));
    let fixedEvents = events.filter((a) =>
      a.economics?.find((x) => x.isFixedPrice && x.fixedPriceValue === 0)
    );

    if (fixedEvents) {
      return !(
        noEco.length > 0 ||
        zeroEco.length > 0 ||
        blankEco.length > 0 ||
        fixedEvents.length > 0
      );
    }
    return !(noEco.length > 0 || zeroEco.length > 0 || blankEco.length > 0);
  }

  changeAllChosen(trip: TripDto, all: boolean = false) {
    if (trip && trip.tripId) {
      if (this.tripsGroupUpdate.find((x) => x.id === trip.tripId && !all)) {
        let index = this.tripsGroupUpdate.findIndex(
          (x) => x.id === trip.tripId
        );
        this.tripsGroupUpdate.splice(index, 1);
      } else if (all && !this.checkIfChosen(trip.tripId)) {
        this.tripsGroupUpdate.push({ id: trip.tripId } as Trip);
      }
    }
    if (
      trip &&
      trip.tripId &&
      !this.checkIfClientsFromEconomic(trip) &&
      this.checkEconomicIsThere(trip)
    ) {
      if (this.tripsEconomicExport.find((x) => x.id === trip.tripId) && !all) {
        let index = this.tripsEconomicExport.findIndex(
          (x) => x.id === trip.tripId
        );
        this.tripsEconomicExport.splice(index, 1);
      } else if (all && !this.checkIfReadyToExport(trip.tripId)) {
        this.tripsEconomicExport.push({ id: trip.tripId } as Trip);
      }
    }
  }

  changeChosen(trip: TripDto, event: any) {
    if (trip && trip.tripId) {
      if (this.tripsGroupUpdate.find((x) => x.id === trip.tripId)) {
        let index = this.tripsGroupUpdate.findIndex(
          (x) => x.id === trip.tripId
        );
        this.tripsGroupUpdate.splice(index, 1);
      } else if (event.target.checked) {
        this.tripsGroupUpdate.push({ id: trip.tripId } as Trip);
      }
    }
    if (
      trip &&
      trip.tripId &&
      !this.checkIfClientsFromEconomic(trip) &&
      this.checkEconomicIsThere(trip)
    ) {
      if (this.tripsEconomicExport.find((x) => x.id === trip.tripId)) {
        let index = this.tripsEconomicExport.findIndex(
          (x) => x.id === trip.tripId
        );
        this.tripsEconomicExport.splice(index, 1);
      } else {
        if (event.target.checked) {
          this.tripsEconomicExport.push({ id: trip.tripId } as Trip);
        }
      }
    }
  }

  changeExport(trip: TripDto) {
    if (
      trip &&
      trip.tripId &&
      !this.checkIfClientsFromEconomic(trip) &&
      this.checkEconomicIsThere(trip)
    ) {
      if (!this.tripsEconomicExport.find((x) => x.id === trip.tripId)) {
        this.tripsEconomicExport.push({ id: trip.tripId } as Trip);
      }
    } else {
      if (this.tripsEconomicExport.find((x) => x.id === trip.tripId)) {
        let index = this.tripsEconomicExport.findIndex(
          (x) => x.id === trip.tripId
        );
        this.tripsEconomicExport.splice(index, 1);
      }
    }
  }

  updateModalValue(form: UntypedFormGroup, control: string, value: any) {
    const controlObject = form?.get(control);
    controlObject?.setValue(value);
  }

  bulkExport(union = 'false') {
    if (this.tripsEconomicExport.length > 0) {
      let ids = new Array<string>();
      this.tripsEconomicExport.forEach((x) => {
        if (x.id) {
          ids.push(x.id);
        }
      });
      this.service.eexport(ids, union).subscribe({
        next: (value) => {
          this.pushedResponses = value;
          if (value && value.find((x) => x.errorMessage)) {
            this.submitErrors = value.map((a) => a.errorMessage);
          } else if (value.find((x) => x.responseCode !== 201)) {
            this.submitErrors = [EXPORT_ERROR];
          }
        },
        complete: () => {
          this.service.loadOverview().subscribe();
          this.tripsGroupUpdate = [];
          this.tripsEconomicExport = [];
        },
        error: (data) => (this.submitErrors = data),
      });
      this.tripsEconomicExport = [];
    }
  }

  changeApprove(event: TripDto) {
    if (event.isApproved === true) {
      event.isApproved = false;
    } else {
      event.isApproved = true;
    }
    this.onUpdateApprove(event);
  }

  onUpdateApprove(event: Partial<TripDto>) {
    var el = this.tripDtos?.filter((x) => x.tripId === event.tripId)[0];
    if (el) {
      el.isApproved = event.isApproved;
      let objIndex = this.tripDtos?.findIndex(
        (obj) => obj.tripId === el?.tripId
      );
      if (objIndex !== undefined && this.tripDtos !== null) {
        if (
          this.tripDtos[objIndex] !== null &&
          this.tripDtos[objIndex] !== undefined
        ) {
          this.tripDtos[objIndex].isApproved = el.isApproved;
        }
      }
    }
    this.service.updateApprove(event).subscribe();
    this._search();
  }

  updateDriver(value: any) {}
  updateProducts(value: any) {}

  selectChangeNumber: number = 0;
  changeState(selectChangeNumber: number = 0) {
    this.selectChangeNumber = selectChangeNumber;
    //trigger timeLogs
    this._search$.next();
  }

  updateGroups($event: EconomicInfoExtended) {
    this.economicEmmiter.emit($event);
    //this.tripsGroupUpdate = [];
    //this.tripsEconomicExport = [];
  }

  updateClients($event: ClientEventInfoDto[]) {
    this.clientsEmmiter.emit($event);
  }

  ngOnInit(): void {
    if (this.timeLogs) {
      if (this.isAdminPage) {
        this.timeLogs.subscribe((x) => {
          if (x) {
            this.InitializeTripDtos(
              x.filter((a) => a.tripEvents.find((e) => e.type === 'TripEnd'))
            );
            this._search(true);
          }
        });
      } else {
        this.timeLogs.subscribe((x) => {
          if (x) {
            x = x?.filter((y) => y.userId === this.driverId);
            this.InitializeTripDtos(
              x.filter((a) => a.tripEvents.find((e) => e.type === 'TripEnd'))
            );
            this._search(true);
          }
        });
      }
    }
    this._search(true);

    if (this.dateType) {
      if (this.dateType) {
        this.setDatePicker(this.dateType);
      } else {
        this.setDatePicker('month');
      }
    } else if (!this.dateTo && !this.dateFrom) {
      this.setDatePicker('month');
    }
    this.changeState();
  }

  ngAfterContentChecked() {
    this.OutPutVales?.forEach((x, y) => {
      let row = document.getElementById(`ordering${y}`);

      this.simpleColumns?.forEach((a, b) => {
        let sortedColumnName = `${a.name}${y}`;
        let unsortedColumnName = row?.children.item(b)?.id;
        if (row) {
          let sortedColumn = row.querySelector(`#${sortedColumnName}`);
          let unsortedColumn = row.querySelector(`#${unsortedColumnName}`);
          if (sortedColumn) {
            row.insertBefore(sortedColumn, unsortedColumn);
          }
        }
        if (!a.isVisible && !this.isAdminPage) {
          let toRemove = `${a.name}${y}`;
          let toRemoveColumn = row?.querySelector(`#${toRemove}`);
          if (row && toRemoveColumn) {
            row.removeChild(toRemoveColumn);
          }
        }
        if (!a.isVisible && this.isAdminPage) {
          let toRemove = `${a.name}${y}`;
          let toRemoveColumn = row?.querySelector(`#${toRemove}`);
          if (row && toRemoveColumn) {
            row.removeChild(toRemoveColumn);
          }
        }
      });
    });
    this.allSelected = this.checkAll();

    if (this.clientSort) {
      this.clientOptions = this.clientSort.map((x) => ({
        value: x.id,
        label: x.name,
        isActive: x.isActive,
      }));
      this.clientOptions = this.clientOptions.filter((x) =>
        this.clientIds?.includes(x.value)
      );
    }
    if (this.driverSort) {
      this.driverOptions = this.driverSort.map((x) => ({
        value: x.id,
        label: x.name,
      }));
      this.driverOptions = this.driverOptions.filter((x) =>
        this.driverIds?.includes(x.value)
      );
    }
    if (this.prodSort) {
      this.eproductSortOptions = this.prodSort
        .filter((x) => x.priceType)
        .map((x) => ({
          value: x.id,
          label: x.name,
          sublabel: x.priceType
            ? x.priceType.name.replace('_', ' ')
            : x.description,
        }));
      this.eproductSortOptions = this.eproductSortOptions.filter((x) =>
        this.productIds?.includes(x.value)
      );
    }
  }

  setDatePicker(dateType: string) {
    if (dateType === 'day') {
      const dayObj = this.ngDay.dayjs.utc().local();
      this.dateFrom = dayObj.format('YYYY-MM-DD');
      this.dateTo = dayObj.format('YYYY-MM-DD');
    }
    if (dateType === 'week') {
      const weekStart = this.ngDay.dayjs().startOf('week');
      const weekEnd = this.ngDay.dayjs().endOf('week');
      this.dateFrom = weekStart.format('YYYY-MM-DD');
      this.dateTo = weekEnd.format('YYYY-MM-DD');
    }
    if (dateType === 'month') {
      let monthStart = this.ngDay.dayjs().startOf('month');
      let monthEnd = this.ngDay.dayjs().endOf('month');
      if (this.isAdminPage) {
        monthStart = monthStart.subtract(1, 'month');
        monthEnd = monthStart.endOf('month');
      }
      this.dateFrom = monthStart.format('YYYY-MM-DD');
      this.dateTo = monthEnd.format('YYYY-MM-DD');
    }
  }

  checkIfLoadUnloadInEvents(events: TripEventDto[]): boolean {
    let loadsUnloads = events.filter(
      (x) => x.type === 'Load' || x.type === 'Unload'
    );
    if (loadsUnloads && loadsUnloads.length > 0) {
      return true;
    }
    return false;
  }

  private _search(isInit: boolean = false): Observable<SearchResult> | any {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
      unloadPosition,
    } = this._state;

    // 1. sort
    if (this.tripDtos) {
      let countries = sort(
        this.tripDtos,
        sortColumn,
        sortDirection,
        unloadPosition
      );

      // 2. filter
      let dateToCustom = new Date(this.dateTo!);
      dateToCustom.setDate(dateToCustom.getDate() + 1);
      countries = countries
        ?.filter((country) => matches(country, searchTerm, this.pipe))
        .filter(
          (x) =>
            x.startEventTimeForFilter! <= dateToCustom &&
            x.startEventTimeForFilter! >= new Date(this.dateFrom!)
        );

      if (this.clientSelected) {
        countries = countries?.filter(
          (x) =>
            this.clientSelected?.includes(x.clientIdStart!) ||
            this.clientSelected?.includes(x.clientIdEnd!) ||
            x.tripEvents?.map((x) => x.clientId).includes(this.clientSelected)
        );
      }
      if (this.driverSelected) {
        countries = countries?.filter((x) =>
          this.driverSelected?.includes(x.driverId!)
        );
      }

      if (this.productSelected?.length! > 0) {
        countries = countries?.filter((x) => {
          var prodsCheck = x.tripEvents
            ?.map((x) => x.productId?.split(','))
            .flat(1)
            ?.filter((x: any) => this.productSelected?.includes(x));

          var ecProdsCheck = false;

          var economicsProds = x.tripEvents?.map((x) => x.economics);
          economicsProds?.forEach((g) => {
            g?.map((f) => f.productId).forEach((t) => {
              if (this.productSelected?.includes(t)) {
                ecProdsCheck = true;
              }
            });
          });

          return (
            this.productSelected?.some((r) =>
              x.productIdStart?.split(',')?.includes(r)
            ) ||
            this.productSelected?.some((r) =>
              x.productIdEnd?.split(',')?.includes(r)
            ) ||
            (prodsCheck && prodsCheck.length > 0) ||
            ecProdsCheck
          );
        });
      }

      if (this.isApprovedSearch) {
        countries = countries?.filter((x) => x.isApproved === false);
      }

      const total = countries.length;

      let opt: FilterOptions = new FilterOptions();
      opt.resultIds = countries.map((x) => x.tripId || '');

      this.dtoForFilters = countries;
      /// 3. paginate
      countries = countries.slice(
        (page - 1) * pageSize,
        (page - 1) * pageSize + pageSize
      );
      this.OutPutVales = countries;
      // this.tripDtos = countries;
      let numberOfPages = Math.floor(total / this.pageSize);
      if (total % this.pageSize !== 0) {
        numberOfPages = numberOfPages + 1;
      }
      this.pages = Array.from(
        { length: numberOfPages ? numberOfPages : 1 },
        (_, i) => i + 1
      );

      if (!this.pages.includes(this.page)) {
        this.page = this.pages.length;
      }

      if (this.clientSelected) {
        opt.clientSelected = this.clientSelected;
      }
      if (this.driverSelected) {
        opt.driverSelected = this.driverSelected;
      }
      if (this.productSelected) {
        opt.productSelected = this.productSelected;
      }
      opt.isApproved = this.isApprovedSearch;
      opt.searchWord = this.searchTerm;
      opt.dateFrom = this.dateFrom;
      opt.dateTo = this.dateTo;
      opt.order = sortDirection;
      opt.orderColumn = sortColumn;

      this.options = opt;

      this.service.featuredExport(this.options);

      //localStorage.setItem('searchTerm', this.searchTerm);
      localStorage.setItem('page', this.page.toString());
      localStorage.setItem('sortColumn', this._state.sortColumn);
      localStorage.setItem('sortDirection', this._state.sortDirection);
      localStorage.setItem('dateFrom', this.dateFrom || '');
      localStorage.setItem('dateTo', this.dateTo || '');
      localStorage.setItem('clientSelected', this.clientSelected || '');
      localStorage.setItem('driverSelected', this.driverSelected || '');
      localStorage.setItem('searchTerm', this.searchTerm);
      localStorage.setItem(
        'productSelected',
        JSON.stringify(this.productSelected || '')
      );
      localStorage.setItem(
        'isApprovedSearch',
        JSON.stringify(this.isApprovedSearch)
      );

      setTimeout(() => {
        this.rebuildSearches(this.OutPutVales, isInit);
      }, 800);

      return of({ countries, total });
    }
  }

  dtoForFilters: TripDto[] | null = null;

  rebuildSearches(tripDtos: TripDto[] | null, isInit: boolean = false) {
    if (tripDtos) {
      var dtoForFilters = this.dtoForFilters;
      if (dtoForFilters) {
        // get product ids

        var ecProds: any[] = [];
        this.productIds = dtoForFilters.flatMap((tripDto: TripDto) =>
          tripDto.tripEvents?.map(
            (tripEventDto: TripEventDto) => tripEventDto.productId
          )
        );

        dtoForFilters.flatMap((tripDto: TripDto) =>
          tripDto.tripEvents?.map((tripEventDto: TripEventDto) =>
            tripEventDto.economics?.forEach((h) => {
              if (
                !this.productIds?.includes(h.productId) &&
                !ecProds?.includes(h.productId)
              ) {
                ecProds.push(h.productId);
              }
            })
          )
        );

        this.productIds = this.productIds.concat(ecProds);

        var tempProds = structuredClone(this.productIds);
        this.productIds = this.productIds.filter((x) => x !== undefined);
        this.productIds = this.productIds.flatMap((x) => x?.split(','));
        this.productIds = Array.from(new Set(this.productIds));

        // get client ids
        this.clientIds = dtoForFilters.flatMap((tripDto: TripDto) =>
          tripDto.tripEvents?.map(
            (tripEventDto: TripEventDto) => tripEventDto.clientId
          )
        );

        this.clientIds = this.clientIds.filter((x) => x !== undefined);
        this.clientIds = Array.from(new Set(this.clientIds));
        //get driver ids
        this.driverIds = dtoForFilters.map((x) => x.driverId);
        this.driverIds = this.driverIds.filter((x) => x !== undefined);
        this.driverIds = Array.from(new Set(this.driverIds));

        if (this.clientSort) {
          this.clientOptions = this.clientSort.map((x) => ({
            value: x.id,
            label: x.name,
            isActive: x.isActive,
          }));
          this.clientOptions = this.clientOptions.filter((x) =>
            this.clientIds?.includes(x.value)
          );
        }
        if (this.driverSort) {
          this.driverOptions = this.driverSort.map((x) => ({
            value: x.id,
            label: x.name,
          }));
          this.driverOptions = this.driverOptions.filter((x) =>
            this.driverIds?.includes(x.value)
          );
        }
        if (this.prodSort) {
          this.eproductSortOptions = this.prodSort
            .filter((x) => x.priceType)
            .map((x) => ({
              value: x.id,
              label: x.name,
              sublabel: x.priceType ? x.priceType.name : x.description,
            }));
          this.eproductSortOptions = this.eproductSortOptions.filter((x) =>
            this.productIds?.includes(x.value)
          );
        }

        if (isInit) {
          if (!this.clientIds.includes(this.clientSelected)) {
            this.clientSelected = undefined;
          }
          if (!this.driverIds.includes(this.driverSelected)) {
            this.driverSelected = undefined;
          }
          var prodsSelected: string[] = [];
          this.productIds.forEach((x: any) => {
            if (this.productSelected && !this.productSelected?.includes(x)) {
              this.productSelected = this.productSelected?.filter(
                (h) => h != x
              );
            }
          });

          //this.productSelected = prodsSelected;

          this._search(false);
        }
      }
    }
  }

  getWeightPhotos($event: PhotoEventDto) {
    if ($event.url && this.urls) {
      const index = this.urls.indexOf($event.url);
      if (index > -1) {
        this.urls.splice(index, 1);
      }
      this.weighPhotoEmmiter.emit($event);
    }
  }

  getUsualPhotos($event: PhotoEventDto) {
    if ($event.url && this.urls) {
      const index = this.urls.indexOf($event.url);
      if (index > -1) {
        this.urls.splice(index, 1);
      }
      this.usualPhotoEmmiter.emit($event);
    }
  }

  getModalPhotos($event: PhotoModalEventDto) {
    if ($event.url && this.urls) {
      this.modalPhotoEmmiter.emit($event);
    }
  }

  getUrls(files?: TripPhoto[], type?: string, event?: TripDto) {
    this.urls = [];
    this.weighEvents = [];
    this.usualEvents = [];
    if (files) {
      this.urls = files?.map((x) => x.url);
    }
    if (event && type !== 'weigh') {
      this.usualEvents.push(event);
    }
    if (event && type === 'weigh') {
      this.weighEvents.push(event);
    }
  }

  compareStrings(start?: string | number, end?: string | number): string {
    if (start && end && start === end) {
      return start.toString();
    }
    if (start && end && start !== end) {
      return `${start + '/'} \n ${end}`;
    }
    if (start && !end) {
      return `${start}/`;
    }
    if (!start && end) {
      return `/${end}`;
    }
    return '';
  }

  openTenantComment(OutPutValues: TripDto | null) {
    this.eventIdToUpdateTenantNote = '';
    if (OutPutValues) {
      this.textTenantNote = OutPutValues.tenantNote
        ? [OutPutValues.tenantNote]
        : [''];
      this.titleTenantNote = 'Vognmand Bemærkning';
      this.eventIdToUpdateTenantNote = OutPutValues.tripEventIdStart!;
    }
  }

  cancelTenantNote() {
    this.titleTenantNote = null;
    this.textTenantNote = [];
  }

  trip = {} as TripDto;
  clientTrip = {} as TripDto;
  prodGroupTitle = new Array<string>();
  clientTitle = new Array<string>();
  openProductGroupDropdown(OutPutValues: TripDto | null) {
    if (this.trip) {
      // const injector = Injector.create({
      //   providers: [],
      //   parent: this.injector,
      // });

      // const factory = this.resolver.resolveComponentFactory(
      //   SingleGroupModalComponent
      // );
      // this.productcomponentRef = this.productscontainer?.createComponent(
      //   factory,
      //   undefined,
      //   injector
      // );

      this.prodGroupTitle = ['Produktgruppe'];
      if (OutPutValues) {
        this.trip = OutPutValues;
      }
      this.getDataToProductGroupModal(this.trip);

      // if (this.productcomponentRef?.instance) {
      //   this.productcomponentRef.instance.title = this.prodGroupTitle;
      //   this.productcomponentRef.instance.trip = this.trip;
      //   this.productcomponentRef.instance.eproducts = this.eprodsT;
      //   this.productcomponentRef.instance.eproductsDeleted =
      //     this.deletedProducts;
      // }
    }
  }
  clientsR: any;
  clientsDeletedR: any;

  @ViewChild('clentsContainer', { read: ViewContainerRef })
  clientscontainer?: ViewContainerRef;

  @ViewChild('productsContainer', { read: ViewContainerRef })
  productscontainer?: ViewContainerRef;

  clientcomponentRef?: ComponentRef<any>;
  productcomponentRef?: ComponentRef<any>;
  openClientDropdown(OutPutValues: TripDto | null) {
    if (this.clientTrip) {
      const injector = Injector.create({
        providers: [],
        parent: this.injector,
      });

      const factory = this.resolver.resolveComponentFactory(
        ClientUpdateModalComponent
      );
      this.clientcomponentRef = this.clientscontainer?.createComponent(
        factory,
        undefined,
        injector
      );

      this.clientTitle = ['Kunder'];
      if (OutPutValues) {
        this.clientTrip = OutPutValues;
      }
      this.getDataClientsModal(this.clientTrip);

      if (this.clientcomponentRef?.instance) {
        this.clientcomponentRef.instance.title = this.clientTitle;
        var filteredEvents = this.clientTrip?.tripEvents?.filter(
          (x) => x.type !== 'Total'
        );
        var tempTrip = Object.assign({}, this.clientTrip);
        tempTrip.tripEvents = filteredEvents;
        this.clientcomponentRef.instance.trip = tempTrip;
        this.clientcomponentRef.instance.clientsDeleted = this.clientsDeleted;
        this.clientcomponentRef.instance.clients = this.clientSort;
      }
    }
  }

  checkIfClientsFromEconomic(trip: TripDto) {
    if (trip.tripEvents) {
      let notFromEconomic = trip.tripEvents
        .filter((x) => x.type !== 'TripStart' && x.type !== 'Total')
        .filter((x) => !x.isClientFromEconomic);
      if (notFromEconomic && notFromEconomic.length > 0) {
        return true;
      }
      let deleted = trip.tripEvents
        .filter((x) => x.type !== 'TripStart' && x.type !== 'Total')
        .filter((x) => x.isClientDeleted);
      if (deleted && deleted.length > 0) {
        return true;
      }
    }
    return false;
  }

  deletedProducts: any;

  checkEconomicIsThere(trip: TripDto) {
    let events = trip.tripEvents!.filter(
      (x) => x.type !== 'TripStart' && x.type !== 'Total'
    );
    let noEco = events.filter((x) => !x.economics);
    let zeroEco = events.filter((x) => x.economics?.length === 0);
    let blankEco = events.filter((e) => e.economics?.find((a) => !a.productId));
    let fixedEvents = events.filter((a) =>
      a.economics?.find((x) => x.isFixedPrice && x.fixedPriceValue < 0.01)
    );

    let pseudoFixedEvents = events.filter((a) =>
      a.economics?.find(
        (x) =>
          (x.product?.priceType?.name.includes('Fast') ||
            x.product?.priceType?.name.includes('Stk')) &&
          !x.isFixedPrice &&
          x.fixedPriceValue < 0.01
      )
    );

    let emptyEcoKm = events.filter((e) =>
      e.economics?.find(
        (a) =>
          !a.isFixedPrice &&
          (a.product?.priceType?.name.includes('Km') ||
            a.product?.priceType?.name.includes('Navn')) &&
          (!e.diffKm || (e.diffKm && +e.diffKm < 0.01))
      )
    );

    let emptyEcoKg = events.filter(
      (e) =>
        e.economics?.find(
          (a) => !a.isFixedPrice && a.product?.priceType.name === 'Kg'
        ) &&
        (!e.weight || (e.weight && +e.weight < 0.01))
    );

    var isProductsDeleted = false;

    this.deletedProducts.forEach((x: any) => {
      events
        .map((x) => x.economics?.map((j) => j.productId))
        .forEach((h) => {
          if (h?.includes(x.id)) {
            isProductsDeleted = true;
          }
        });
    });

    if (fixedEvents) {
      return (
        !(
          noEco.length > 0 ||
          zeroEco.length > 0 ||
          blankEco.length > 0 ||
          emptyEcoKm.length > 0 ||
          emptyEcoKg.length > 0 ||
          fixedEvents.length > 0 ||
          pseudoFixedEvents.length > 0
        ) && !isProductsDeleted
      );
    }
    return (
      !(
        noEco.length > 0 ||
        zeroEco.length > 0 ||
        blankEco.length > 0 ||
        emptyEcoKm.length > 0 ||
        emptyEcoKg.length > 0 ||
        pseudoFixedEvents.length > 0
      ) && !isProductsDeleted
    );
  }

  addTenantNote($event: string) {
    if (this.eventIdToUpdateTenantNote) {
      let toUpdate = new TenantNoteEvent();
      toUpdate.tripEventId = this.eventIdToUpdateTenantNote;
      toUpdate.tenantNote = $event;
      this.tenantNoteEmmiter.emit(toUpdate);
    }
    this.cancelTenantNote();
  }

  getDataToSortModal(OutPutValues: TripDto | null, index: number) {
    this.tripUnloads = [];
    this.tripLoads = [];
    this.tripEvents = [];
    this.clickedRows = [];
    if (OutPutValues) {
      let outputEvents = OutPutValues.tripEvents;
      outputEvents?.forEach((b) => {
        if (b.type !== 'Weighing' && b.type !== 'Total') {
          this.tripEvents?.push(b);
        }
      });
      this.tripEvents.map((x) => {
        x.isApproved = OutPutValues.isApproved;
        x.carNumber = OutPutValues.carNumber;
        x.tripTime = OutPutValues.tripTime;
        x.tripKm = OutPutValues.tripKm;
        x.driverName = OutPutValues.driverName;
        x.trailerNumber = OutPutValues.trailerNumber;
      });
    }
    this.clickedRows.push(index);
  }

  InitializeTripDtos(trips: Trip[] | null) {
    if (trips) {
      let tripDtos: TripDto[] = [];
      trips?.forEach((trip) => {
        if (trip) {
          var dto = new TripDto();
          dto.driverId = trip.userId;
          dto.driverName = trip.userName;
          dto.tripId = trip.id;
          dto.isApproved = trip.isApproved;
          dto.isOrder = trip.isOrder;
          dto.officeNote = trip.officeNote;
          dto.deliveryAddress = trip.deliveryAddress;
          dto.pickUpAddress = trip.pickUpAddress;
          dto.exportedAt = trip.exportedAt ? trip.exportedAt.toString() : '';
          dto.failedSyncAt = trip.failedSyncAt
            ? trip.failedSyncAt.toString()
            : '';
          dto.carNumber = trip.vehicle?.numberplate;
          dto.trailerNumber = trip.trailer?.numberplate
            ? trip.trailer?.numberplate
            : '';
          let start;
          let end;
          let tripEventStart = trip.tripEvents?.filter(
            (x) => x.type === 'TripStart'
          )[0];
          if (tripEventStart) {
            dto.tripEventIdStart = tripEventStart.id;
            dto.clientNameStart = tripEventStart.client?.name;
            dto.clientIdStart = tripEventStart.client?.id;
            dto.clientIdNumberStart = tripEventStart.client?.idNumber;
            dto.startEventTimeForFilter = tripEventStart.eventTime;
            start = tripEventStart.eventTime;
            dto.startEventTime = dateFormater(tripEventStart.eventTime);
            dto.kmStart = tripEventStart.km;
            dto.startPosition = `https://maps.google.com/?q=${tripEventStart.latitude},${tripEventStart.longitude}`;
            dto.startEventPhotos = tripEventStart.tripPhotos;
            dto.startPhotoUrls = getUrls(tripEventStart.tripPhotos);
            dto.weightStart = tripEventStart.weight;
            dto.tenantNote = tripEventStart.tenantNote;

            if (tripEventStart.products) {
              if (tripEventStart.products[0]) {
                dto.productNameStart = tripEventStart.products
                  .map((x) => x.name)
                  .toString();
                dto.productIdStart = tripEventStart.products
                  .map((x) => x.id)
                  .toString();
              }
            }
            dto.cleaningStart = tripEventStart.cleaning?.name;
            dto.noteStart = tripEventStart.note;
            dto.referenceStart = tripEventStart.reference;
            dto.commentStart = tripEventStart.comment;
            dto.vendorNameStart = tripEventStart.vendor?.name;
            dto.vendorIdNumberStart = tripEventStart.vendor?.idNumber;
            dto.accessoryNameStart = tripEventStart.accessory?.name;
          }

          let tripEventEnd = trip.tripEvents?.filter(
            (x) => x.type === 'TripEnd'
          )[0];

          if (tripEventEnd) {
            dto.tripEventIdEnd = tripEventEnd.id;
            end = tripEventEnd.eventTime;
            dto.clientNameEnd = tripEventEnd.client?.name;
            dto.clientIdEnd = tripEventEnd.client?.id;
            dto.clientIdNumberEnd = tripEventEnd.client?.idNumber;
            dto.endEventTime = dateFormater(tripEventEnd.eventTime);
            dto.kmEnd = tripEventEnd.km;
            dto.endPosition = `https://maps.google.com/?q=${tripEventEnd.latitude},${tripEventEnd.longitude}`;
            dto.endEventPhotos = tripEventEnd.tripPhotos;
            dto.endPhotoUrls = getUrls(tripEventEnd.tripPhotos);
            dto.noteEnd = tripEventEnd.note;
            dto.referenceEnd = tripEventEnd.reference;
            dto.commentEnd = tripEventEnd.comment;
            dto.cleaningEnd = tripEventEnd.cleaning?.name;
            dto.weightEnd = tripEventEnd.weight;
            dto.vendorIdNumberEnd = tripEventEnd.vendor?.idNumber;
            dto.vendorNameEnd = tripEventEnd.vendor?.name;
            dto.accessoryNameEnd = tripEventEnd.accessory?.name;
            if (tripEventEnd.products) {
              if (tripEventEnd.products[0]) {
                dto.productNameEnd = tripEventEnd.products
                  .map((x) => x.name)
                  .toString();
                dto.productIdEnd = tripEventEnd.products
                  .map((x) => x.id)
                  .toString();
              }
            }
          }
          let duration = dayjs
            .duration(dayjs(end).diff(dayjs(start)))
            .asMilliseconds();
          dto.tripTime = convertMS(
            duration - (trip?.parkDuration ? trip!.parkDuration : 0)
          );

          dto.tripKm = (dto.kmEnd ?? 0) - (dto.kmStart ?? 0);

          let tripEventWeighing = trip.tripEvents?.filter(
            (x) => x.type === 'Weighing'
          );
          dto.weighingPhotos = new Array<TripPhoto>();
          if (tripEventWeighing) {
            tripEventWeighing.forEach((x) => {
              x.tripPhotos?.forEach((y) => {
                dto.weighingPhotos?.push(y);
              });
            });
          }
          let tripEventsDto: TripEventDto[] = [];

          trip.tripEvents
            ?.filter((x) => x.type !== 'Weighing')
            .forEach(function (event) {
              var eventDto = new TripEventDto();
              eventDto.tripEventId = event.id;
              eventDto.type = event.type;
              eventDto.economics = event.economics;
              eventDto.eventTime = dateFormater(event.eventTime);
              eventDto.km = event.km;
              eventDto.note = event.note;
              eventDto.reference = event.reference;
              eventDto.comment = event.comment;
              eventDto.accessoriesName = event.accessory?.name;
              eventDto.vendorName = event.vendor?.name;
              eventDto.vendorIdNumber = event.vendor?.idNumber;
              eventDto.cleaning = event.cleaning?.name;
              eventDto.isClientFromEconomic = event.client?.isFromEconomic;
              eventDto.totalKg = event.totalKg;
              eventDto.totalKm = event.totalKm;
              eventDto.totalMinutes = event.totalMinutes;
              eventDto.weight = event.weight;
              if (event.clientId) {
                eventDto.clientId = event.clientId;
              }
              if (event.client) {
                eventDto.clientName = event.client.name;
                eventDto.clientIdNumber = event.client?.idNumber;
                eventDto.isClientDeleted = event.client?.isDeleted;
              }

              if (event.products) {
                if (event.products[0]) {
                  eventDto.productName = event.products
                    .map((x) => x.name)
                    .toString();
                  eventDto.productId = event.products
                    .map((x) => x.id)
                    .toString();
                }
              }
              eventDto.position = `https://maps.google.com/?q=${event.latitude},${event.longitude}`;
              eventDto.photos = event.tripPhotos;
              eventDto.photoUrls = getUrls(event.tripPhotos);
              eventDto.duration = getDurationForLoadUnload(trip, event);
              eventDto.diffKg = getKgDiff(trip, event).toString();
              eventDto.diffKm = getKmDiff(trip, event).toString();
              tripEventsDto.push(eventDto);
            });

          dto.tripEvents = tripEventsDto;
          let notes = trip.tripEvents
            .filter(
              (x) =>
                x.note !== undefined && x.note !== null && x.note.trim() !== ''
            )
            .map((x) => `${this.getDanishEventType(x.type)}: ${x.note}`);
          dto.tripAllNotes = notes.join(' / ');

          if (
            dto.tripEvents &&
            this.tripsEconomicExport.length > 0 &&
            this.tripsEconomicExport.find((x) => x.id === dto.tripId)
          ) {
            this.changeExport(dto);
          }
          if (
            dto.tripEvents &&
            this.tripsGroupUpdate.length > 0 &&
            this.tripsGroupUpdate.find((x) => x.id === dto.tripId)
          ) {
            this.changeExport(dto);
          }
          tripDtos.push(dto);
        }
      });
      this.tripDtos = tripDtos;
    }
  }
  getDanishEventType(type: string): string {
    if (type === 'TripStart') {
      return 'Start';
    }
    if (type === 'TripEnd') {
      return 'Slut';
    }
    if (type === 'Load') {
      return 'Laste';
    }
    if (type === 'Unload') {
      return 'Losse';
    }
    return '';
  }
}
function getDurationForLoadUnload(trip: Trip, event: TripEvent): string {
  let actualEvents = trip.tripEvents.filter((x) => x.type !== 'Weighing');
  let index = actualEvents.indexOf(event);
  let previous = actualEvents[index - 1];
  if (
    event.eventTime &&
    previous &&
    previous.eventTime &&
    previous.eventTime <= event.eventTime
  ) {
    let durationMs = dayjs
      .duration(dayjs(event.eventTime).diff(dayjs(previous.eventTime)))
      .asMilliseconds();
    return convertMS(durationMs);
  }
  return '';
}

function getKmDiff(trip: Trip, event: TripEvent): string {
  let actualEvents = trip.tripEvents.filter((x) => x.type !== 'Weighing');
  let index = actualEvents.indexOf(event);

  let previous = actualEvents[index - 1];
  if (event && previous && event.km) {
    return (event.km - previous.km).toString();
  }
  return '';
}
function getKgDiff(trip: Trip, event: TripEvent): string {
  let actualEvents = trip.tripEvents.filter((x) => x.type !== 'Weighing');
  let index = actualEvents.indexOf(event);
  let previous = actualEvents[index - 1];

  if (event && previous && event.weight) {
    return (event.weight - previous.weight).toString();
  }
  return '';
}
function convertMS(duration: number): string {
  let d, h, m, s;
  s = Math.floor(duration / 1000);
  m = Math.floor(s / 60);
  s = s % 60;
  h = Math.floor(m / 60);
  m = m % 60;
  d = Math.floor(h / 24);
  h = h % 24;
  h += d * 24;
  return `${h}h. ${m}m. ${s}s. `;
}

function getUrls(files?: TripPhoto[]): string[] {
  let urls = new Array<string>();
  if (files) {
    urls = files?.map((x) => x.url);
  }
  return urls;
}
