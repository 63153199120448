import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { BaseService } from 'src/app/base-service';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { Company } from 'src/app/state/companies.repository';
import { PriceType } from 'src/app/state/pricetypes.repository';
import {
  ClientEventDto,
  ClientEventInfoDto,
  EconomicDto,
  EconomicInfo,
  TripDto,
  TripEventDto,
} from 'src/app/state/trips.repository';

@Component({
  selector: 'app-client-update-modal',
  templateUrl: './client-update-modal.component.html',
  styleUrls: ['./client-update-modal.component.scss'],
})
export class ClientUpdateModalComponent implements OnInit {
  groups = new Array<string>();
  display: string = 'block !important';
  opacity: string = '1 !important';
  @Input() title: string[] | null = null;
  @Input() mutable = true;
  theTrip: TripDto | null = null;
  @Input() set trip(value: TripDto | null) {
    if (!value?.tripEvents) {
      this.tevents = null;
      this.theTrip = null;
    } else {
      this.theTrip = value;
      this.tevents = value.tripEvents.filter((x) => x.type !== 'TripStart');
      this.getClients(value);
    }
  }
  tevents: TripEventDto[] | null = null;

  clientOptions: DatalistOption[] | null = null;
  clientOptionsDeleted: DatalistOption[] | null = null;

  @Input() set clients(value: Company[] | null) {
    if (!value) {
      this.clientOptions = null;
    } else {
      value = value.filter(
        (x) =>
          x.isActive ||
          this.clientsForm?.value.clientInfos.some(
            (h: any) => h.clientId == x.id
          ) ||
          x.id == this.theTrip?.clientIdStart ||
          x.id == this.theTrip?.clientIdEnd
      );

      this.clientOptions = value
        .filter((x) => x.isFromEconomic)
        .map((x) => ({
          value: x.id,
          label: x.name,
          sublabel: x.idNumber,
          isActive: x.isActive,
        }));
    }
  }

  @Input() set clientsDeleted(value: Company[] | null) {
    if (!value) {
      this.clientOptionsDeleted = null;
    } else {
      this.clientOptionsDeleted = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.idNumber,
        isDeleted: x?.deletedAt == '' || x?.deletedAt == undefined,
      }));
    }
  }

  @Output() clientsEmmiter = new EventEmitter<ClientEventInfoDto[]>();
  clientsForm?: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private readonly baseService: BaseService
  ) {
    this.clientsForm = this.formBuilder.group({
      clientInfos: this.formBuilder.array([]),
    });

    if (this.trip) {
      this.getClients(this.trip);
    }
  }

  ngOnInit(): void {}

  updateClientFormValue(control: string, value: any, index: number) {
    const controlObject = this.clientsForm
      ?.get('clientInfos')
      ?.get(`${index}`)
      ?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  getClients(trip?: TripDto) {
    let clientControl = this.clientsForm?.get(
      'clientInfos'
    ) as UntypedFormArray;
    if (clientControl) {
      trip?.tripEvents
        ?.filter((x) => x.type !== 'TripStart')
        ?.map((x) => {
          clientControl.push(
            this.formBuilder.group({
              tripEventId: [x?.tripEventId],
              clientId: [x?.clientId],
            })
          );
        });
    }
  }

  removeSpaces(text: string) {
    return `${text.replace(/\s/g, '')}`;
  }

  updateModalValue(control: string, value: any, index: number) {
    const controlObject = this.clientsForm
      ?.get('clientInfos')
      ?.get(`${index}`)
      ?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  obliterate() {
    this.baseService.sendDestroyModal('client');
    this.title = [];
    this.groups = [];
  }

  getDanishEventType(type: string): string {
    if (type === 'TripStart') {
      return 'Start';
    }
    if (type === 'TripEnd') {
      return 'Slut';
    }
    if (type === 'Load') {
      return 'Laste';
    }
    if (type === 'Unload') {
      return 'Losse';
    }
    return '';
  }

  save() {
    let clientDtos = new Array<ClientEventInfoDto>();
    let clientControl = this.clientsForm?.value.clientInfos as Array<any>;
    clientControl.forEach((x) => {
      if (x.clientId) {
        let dto = {
          tripEventId: x.tripEventId,
          clientId: x.clientId,
        } as ClientEventInfoDto;
        clientDtos.push(dto);
      }
    });

    this.baseService.sendClientEmit(clientDtos);

    //this.clientsEmmiter.emit(clientDtos);
    this.obliterate();
  }
}
