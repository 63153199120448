<div class="container-fluid">
  <div class="btn-group" role="group">
    <button
      *ngFor="let pageNumber of pages"
      class="btn"
      [class.btn-outline-primary]="page !== pageNumber"
      [class.btn-primary]="page === pageNumber"
      (click)="changePage(pageNumber)"
    >
      {{ pageNumber }}
    </button>
    <div style="padding-left: 10px; padding-top: 5px">
      <app-spinner *ngIf="loading$ | async"></app-spinner>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <!-- <div class="col-sm-3 col-md-6 col-lg-4">
      <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Søg:</label>
      <input id="table-complete-search" type="text" class="form-control" name="searchTerm" [(ngModel)]="searchTerm">
    </div> -->
    <div class="col-sm-4 col-md-8 col-lg-6">
      <label for="dateFrom" class="col-xs-3 col-sm-auto col-form-label"
        >Fra</label
      >
      <input
        id="dateFrom"
        type="date"
        class="form-control"
        name="dateFrom"
        [(ngModel)]="dateFrom"
        (ngModelChange)="changeState()"
      />
    </div>
    <div class="col-sm-4 col-md-8 col-lg-6">
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Til</label
      >
      <input
        id="dateTo"
        type="date"
        class="form-control"
        name="dateTo"
        [(ngModel)]="dateTo"
        (ngModelChange)="changeState()"
      />
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4">
      <app-spinner *ngIf="loading$ | async"></app-spinner>
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Kunder</label
      >
      <app-datalist-select
        *ngIf="!(loading$ | async)"
        id="clientsSelect"
        [options]="clientOptions"
        [(activeValue)]="clientSelected"
        (activeValueChange)="changeState()"
        label="Kunder"
        i18n-label="Label client"
      ></app-datalist-select>
    </div>
    <div *ngIf="isAdminPage" class="col-sm-3 col-md-6 col-lg-4">
      <app-spinner *ngIf="loading$ | async"></app-spinner>
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Chauffør</label
      >
      <app-datalist-select
        *ngIf="!(loading$ | async)"
        id="driversSelect"
        [options]="driverOptions"
        [(activeValue)]="driverSelected"
        (activeValueChange)="changeState()"
        label="Chauffør"
        i18n-label="Label driver"
      ></app-datalist-select>
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4">
      <app-spinner *ngIf="loading$ | async"></app-spinner>
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Produkter</label
      >
      <app-datalist-select
        *ngIf="!(loading$ | async)"
        id="productsSelect"
        [options]="productsOptions"
        [multiple]="true"
        [(activeValue)]="productSelected"
        (activeValueChange)="changeState()"
        label="Produkter"
        i18n-label="Features label"
      ></app-datalist-select>
    </div>
    <div *ngIf="isAdminPage" class="col-sm-3 col-md-6 col-lg-4 mt-4">
      <label
        for="isApprovedSearch"
        class="col-xs-3 col-sm-auto col-form-label align-middle"
        >Ikke behandlet</label
      >
      <input
        id="isApprovedSearch"
        name="isApprovedSearch"
        [(ngModel)]="isApprovedSearch"
        (ngModelChange)="changeState()"
        class="text-center align-middle"
        style="margin-left: 10px; width: 20px; height: 20px"
        type="checkbox"
        aria-label=""
      />
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4 mt-4">
      <app-import-export
        *ngIf="authRepo.isTenantAdmin$ | async"
        class="me-2"
        filenamePrefix="trips"
        [exportFactory]="exportFactory"
      ></app-import-export>
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4 mt-4">
      <app-import-export
        *ngIf="authRepo.isTenantAdmin$ | async"
        class="me-2"
        [titlePrefix]="'Vejesedler Billede '"
        filenamePrefix="fotos/{{ dateFrom }}/{{ dateTo }}"
        [filenameExtension]="'.zip'"
        [icon]="'bi bi-file-earmark-zip'"
        [format]="''"
        [exportFactory]="exportPhotoFactory"
      ></app-import-export>
    </div>
  </div>
</div>

<div
  style="width: auto; overflow-x: scroll"
  class="table-responsive custom-scroll"
>
  <table
    style="margin-top: 15px"
    class="table table-hover table-bordered"
    [class.darkModeCustom]="ui.isDarkMode$ | async"
  >
    <thead>
      <tr>
        <th
          class="text-center align-middle hoverable"
          scope="col"
          *ngFor="let item of simpleColumns; let i = index"
          [hidden]="!item.isVisible"
          sortable="{{ standartVariableNames[i] }}"
          (sort)="onSort($event)"
        >
          {{ item.danskName }}
          <i
            *ngIf="headers?.get(i)?.direction === 'asc'"
            class="bi bi-arrow-up"
          ></i
          ><i
            *ngIf="headers?.get(i)?.direction === 'desc'"
            class="bi bi-arrow-down"
          ></i>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        [class.darkModeCustomTr]="
          timeLog?.isApproved ? (ui.isDarkMode$ | async) : ''
        "
        [class.bg-yellow]="timeLog.isOrder"
        [class.bg-approved]="
          isAdminPage && timeLog?.isApproved && i !== clickedRows[0]
        "
        *ngFor="let timeLog of OutPutVales; let i = index"
        [class.bg-chosen-hover]="i === clickedRows[0]"
        id="ordering{{ i }}"
      >
        <td id="modal{{ i }}" class="text-center align-middle hoverable">
          <a
            class="anchor"
            *ngIf="
              timeLog.tripEvents &&
              timeLog.tripEvents.length > 0 &&
              checkIfLoadUnloadInEvents(timeLog.tripEvents)
            "
            (click)="getDataToSortModal(timeLog, i)"
            >*</a
          >
        </td>
        <th
          id="Approved{{ i }}"
          *ngIf="isAdminPage"
          class="text-center align-middle"
        >
          <input
            [checked]="timeLog?.isApproved"
            [attr.disabled]="isAdminPage ? null : true"
            (change)="changeApprove(timeLog)"
            style="width: 20px; height: 20px"
            type="checkbox"
            aria-label=""
          />
        </th>
        <td
          id="Note{{ i }}"
          class="text-center align-middle"
          [innerHTML]="compareStrings(timeLog.noteStart, timeLog.noteEnd)"
        ></td>
        <td
          id="TenantNote{{ i }}"
          *ngIf="isAdminPage"
          class="text-center align-middle hoverable"
        >
          <a
            [class.anchor-green]="!timeLog.tenantNote"
            [class.anchor-blue]="timeLog.tenantNote"
            (click)="openTenantComment(timeLog)"
            >*</a
          >
        </td>
        <td id="Numberplate{{ i }}" class="text-center align-middle">
          {{ timeLog.carNumber }}
        </td>
        <td id="Trailer{{ i }}" class="text-center align-middle">
          {{ timeLog.trailerNumber }}
        </td>
        <td
          id="ClientID{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(
              timeLog.clientIdNumberStart,
              timeLog.clientIdNumberEnd
            )
          "
        ></td>
        <td
          id="ClientName{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.clientNameStart, timeLog.clientNameEnd)
          "
        ></td>
        <td id="DriverID{{ i }}" class="text-center align-middle">
          {{ timeLog.driverName }}
        </td>

        <td
          id="ProductName{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.productNameStart, timeLog.productNameEnd)
          "
        ></td>
        <td
          id="Weight{{ i }}"
          class="text-center align-middle"
          [innerHTML]="compareStrings(timeLog.weightStart, timeLog.weightEnd)"
        ></td>
        <td id="TripTotalKm{{ i }}" class="text-center align-middle">
          {{ timeLog.tripKm ?? 0 }}
        </td>
        <td id="Totaltime{{ i }}" class="text-center align-middle">
          {{ timeLog.tripTime }}
        </td>
        <td
          id="VendorID{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(
              timeLog.vendorIdNumberStart,
              timeLog.vendorIdNumberEnd
            )
          "
        ></td>
        <td
          id="VendorName{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.vendorNameStart, timeLog.vendorNameEnd)
          "
        ></td>
        <td
          id="Reference{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.referenceStart, timeLog.referenceEnd)
          "
        ></td>
        <td
          id="Orderinfo{{ i }}"
          class="text-center align-middle"
          [innerHTML]="compareStrings(timeLog.commentStart, timeLog.commentEnd)"
        ></td>
        <td id="TripStartphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="timeLog?.startEventPhotos">
            <a
              *ngIf="(timeLog?.startEventPhotos)!.length > 0"
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(timeLog?.startEventPhotos, '', timeLog)"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
          <br />
          <span>
            <a class="btn btn-sm btn-link py-0 p-md-0">
              <label for="startimg" class="hoverable" i18n="Label for startimg"
                ><i
                  class="bi bi-plus-lg"
                  *ngIf="timeLog.tripEventIdStart"
                  (click)="selectedId = timeLog.tripEventIdStart"
                ></i
              ></label>
              <input
                type="file"
                accept="image/*"
                id="startimg"
                *ngIf="timeLog.tripEventIdStart"
                (change)="addImage($event)"
                style="display: none"
                multiple
              />
            </a>
          </span>
        </td>
        <td id="Weighingphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="timeLog?.weighingPhotos">
            <a
              *ngIf="(timeLog?.weighingPhotos)!.length > 0"
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(timeLog?.weighingPhotos, 'weigh', timeLog)"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
          <br />
          <br />
          <span>
            <a class="btn btn-sm btn-link py-0 p-md-0">
              <label for="weighimg" class="hoverable" i18n="Label for weighimg"
                ><i
                  class="bi bi-plus-lg"
                  *ngIf="timeLog.tripId"
                  (click)="selectedId = timeLog.tripId"
                ></i
              ></label>
              <input
                type="file"
                accept="image/*"
                id="weighimg"
                *ngIf="timeLog.tripId"
                (change)="addImage($event)"
                style="display: none"
                multiple
              />
            </a>
          </span>
        </td>
        <td id="TripStarttime{{ i }}" class="text-center align-middle">
          {{ timeLog.startEventTime }}
        </td>
        <td id="TripStartKm{{ i }}" class="text-center align-middle">
          {{ timeLog.kmStart }}
        </td>
        <td id="EditTripStart{{ i }}" class="text-center align-middle">
          <a
            class="btn btn-sm btn-link py-0 p-md-0"
            (click)="navigateTo(timeLog.tripEventIdStart!)"
          >
            <i class="bi bi-pencil me-1"></i>
            <ng-container i18n="@@edit">Edit</ng-container>
          </a>
        </td>
        <td id="StartTripposition{{ i }}" class="text-center align-middle">
          <a target="_blank" href="{{ timeLog.startPosition }}">Link</a>
        </td>
        <td id="TripEndphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="timeLog?.endEventPhotos">
            <a
              *ngIf="(timeLog?.endEventPhotos)!.length > 0"
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(timeLog?.endEventPhotos, '', timeLog)"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
          <br />
          <span>
            <a class="btn btn-sm btn-link py-0 p-md-0">
              <label for="endimg" class="hoverable" i18n="Label for endimg"
                ><i
                  class="bi bi-plus-lg"
                  *ngIf="timeLog.tripEventIdEnd"
                  (click)="selectedId = timeLog.tripEventIdEnd"
                ></i
              ></label>
              <input
                type="file"
                accept="image/*"
                id="endimg"
                *ngIf="timeLog.tripEventIdEnd"
                (change)="addImage($event)"
                style="display: none"
                multiple
              />
            </a>
          </span>
        </td>
        <td id="TripEndTime{{ i }}" class="text-center align-middle">
          {{ timeLog.endEventTime }}
        </td>
        <td id="TripEndKm{{ i }}" class="text-center align-middle">
          {{ timeLog.kmEnd }}
        </td>
        <td id="EditTripEnd{{ i }}" class="text-center align-middle">
          <a
            *ngIf="timeLog.tripEventIdEnd"
            class="btn btn-sm btn-link py-0 p-md-0"
            (click)="navigateTo(timeLog.tripEventIdEnd)"
          >
            <i class="bi bi-pencil me-1"></i>
            <ng-container i18n="@@edit">Edit</ng-container>
          </a>
        </td>
        <td id="TripEndGPS{{ i }}" class="text-center align-middle">
          <a
            *ngIf="timeLog.endPosition"
            target="_blank"
            href="{{ timeLog.endPosition }}"
            >Link</a
          >
        </td>

        <td
          id="Cleaning{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.cleaningStart, timeLog.cleaningEnd)
          "
        ></td>
        <td
          id="Accessory{{ i }}"
          class="Accessory{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.accessoryNameStart, timeLog.accessoryNameEnd)
          "
        ></td>
        <td
          *ngIf="isParkedOverview"
          id="Time{{ i }}"
          class="text-center align-middle"
        >
          <div>
            <div>{{ timeLog.parkDuration }}</div>
            <i class="bi bi-pause strong"></i>
          </div>
        </td>
        <td
          *ngIf="isParkedOverview"
          id="TripInfo{{ i }}"
          class="text-center align-middle"
        >
          <div *ngIf="timeLog.isOrder">
            <i
              class="bi bi-cone-striped hoverable me-1"
              (click)="openDriversModal(timeLog)"
            ></i>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="container-fluid mt-4">
  <div class="btn-group" role="group">
    <button
      *ngFor="let pageNumber of pages"
      class="btn"
      [class.btn-outline-primary]="page !== pageNumber"
      [class.btn-primary]="page === pageNumber"
      (click)="changePage(pageNumber)"
    >
      {{ pageNumber }}
    </button>
    <div style="padding-left: 10px; padding-top: 5px">
      <app-spinner *ngIf="loading$ | async"></app-spinner>
    </div>
  </div>
</div>
<app-image-slider
  id="photoModal"
  [urls]="urls"
  [weighEvents]="weighEvents"
  [usualEvents]="usualEvents"
  (weighPhotoEmmiter)="getWeightPhotos($event)"
  (usualPhotoEmmiter)="getUsualPhotos($event)"
></app-image-slider>
<app-sort-table-modal
  id="sortModal"
  [isDarkMode]="ui.isDarkMode$ | async"
  (modalPhotoEmmiter)="getUsualPhotos($event)"
  [isAdminPage]="isAdminPage"
  (takePhotos)="takePhotos.emit($event)"
  [tripEvents]="tripEvents"
  [clickedRows]="clickedRows"
  [simpleLoadUnloadColumns]="simpleLoadUnloadColumns"
></app-sort-table-modal>
<app-single-textbox-modal
  *ngIf="textTenantNote"
  [title]="titleTenantNote"
  [text]="textTenantNote"
  (stringEmmiter)="addTenantNote($event)"
  (cancel)="cancel()"
></app-single-textbox-modal>

<app-trip-change-driver-modal
  *ngIf="selectedTrip"
  [drivers]="allDrivers"
  [clientName]="selectedTrip.clientNameStart"
  [currentId]="selectedTrip.driverId"
  [date]="selectedTrip.startEventTime"
  (confirm)="updateParkedTrip($event)"
  (cancel)="selectedTrip = null"
>
</app-trip-change-driver-modal>
