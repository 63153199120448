import { Injectable, Input } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  withActiveId,
  selectActiveEntity,
  setActiveId,
  upsertEntities,
  selectEntity,
  getEntity,
  getActiveEntity,
  getAllEntities,
  setEntitiesMap,
  setEntities,
  addEntities,
  deleteAllEntities,
} from '@ngneat/elf-entities';
import {
  withRequestsCache,
  withRequestsStatus,
  createRequestsStatusOperator,
  createRequestsCacheOperator,
  updateRequestCache,
  updateRequestsStatus,
  selectRequestStatus,
} from '@ngneat/elf-requests';
import { from, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { sortBy } from '../modules/shared/pipes/sort.pipe';
import {
  SortColumn,
  SortDirection,
} from '../modules/truck/components/sortable.directive';
import { Accessory } from './accessories.repository';
import { Cleaning } from './cleaning.repository';
import { Company } from './companies.repository';
import { Product } from './products.repository';
import { Trailer } from './trailers.repository';
import { Vehicle } from './vehicles.repository';
import { DayjsService } from '../modules/shared/services/dayjs.service';
import { CommentPhotoDto } from './orders.repository';

export type TripEventType =
  | 'TripStart'
  | 'TripEnd'
  | 'Unload'
  | 'Load'
  | 'Weighing'
  | 'Total';

export interface Trip {
  id: string;
  vehicle?: Vehicle | null;
  trailer?: Trailer | null;
  createdAt: Date;
  isApproved?: boolean;
  tripEvents: TripEvent[];
  userId?: string;
  userName?: string;
  exportedAt?: Date;
  failedSyncAt?: Date;
  isOrder?: boolean;
  isRejected?: boolean;
  parentId?: string | null;
  pickUpAddress?: string;
  deliveryAddress?: string;
  plannedToStartAt?: Date;
  plannedToEndAt?: Date;
  startedAt?: Date;
  rejectedAt?: Date;
  contactPerson?: string;
  reference?: string;
  note?: string;
  tenantNote?: string;
  clientId?: string;
  vendorId?: string;
  accessoryId?: string;
  cleaningId?: string;
  client?: Company;
  vendor?: Company;
  accessory?: Accessory;
  cleaning?: Cleaning;
  productIds?: string[];
  products?: Product[];
  weight?: number;
  casualUpdate?: boolean;
  familyNumber?: number;
  rejectedById?: string;
  rejectedByName?: string;
  officeNote?: string;
  type?: string;
  curTime?: Date;
  parkedAt?: Date;
  resumedAt?: Date;
  deletedAt?: Date;
  parkDuration?: number;
  totalDuration?: number;
  commentPhotos?: CommentPhotoDto[];
}

export class TripDto {
  tripId?: string;
  exportedAt?: string;
  clientNameStart?: string;
  clientNameEnd?: string;
  clientIdStart?: string;
  clientIdEnd?: string;
  clientIdNumberStart?: string;
  clientIdNumberEnd?: string;
  isClientFromEconomic?: boolean;
  isClientDeleted?: boolean;
  carNumber?: string;
  trailerNumber?: string;
  pickUpAddress?: string;
  deliveryAddress?: string;
  tripEventIdStart?: string;
  tripEventIdEnd?: string;
  startEventTime?: string;
  startEventTimeForFilter?: Date;
  startEventPhotos?: TripPhoto[];
  startPhotoUrls?: string[];
  weighingPhotoUrls?: string[];
  weighingPhotos?: TripPhoto[];
  endPhotoUrls?: string[];
  endEventPhotos?: TripPhoto[];
  kmStart?: number;
  officeNote?: string;
  endEventTime?: string;
  kmEnd?: number;
  tripTime?: string;
  tripKm?: number;
  weightStart?: number;
  weightEnd?: number;
  cleaningStart?: string;
  cleaningEnd?: string;
  startPosition?: string;
  endPosition?: string;
  productNameStart?: string;
  productNameEnd?: string;
  productIdStart?: string;
  productIdEnd?: string;
  noteStart?: string;
  noteEnd?: string;
  referenceStart?: string;
  referenceEnd?: string;
  commentStart?: string;
  commentEnd?: string;
  vendorIdNumberStart?: string;
  vendorIdNumberEnd?: string;
  vendorNameStart?: string;
  vendorNameEnd?: string;
  accessoryNameStart?: string;
  accessoryNameEnd?: string;
  tripEvents?: TripEventDto[];
  tripAllNotes?: string;
  isApproved?: boolean;
  driverName?: string;
  driverId?: string;
  tenantNote?: string;
  failedSyncAt?: string;
  isOrder?: boolean;
  parkDuration?: string;
  mainParentId?: string;
}

export class TripColumnDto {
  columnName?: string;
  columnExpression?: string;
}

export interface BulkEProduct {
  tripIds: string[];
  productIds: string[];
}

export interface EconomicDto {
  tripEventId: string;
  productId: string;
  product?: Product;
  isFixedPrice: boolean;
  fixedPriceValue: number;
  onlyTakeValueFromTotal: boolean;
}

export interface ClientEventDto {
  clientEventInfoDtos?: ClientEventInfoDto[];
}

export interface ClientEventInfoDto {
  tripEventId: string;
  clientId: string;
}

export interface EconomicInfoExtended {
  infos?: EconomicInfo[];
  photos?: TripPhoto[];
}

export interface EconomicInfo {
  tripEventId: string;
  productId: string;
  priceTypeId: string;
  fixedPrice?: number;
  onlyTakeValueFromTotal: boolean;
}

export class TripUnloadDto {
  tripEventIdUnload?: string;
  position?: string;
  eventTime?: string;
  km?: number;
  weight?: number;
  productName?: string;
  clientName?: string;
  clientId?: string;
  clientIdNumber?: string;
  cleaning?: string;
  note?: string;
  reference?: string;
  vendorIdNumber?: string;
  vendorName?: string;
  unloadPhotos?: TripPhoto[];
  photoUrls?: string[];
  accessoriesName?: string;
}
export class TripEventDto {
  tripEventId?: string;
  exportedAt?: string;
  position?: string;
  eventTime?: string;
  km?: number;
  weight?: number;
  productId?: string;
  productName?: string;
  clientName?: string;
  clientId?: string;
  clientIdNumber?: string;
  isClientFromEconomic?: boolean;
  cleaning?: string;
  note?: string;
  reference?: string;
  comment?: string;
  vendorIdNumber?: string;
  vendorName?: string;
  photos?: TripPhoto[];
  photoUrls?: string[];
  accessoriesName?: string;
  type?: string;
  duration?: string;
  diffKm?: string;
  diffKg?: string;
  tenantNote?: string;
  isApproved?: boolean;
  carNumber?: string;
  trailerNumber?: string;
  driverName?: string;
  tripKm?: number;
  tripTime?: string;
  economics?: EconomicDto[];
  isClientDeleted?: boolean;
  totalKm?: number;
  totalMinutes?: number;
  totalKg?: number;
  pickUpAddress?: string;
  deliveryAddress?: string;
}

export class TripLoadDto {
  tripEventIdLoad?: string;
  position?: string;
  eventTime?: string;
  km?: number;
  weight?: number;
  productName?: string;
  clientName?: string;
  clientId?: string;
  clientIdNumber?: string;
  cleaning?: string;
  note?: string;
  reference?: string;
  vendorIdNumber?: string;
  vendorName?: string;
  loadPhotos?: TripPhoto[];
  photoUrls?: string[];
  accessoriesName?: string;
}

export interface SearchResult {
  countries: TripDto[];
  total: number;
}

export interface SearchState {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  unloadPosition: string;
}

export interface TripPhoto {
  id: string;
  createdAt: Date;
  url: string;
  isActive?: boolean;
}

export class PhotoEventDto {
  url?: string;
  trips?: TripDto[];
}
export class PhotoModalEventDto {
  url?: string;
  events?: TripEventDto[];
}

export interface TripEvent {
  id: string;
  tripId?: string;
  createdAt?: Date;
  eventTime: Date;
  type: TripEventType;
  longitude: number;
  latitude: number;
  km: number;
  weight: number;
  accessoryId: string | null;
  accessory?: Accessory | null;
  cleaningId: string | null;
  cleaning?: Cleaning | null;
  clientId: string | null;
  client?: Company | null;
  vendorId: string | null;
  vendor?: Company | null;
  contactPerson?: string;
  reference?: string;
  comment?: string;
  note?: string;
  tenantNote?: string;
  productIds?: string[];
  products?: Product[];
  tripPhotos?: TripPhoto[];
  tripFiles?: File[];
  carNumber?: string;
  economics?: EconomicDto[];
  exportedAt?: Date;
  totalKm: number;
  totalMinutes: number;
  totalKg: number;
  pickUpAddress?: string;
  deliveryAddress?: string;
}

export class TenantNoteEvent {
  tripEventId?: string;
  tenantNote?: string;
}

export interface StringContainer {
  text?: string;
}

export interface PushedInvoiceResponse {
  tripId: string;
  tripEventIds: string[];
  responseCode: number;
  errorMessage: string;
}

export interface TripEventFile {
  id: string;
  files: File[];
}

const store = createStore(
  { name: 'trips' },
  withEntities<Trip>(),
  withActiveId(),
  withRequestsCache(),
  withRequestsStatus()
);
export const trackTripsRequestsStatus = createRequestsStatusOperator(store);
export const skipWhileTripsCached = createRequestsCacheOperator(store);

@Injectable({ providedIn: 'root' })
export class TripsRepository {
  name = store.name;

  activeTrip$ = store.pipe(selectActiveEntity()).pipe(
    map((active) => {
      if (
        active &&
        !active.isOrder &&
        (!active?.parkedAt ||
          (active.parkedAt &&
            active.resumedAt &&
            active.parkedAt < active.resumedAt))
      ) {
        return active;
      }
      return;
    })
  );
  lastActiveEvent$ = this.activeTrip$.pipe(
    map(
      (trip) =>
        trip &&
        sortBy(
          trip.tripEvents.filter((x) => x.type !== 'Weighing'),
          {
            parameter: { property: 'eventTime' },
            direction: 'asc',
          }
        ).pop()
    )
  );
  trips$ = store.pipe(selectAllEntities());
  parkedTrips$ = (id: string) =>
    store
      .pipe(selectAllEntities())
      .pipe(
        map((x) =>
          x.filter(
            (a) =>
              a.userId === id &&
              ((a.parkedAt && !a.resumedAt) ||
                (a.parkedAt && a.resumedAt && a.parkedAt > a.resumedAt))
          )
        )
      );

  one = (id: string) => store.pipe(selectEntity(id));
  events$ = this.trips$.pipe(
    map((trips) =>
      sortBy(
        trips.reduce(
          (events, trip) => events.concat(trip.tripEvents),
          [] as TripEvent[]
        ),
        { parameter: { property: 'eventTime' }, direction: 'desc' }
      )
    )
  );

  clear(): void {
    store.update(deleteAllEntities());
  }

  tripsByUser$ = (id: string) =>
    this.trips$.pipe(map((x) => x.filter((a) => a.userId == id)));

  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  isLoadingCurrent$ = store.pipe(
    selectRequestStatus(`${this.name}_current`),
    map((x) => x.value === 'pending')
  );
  isAdding$ = store.pipe(
    selectRequestStatus(`${this.name}_add`),
    map((x) => x.value === 'pending')
  );

  isLoadingOne$ = (id: Trip['id']) =>
    store.pipe(
      selectRequestStatus(id),
      map((x) => x.value === 'pending')
    );

  setActiveId(id: Trip['id'] | null) {
    store.update(setActiveId(id));
  }

  getResumedTrips() {
    return store
      .query(getAllEntities())
      .filter((a) => a.parkedAt && a.resumedAt && a.parkedAt < a.resumedAt);
  }

  getOne(id: string) {
    return store.query(getEntity(id));
  }

  // getParkedTrips () {
  //   return store.query(getAllEntities()).filter(x => x.parkedAt && !x.resumedAt || x.parkedAt && x.resumedAt && x.parkedAt > x.resumedAt);
  // }

  setTrips(trips: Trip[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(trips),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upsertTrip(trip: Trip, customStatus?: string) {
    if (!trip.isOrder) {
      store.update(
        updateRequestCache(trip.id),
        upsertEntities([trip]),
        updateRequestsStatus([customStatus ?? trip.id], 'success')
      );
    }
  }

  getTrip(id: string) {
    return store.query(getEntity(id));
  }

  getActiveTrip() {
    let active = store.query(getActiveEntity());
    if (
      active &&
      (!active?.parkedAt ||
        (active.parkedAt &&
          active.resumedAt &&
          active.parkedAt < active.resumedAt))
    ) {
      return active;
    }
    return;
  }
}
