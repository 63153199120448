<div class="container-fluid">
  <div class="btn-group" role="group">
    <button
      *ngFor="let pageNumber of pages"
      class="btn"
      [class.btn-outline-primary]="page !== pageNumber"
      [class.btn-primary]="page === pageNumber"
      (click)="changePage(pageNumber); changeState()"
    >
      {{ pageNumber }}
    </button>
    <!-- <div style="padding-left: 10px; padding-top: 5px">
      <app-spinner *ngIf="(loading$ | async) || updateInProgress"></app-spinner>
    </div> -->
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4 col-md-8 col-lg-6">
      <label for="dateFrom" class="col-xs-3 col-sm-auto col-form-label"
        >Fra</label
      >
      <input
        id="dateFrom"
        type="date"
        class="form-control"
        name="dateFrom"
        [(ngModel)]="dateFrom"
        (ngModelChange)="changeState()"
      />
    </div>
    <div class="col-sm-4 col-md-8 col-lg-6">
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Til</label
      >
      <input
        id="dateTo"
        type="date"
        class="form-control"
        name="dateTo"
        [(ngModel)]="dateTo"
        (ngModelChange)="changeState()"
      />
    </div>
    <!-- <div *ngIf="isTiming" style="opacity: 1" class="col-sm-3 col-md-6 col-lg-4">
      <app-spinner *ngIf="(loading$ | async) || isTiming"></app-spinner>
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Kunder</label
      >
      <app-datalist-select
        *ngIf="!(loading$ | async) && !isTiming"
        id="clientsSelect"
        [options]="clientOptions"
        [(activeValue)]="clientSelected"
        (activeValueChange)="changeState()"
        label="Kunder"
        i18n-label="Label client"
      ></app-datalist-select>
    </div> -->

    <div class="col-sm-3 col-md-6 col-lg-4">
      <app-spinner *ngIf="(loading$ | async) || isTiming"></app-spinner>
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Kunder</label
      >
      <app-datalist-select
        *ngIf="!(loading$ | async) || !isTiming"
        id="clientsSelect"
        [options]="clientOptions"
        [(activeValue)]="clientSelected"
        (activeValueChange)="changeState(1)"
        label="Kunder"
        i18n-label="Label client"
      ></app-datalist-select>
    </div>
    <div *ngIf="isAdminPage" class="col-sm-3 col-md-6 col-lg-4">
      <app-spinner *ngIf="(loading$ | async) || isTiming"></app-spinner>
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Chauffør</label
      >
      <app-datalist-select
        *ngIf="!(loading$ | async) || !isTiming"
        id="driversSelect"
        [options]="driverOptions"
        [(activeValue)]="driverSelected"
        (activeValueChange)="changeState(2)"
        label="Chauffør"
        i18n-label="Label driver"
      ></app-datalist-select>
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4">
      <app-spinner *ngIf="(loading$ | async) || isTiming"></app-spinner>
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Produkter</label
      >
      <app-datalist-select
        [class.app-datalist-select-half-height]="
          productSelected && productSelected.length > 1
        "
        *ngIf="!(loading$ | async) || !isTiming"
        id="productsSelect"
        [options]="eproductSortOptions"
        [multiple]="true"
        [(activeValue)]="productSelected"
        (activeValueChange)="changeState(3)"
        label="Produkter"
        i18n-label="Features label"
      ></app-datalist-select>
    </div>
    <!-- <div *ngIf="isAdminPage" class="col-sm-3 col-md-6 col-lg-4  mt-4">
        <label for="isApprovedSearch" class="col-xs-3 col-sm-auto col-form-label  align-middle">Ikke behandlet</label>
        <input id="isApprovedSearch" name="isApprovedSearch" [(ngModel)]="isApprovedSearch" (ngModelChange)="changeState()" class="text-center align-middle" style="margin-left:10px; width: 20px; height: 20px;" type="checkbox" aria-label="">
      </div> -->
    <div class="col-sm-3 col-md-6 col-lg-4 mt-4">
      <button
        class="btn btn-lg btn-outline-primary me-3"
        style="max-height: 78%; width: 48%"
        [class.btn-primary]="tripsEconomicExport.length !== 0"
        [disabled]="tripsEconomicExport.length === 0"
        (click)="bulkExport()"
        i18n="ExportEconomic"
      >
        <h6 class="mt-1">Export to economic</h6>
      </button>
      <button
        class="btn btn-lg btn-outline-primary"
        style="max-height: 78%; width: 48%"
        [class.btn-primary]="tripsEconomicExport.length !== 0"
        [disabled]="tripsEconomicExport.length === 0"
        (click)="bulkExport('true')"
        i18n="ExportEconomicunion"
      >
        <h6 class="mt-1">Export union</h6>
      </button>
    </div>
    <div class="col-sm-12 col-md-10 col-lg-8 mt-4">
      <div
        class="btn-group box-group w-100 box-normal"
        [class.box-bigger]="eprods && eprods.length > 1"
      >
        <div class="form-group form-floating mb-3 w-100 h-100">
          <app-datalist-select
            [class.app-datalist-select-half-height]="
              eprods && eprods.length > 1
            "
            [options]="eproductOptions"
            [multiple]="true"
            [activeValue]="eprods"
            (activeValueChange)="updateValue($event)"
            label="Products"
            i18n-label="Products label"
          ></app-datalist-select>
        </div>
        <button
          class="btn btn-lg btn-outline-primary w-100"
          style="max-height: 78% !important"
          (click)="bulkUpdate()"
          [class.bg-greyish]="
            !tripsGroupUpdate || tripsGroupUpdate.length === 0
          "
          [class.btn-primary]="
            tripsGroupUpdate &&
            tripsGroupUpdate.length !== 0 &&
            this.eprods.length > 0
          "
          [disabled]="!tripsGroupUpdate || tripsGroupUpdate.length === 0"
          i18n="BulkUpdate"
        >
          Update trips
        </button>
      </div>
    </div>
  </div>
  <app-error-alert
    title="Export errors"
    i18n-title="Export errors"
    [errors]="submitErrors"
  ></app-error-alert>
</div>
<div *ngIf="isAdminPage" class="col-12 px-4">
  <label
    for="isApprovedSearch"
    class="col-xs-3 col-sm-auto col-form-label align-middle"
    >Ikke behandlet</label
  >
  <input
    id="isApprovedSearch"
    name="isApprovedSearch"
    [(ngModel)]="isApprovedSearch"
    (ngModelChange)="changeState()"
    class="text-center align-middle"
    style="margin-left: 10px; width: 20px; height: 20px"
    type="checkbox"
    aria-label=""
  />
</div>
<div
  style="width: auto; overflow-x: scroll !important"
  class="table-responsive custom-scroll"
>
  <table
    style="margin-top: 15px"
    class="table table-hover table-bordered"
    [class.darkModeCustom]="ui.isDarkMode$ | async"
  >
    <thead>
      <tr>
        <th
          class="text-center align-middle hoverable"
          [class.px-5]="
            item.danskName === 'Kunde Navn' ||
            item.danskName === 'Kunde' ||
            item.danskName === 'Produktet'
          "
          scope="col"
          *ngFor="let item of simpleColumns; let i = index"
          [hidden]="!item.isVisible"
          sortable="{{
            item.danskName !== 'Vælg' ? standartVariableNames[i] : null
          }}"
          (sort)="item.danskName !== 'Vælg' ? onSort($event) : null"
        >
          {{ item.danskName }}
          <i
            *ngIf="
              item.danskName !== 'Vælg' && headers?.get(i)?.direction === 'asc'
            "
            class="bi bi-arrow-up"
          ></i
          ><i
            *ngIf="
              item.danskName !== 'Vælg' && headers?.get(i)?.direction === 'desc'
            "
            class="bi bi-arrow-down"
          ></i>
          <input
            *ngIf="item.danskName === 'Vælg'"
            [checked]="allSelected"
            (change)="changeAll()"
            style="width: 20px; height: 20px"
            type="checkbox"
            aria-label=""
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        [class.darkModeCustomTr]="
          timeLog?.isApproved ? (ui.isDarkMode$ | async) : ''
        "
        [class.bg-approved]="
          isAdminPage && timeLog?.isApproved && i !== clickedRows[0]
        "
        [class.bg-noclient]="timeLog.failedSyncAt"
        *ngFor="let timeLog of OutPutVales; let i = index"
        [class.bg-yellow]="timeLog.isOrder"
        [class.bg-chosen-hover]="i === clickedRows[0]"
        id="ordering{{ i }}"
      >
        <th id="chosen{{ i }}" class="text-center align-middle">
          <input
            [checked]="timeLog?.exportedAt || checkIfChosen(timeLog?.tripId!)"
            (change)="changeChosen(timeLog, $event)"
            [class.isDisabled]="timeLog?.exportedAt"
            style="width: 20px; height: 20px"
            type="checkbox"
            aria-label=""
          />
        </th>
        <td id="Exported{{ i }}" class="text-center align-middle">
          <div>{{ timeLog?.exportedAt ? "Ja" : "Nej" }}</div>
          <div *ngIf="timeLog?.exportedAt" class="">
            <i
              (click)="unexportCandidate = timeLog"
              class="unexport bi bi-file-earmark-arrow-up"
            ></i>
          </div>
        </td>
        <td id="modal{{ i }}" class="text-center align-middle hoverable">
          <a
            class="anchor"
            *ngIf="
              timeLog.tripEvents &&
              timeLog.tripEvents.length > 0 &&
              checkIfLoadUnloadInEvents(timeLog.tripEvents)
            "
            (click)="getDataToSortModal(timeLog, i)"
            >*</a
          >
        </td>
        <th
          id="Approved{{ i }}"
          *ngIf="isAdminPage"
          class="text-center align-middle"
        >
          <input
            [disabled]="updateInProgress"
            [checked]="timeLog?.isApproved"
            (change)="changeApprove(timeLog)"
            style="width: 20px; height: 20px"
            type="checkbox"
            aria-label=""
          />
        </th>
        <td id="Note{{ i }}" class="text-center align-middle">
          <span
            [innerHTML]="compareStrings(timeLog.noteEnd, timeLog.officeNote)"
          ></span>
          <br />
          <a
            class="btn btn-sm btn-link py-0 p-md-0"
            data-bs-toggle="modal"
            data-bs-target="#notemodal"
            (click)="loadDataForPopUp(timeLog)"
          >
            <i class="bi bi-pencil me-1"></i
          ></a>
        </td>
        <td
          id="TenantNote{{ i }}"
          *ngIf="isAdminPage"
          class="text-center align-middle hoverable"
        >
          <a
            *ngIf="timeLog.tenantNote"
            [class.anchor-blue]="timeLog.tenantNote"
            (click)="openTenantComment(timeLog)"
            >*</a
          >
        </td>
        <td id="Numberplate{{ i }}" class="text-center align-middle">
          {{ timeLog.carNumber }}
        </td>
        <td id="Trailer{{ i }}" class="text-center align-middle">
          {{ timeLog.trailerNumber }}
        </td>
        <td
          id="ClientID{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(
              timeLog.clientIdNumberStart,
              timeLog.clientIdNumberEnd
            )
          "
        ></td>
        <td id="ClientName{{ i }}" class="text-center align-middle">
          <span
            [innerHTML]="
              compareStrings(timeLog.clientNameStart, timeLog.clientNameEnd)
            "
          ></span>
          <a
            class="btn btn-sm btn-link py-0 mx-3"
            (click)="openClientDropdown(timeLog)"
          >
            <i *ngIf="!timeLog?.exportedAt" class="bi bi-pencil ms-2 me-1"></i>
            <i
              *ngIf="timeLog?.exportedAt"
              class="bi bi-eye strong ms-2 me-1"
            ></i>
            <i
              [class.exported]="timeLog?.exportedAt"
              class="bi bi-check-lg ms-1 me-1 anchor-green-smaller"
              *ngIf="!checkIfClientsFromEconomic(timeLog)"
            ></i>
            <i
              [class.exported]="timeLog?.exportedAt"
              class="bi bi-x-circle anchor-red-smaller ms-1 me-1"
              *ngIf="checkIfClientsFromEconomic(timeLog)"
            ></i>
          </a>
        </td>
        <td id="Product{{ i }}" class="text-center align-middle">
          <a
            class="btn btn-sm btn-link py-0 p-md-0"
            (click)="openProductGroupDropdown(timeLog)"
          >
            <i *ngIf="!timeLog?.exportedAt" class="bi bi-pencil ms-2 me-1"></i>
            <i
              *ngIf="timeLog?.exportedAt"
              class="bi bi-eye strong ms-2 me-1"
            ></i>
            <i
              [class.exported]="timeLog?.exportedAt"
              class="bi bi-check-lg ms-2 me-1 anchor-green-smaller"
              *ngIf="checkEconomicIsThere(timeLog)"
            ></i>
            <i
              [class.exported]="timeLog?.exportedAt"
              class="bi bi-x-circle anchor-red-smaller ms-1 me-1"
              *ngIf="!checkEconomicIsThere(timeLog)"
            ></i>
          </a>
        </td>
        <td id="DriverID{{ i }}" class="text-center align-middle">
          {{ timeLog.driverName }}
        </td>
        <td
          id="ProductName{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.productNameStart, timeLog.productNameEnd)
          "
        ></td>
        <td id="Address{{ i }}" class="text-center align-middle">
          <span
            [innerHTML]="
              compareStrings(timeLog.pickUpAddress, timeLog.deliveryAddress)
            "
          ></span
          ><br />
          <a
            class="btn btn-sm btn-link py-0 p-md-0"
            data-bs-toggle="modal"
            data-bs-target="#addressmodal"
            (click)="loadDataForAddressPopUp(timeLog)"
            ><i class="bi bi-pencil me-1"></i
          ></a>
        </td>
        <td
          id="Weight{{ i }}"
          class="text-center align-middle"
          [innerHTML]="compareStrings(timeLog.weightStart, timeLog.weightEnd)"
        ></td>
        <td id="TripTotalKm{{ i }}" class="text-center align-middle">
          {{ timeLog.tripKm ?? 0 }}
        </td>
        <td id="Totaltime{{ i }}" class="text-center align-middle">
          {{ timeLog.tripTime }}
        </td>
        <td
          id="VendorID{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(
              timeLog.vendorIdNumberStart,
              timeLog.vendorIdNumberEnd
            )
          "
        ></td>
        <td
          id="VendorName{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.vendorNameStart, timeLog.vendorNameEnd)
          "
        ></td>
        <td
          id="Reference{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.referenceStart, timeLog.referenceEnd)
          "
        ></td>
        <td
          id="Orderinfo{{ i }}"
          class="text-center align-middle"
          [innerHTML]="compareStrings(timeLog.commentStart, timeLog.commentEnd)"
        ></td>
        <td id="TripStartphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="timeLog?.startEventPhotos">
            <a
              *ngIf="(timeLog?.startEventPhotos)!.length > 0"
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(timeLog?.startEventPhotos, '', timeLog)"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
        </td>
        <td id="Weighingphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="timeLog?.weighingPhotos">
            <a
              *ngIf="(timeLog?.weighingPhotos)!.length > 0"
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(timeLog?.weighingPhotos, 'weigh', timeLog)"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
        </td>
        <td id="TripStarttime{{ i }}" class="text-center align-middle">
          {{ timeLog.startEventTime }}
        </td>
        <td id="TripStartKm{{ i }}" class="text-center align-middle">
          {{ timeLog.kmStart }}
        </td>
        <td
          [hidden]="true"
          id="EditTripStart{{ i }}"
          class="text-center align-middle"
        >
          <a
            class="btn btn-sm btn-link py-0 p-md-0"
            [routerLink]="[
              '/trips',
              'edit',
              timeLog.tripEventIdStart,
              isAdminPage
            ]"
          >
            <i class="bi bi-pencil me-1"></i>
            <ng-container i18n="@@edit">Edit</ng-container>
          </a>
        </td>
        <td id="StartTripposition{{ i }}" class="text-center align-middle">
          <a target="_blank" href="{{ timeLog.startPosition }}">Link</a>
        </td>
        <td id="TripEndphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="timeLog?.endEventPhotos">
            <a
              *ngIf="(timeLog?.endEventPhotos)!.length > 0"
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(timeLog?.endEventPhotos, '', timeLog)"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
        </td>
        <td id="TripEndTime{{ i }}" class="text-center align-middle">
          {{ timeLog.endEventTime }}
        </td>
        <td id="TripEndKm{{ i }}" class="text-center align-middle">
          {{ timeLog.kmEnd }}
        </td>
        <td
          [hidden]="true"
          id="EditTripEnd{{ i }}"
          class="text-center align-middle"
        >
          <a
            *ngIf="timeLog.tripEventIdEnd"
            class="btn btn-sm btn-link py-0 p-md-0"
            [routerLink]="[
              '/trips',
              'edit',
              timeLog.tripEventIdEnd,
              isAdminPage
            ]"
          >
            <i class="bi bi-pencil me-1"></i>
            <ng-container i18n="@@edit">Edit</ng-container>
          </a>
        </td>
        <td id="TripEndGPS{{ i }}" class="text-center align-middle">
          <a
            *ngIf="timeLog.endPosition"
            target="_blank"
            href="{{ timeLog.endPosition }}"
            >Link</a
          >
        </td>

        <td
          id="Cleaning{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.cleaningStart, timeLog.cleaningEnd)
          "
        ></td>
        <td
          id="Accessory{{ i }}"
          class="Accessory{{ i }}"
          class="text-center align-middle"
          [innerHTML]="
            compareStrings(timeLog.accessoryNameStart, timeLog.accessoryNameEnd)
          "
        ></td>
      </tr>
    </tbody>
  </table>
</div>

<div class="container-fluid mt-4">
  <div class="btn-group" role="group">
    <button
      *ngFor="let pageNumber of pages"
      class="btn"
      [class.btn-outline-primary]="page !== pageNumber"
      [class.btn-primary]="page === pageNumber"
      (click)="changePage(pageNumber); changeState()"
    >
      {{ pageNumber }}
    </button>
    <div style="padding-left: 10px; padding-top: 5px">
      <app-spinner *ngIf="loading$ | async"></app-spinner>
    </div>
  </div>
</div>

<div
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  id="notemodal"
  tabindex="-1"
  aria-labelledby="notemodal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6 i18n="Title for notemodal window">Edit note</h6>
        <button
          type="button"
          class="btn-close"
          (click)="resetChild()"
          #closeModal
          data-bs-dismiss="modal"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <div class="modal-body">
        <div #notecontainer class="form-floating mb-3"></div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  id="addressmodal"
  tabindex="-1"
  aria-labelledby="addressmodal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6 i18n="Title for addressmodal window">Edit address</h6>
        <button
          type="button"
          class="btn-close"
          (click)="resetOfficeChild()"
          #closeOfficeModal
          data-bs-dismiss="modal"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <div class="modal-body">
        <div #addresscontainer class="form-floating mb-3"></div>
      </div>
    </div>
  </div>
</div>

<app-confirm-action
  *ngIf="unexportCandidate"
  (confirm)="
    handleUnexportClick(unexportCandidate.tripId); unexportCandidate = null
  "
  (cancel)="unexportCandidate = null"
></app-confirm-action>

<app-image-slider
  id="photoModal"
  [urls]="urls"
  [weighEvents]="weighEvents"
  [usualEvents]="usualEvents"
  (weighPhotoEmmiter)="getWeightPhotos($event)"
  (usualPhotoEmmiter)="getUsualPhotos($event)"
></app-image-slider>
<app-sort-table-modal
  id="sortModal"
  [isDarkMode]="ui.isDarkMode$ | async"
  (modalPhotoEmmiter)="getUsualPhotos($event)"
  [isAdminPage]="isAdminPage"
  [muted]="false"
  [tripEvents]="tripEvents"
  [clickedRows]="clickedRows"
  [simpleLoadUnloadColumns]="simpleLoadUnloadColumns"
></app-sort-table-modal>
<app-single-textbox-modal
  *ngIf="titleTenantNote"
  [title]="titleTenantNote"
  [mutable]="false"
  [text]="textTenantNote"
  (stringEmmiter)="addTenantNote($event)"
  (cancel)="cancelTenantNote()"
></app-single-textbox-modal>
<app-single-group-modal
  id="productGroupModal"
  *ngIf="trip && isWorkEwentsLoaded"
  (economicEmmiter)="updateGroups($event)"
  [title]="prodGroupTitle"
  [eproducts]="prodSort"
  [eproductsDeleted]="eProdRepo.allDeleted$ | async"
  [priceTypes]="priceTypes"
  [workEvents]="workEvents"
  [trip]="trip"
  (close)="isWorkEwentsLoaded = false"
></app-single-group-modal>
<!-- <div #productsContainer></div> -->
<div #clentsContainer></div>
<!-- <app-client-update-modal
  id="clientsModal"
  *ngIf="clientTrip"
  (clientsEmmiter)="updateClients($event)"
  [title]="clientTitle"
  [trip]="clientTrip"
  [clients]="companyRepo.all$ | async"
  [clientsDeleted]="companyRepo.allDeleted$ | async"
></app-client-update-modal> -->
