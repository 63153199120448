import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-single-textbox-modal',
  templateUrl: './single-textbox-modal.component.html',
  styleUrls: ['./single-textbox-modal.component.scss'],
})
export class SingleTextboxModalComponent implements OnInit {
  @Input() title: string | null = null;
  @Input() mutable = true;
  @Input() text: string[] | null = null;
  @Output() stringEmmiter = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  cancelEmit() {
    this.cancel.emit();
  }

  save() {
    if (this.text) {
      this.stringEmmiter.emit(
        (<HTMLInputElement>document.getElementById('textbox')).value
      );
    }
  }
}
