import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { async, Observable } from 'rxjs';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { Column, ColumnsRepository } from 'src/app/state/columns.repository';
import { ColumnsService } from 'src/app/state/columns.service';
import {
  CompaniesRepository,
  Company,
} from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { TripEventsService } from 'src/app/state/tripevents.service';
import {
  Product,
  ProductsRepository,
} from '../../../../state/products.repository';
import {
  ClientEventDto,
  ClientEventInfoDto,
  EconomicDto,
  EconomicInfo,
  EconomicInfoExtended,
  PhotoEventDto,
  PhotoModalEventDto,
  TenantNoteEvent,
  Trip,
  TripDto,
  TripsRepository,
} from '../../../../state/trips.repository';
import { TripsService } from '../../../../state/trips.service';
import { User, UsersRepository } from '../../../../state/users.repository';
import { UsersService } from '../../../../state/users.service';
import { EProductsService } from 'src/app/state/eproducts.service';
import { PriceTypesService } from 'src/app/state/pricetypes.service';
import {
  PriceType,
  PriceTypeRepository,
} from 'src/app/state/pricetypes.repository';
import { EProductsRepository } from 'src/app/state/eproducts.repository';
import { Workday, WorkdayEvent } from 'src/app/state/workdays.repository';
import { WorkDayEventsService } from 'src/app/state/workdayevents.service';
import { WorkDayEventInfo } from 'src/app/state/workdayevents.repository';

@Component({
  selector: 'app-economic-page',
  templateUrl: './economic-page.component.html',
  styleUrls: ['./economic-page.component.scss'],
})
export class EconomicPageComponent implements OnInit {
  dateType?: 'day' | ' week' | 'month';
  drivers: User[] | null = [];
  workDayEvents$: Observable<WorkdayEvent[]> | null = null;
  trips: Trip[] | null | any = null;
  products: Product[] | null | any = null;

  eProducts: Product[] | null = null;
  clients: Company[] | null | any = null;
  deletedClients: Company[] | null = null;
  columns: Column[] | null = null;
  priceTypes: PriceType[] | null = null;

  isColumnsLoaded: boolean = false;
  isEProductsLoaded: boolean = false;
  isClientsLoaded: boolean = false;
  isDeletedClientsLoaded: boolean = false;
  isPriceTypesLoaded: boolean = false;
  updateInProgress: boolean = false;

  constructor(
    public authRepo: AuthRepository,
    public repo: TripsRepository,
    public service: TripsService,
    public router: ActivatedRoute,
    public usersRepo: UsersRepository,
    public usersService: UsersService,
    private companyService: CompaniesService,
    public companyRepo: CompaniesRepository,
    private columnsService: ColumnsService,
    public columnsRepo: ColumnsRepository,
    private tripEventService: TripEventsService,
    private route: Router,
    private eProdService: EProductsService,
    public eProdRepo: EProductsRepository,
    private priceTypeService: PriceTypesService,
    public priceTypeRepo: PriceTypeRepository,
    private workEventService: WorkDayEventsService
  ) {
    // this.eProdService.loadDeleted().subscribe();
  }

  ngOnInit(): void {
    this.service.loadOverview().subscribe({
      complete: () => {
        this.trips = this.repo.trips$;
        this.usersService.loadDrivers().subscribe((x) => (this.drivers = x));
        this.companyService.loadAllAsList().subscribe((x) => {
          this.clients = x.filter((f) => f.canBeClient);
          this.isClientsLoaded = true;
        });
        this.companyService.loadAllDeletedasList().subscribe((x) => {
          this.deletedClients = x;
          this.isDeletedClientsLoaded = true;
        });
        this.eProdService.loadAll().subscribe((x) => {
          this.eProducts = x;
          this.isEProductsLoaded = true;
        });
      },
    });
    this.dateType = this.router.snapshot.data.dateType;

    this.columnsService.loadAll().subscribe((x) => {
      this.columns = x.filter((x) => x.name != 'Trip Info');
      this.isColumnsLoaded = true;
    });
    this.priceTypeService.loadTypes().subscribe((x) => {
      this.priceTypes = x;
      this.isPriceTypesLoaded = true;
    });
  }

  checkIfDataLoaded() {
    return (
      this.isClientsLoaded &&
      this.isDeletedClientsLoaded &&
      this.isColumnsLoaded &&
      this.isEProductsLoaded &&
      this.isPriceTypesLoaded
    );
  }

  updateTripProducts($event: EconomicInfoExtended) {
    this.tripEventService.updateEProducts($event).subscribe({
      complete: () => {
        this.updateInProgress = true;
        this.service.loadOverview().subscribe({
          complete: () => {
            this.updateInProgress = false;
            //this.route.navigate(['/drivers/economic']);
          },
          error: () => {
            this.updateInProgress = false;
          },
        });
      },
    });
  }

  updateTripEventClients($event: ClientEventInfoDto[]) {
    let dto = { clientEventInfoDtos: $event } as ClientEventDto;
    this.tripEventService.updateClients(dto).subscribe({
      complete: () => {
        this.updateInProgress = true;
        this.service.loadOverview().subscribe({
          complete: () => {
            this.updateInProgress = false;
            //this.route.navigate(['/drivers/economic']);
          },
          error: () => {
            this.updateInProgress = false;
          },
        });
      },
    });
  }

  updateTenantNote($event: TenantNoteEvent) {
    this.tripEventService.updateTenantNote($event).subscribe({
      complete: () => {
        this.service.loadOverview().subscribe();
      },
    });
  }

  clientsDeleted: Company[] | null | any = null;

  updatePhotoEvent($event: PhotoEventDto | PhotoModalEventDto) {
    if ($event.url) {
      this.service.changePhotoEvent($event.url).subscribe({
        complete: () => {
          if (this.authRepo.isInRole(UserRoles.Driver)) {
            this.service.loadOverview().subscribe({
              complete: () => {
                this.route.navigate(['/trips']);
              },
            });
          } else {
            this.service.loadOverview().subscribe({
              complete: () => {
                this.route.navigate(['/drivers/trips']);
              },
            });
          }
        },
      });
    }
  }
}
