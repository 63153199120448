<div class="row mb-4">
  <div class="col">
    <h2 i18n="Title for the Economic">Economic</h2>
  </div>
</div>
<div class="row mb-4"></div>
<div class="row mb-4">
  <div class="col">
    <app-spinner
      *ngIf="!checkIfDataLoaded()"
      [showWithText]="true"
    ></app-spinner>
    <app-economic-table
      *ngIf="checkIfDataLoaded()"
      (economicEmmiter)="updateTripProducts($event)"
      (clientsEmmiter)="updateTripEventClients($event)"
      (tenantNoteEmmiter)="updateTenantNote($event)"
      (usualPhotoEmmiter)="updatePhotoEvent($event)"
      (weighPhotoEmmiter)="updatePhotoEvent($event)"
      (modalPhotoEmmiter)="updatePhotoEvent($event)"
      [dateType]="dateType"
      [prodSort]="eProducts"
      [clientSort]="clients"
      [driverSort]="drivers"
      [clients]="clients"
      [clientsDeleted]="deletedClients"
      [drivers]="drivers"
      [timeLogs]="repo.trips$"
      [eproducts]="eProducts"
      [priceTypes]="priceTypes"
      [columns]="columns"
      [updateInProgress]="updateInProgress"
    ></app-economic-table>
  </div>
</div>
<div
  *ngIf="updateInProgress"
  class="w-100 d-flex justify-content-center align-item-center"
  style="
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    top: 0;
    left: 0;
  "
>
  <div
    class="spinner-border m-auto text-light"
    style="width: 5em; height: 5em"
    role="status"
  >
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
