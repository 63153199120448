<form *ngIf="form" [formGroup]="form" (ngSubmit)="save()">
    <div class="col-12" *ngIf="locationCheck()">      
        <div class="form-group form-floating  mb-3">
          <div class="input-group form-floating">
            <input ngx-google-places-autocomplete [options]='myOptions' #placePickRef='ngx-places' (onAddressChange)='handleAddressChange($event, "pickUpAddress")' formControlName="pickUpAddress" aria-label="pickUpAddress" aria-describedby="pickUpAddressButton" class="form-control" 
            >
            <label class="visible" for="pickUpAddress" i18n="pickUpAddress label">PickUp Address</label>
            <button class="btn btn-outline-secondary" [disabled]="!form.value.pickUpAddress || form.value.pickUpAddress.length < 5" type="button" (click)="redirectToAddressOnMaps(form.value.pickUpAddress)" id="pickUpAddressButton"><i class="bi bi-geo-alt-fill strong danger-text"></i></button>
          </div>
        </div>      
      </div>
      <div class="col-12" *ngIf="locationCheck()">      
        <div class="form-group form-floating mb-3">
          <div class="input-group form-floating">
            <input ngx-google-places-autocomplete [options]='myOptions' #placeDelRef='ngx-places' (onAddressChange)='handleAddressChange($event, "deliveryAddress")' formControlName="deliveryAddress" aria-label="deliveryAddress" aria-describedby="deliveryAddressButton" class="form-control"
            >
            <label  class="visible"for="deliveryAddress" i18n="deliveryAddress label">Delivery Address</label>
            <button class="btn btn-outline-secondary" [disabled]="!form.value.deliveryAddress || form.value.deliveryAddress.length < 5" type="button" (click)="redirectToAddressOnMaps(form.value.deliveryAddress)" id="deliveryAddressButton"><i class="bi bi-geo-alt-fill strong danger-text"></i></button>
          </div>              
        </div>      
      </div>
    <div class="form-floating mb-3">
        <div style="width:auto;overflow-x:scroll;overflow-y:scroll;" class="table-responsive custom-scroll">
            <div class="modal-footer justify-content-center">                    
                <button class="btn btn-primary w-100"
                    (click)="save()" i18n="Save button">Save</button>
                <button class="btn btn-secondary w-100"
                    (click)="cancel()" i18n="@@cancel">Cancel</button>
            </div>
        </div>  
    </div>
</form>
