import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { GoPageComponent } from './pages/go-page/go-page.component';
import { TripSettingsPageComponent } from './pages/trip-settings-page/trip-settings-page.component';
import { TripEventPageComponent } from './pages/trip-event-page/trip-event-page.component';
import { VehicleFormComponent } from './components/vehicle-form/vehicle-form.component';
import { VehicleListComponent } from './components/vehicle-list/vehicle-list.component';
import { VehiclePageComponent } from './pages/vehicle-page/vehicle-page.component';
import { VehicleCreatePageComponent } from './pages/vehicle-create-page/vehicle-create-page.component';
import { TrailerFormComponent } from './components/trailer-form/trailer-form.component';
import { TrailerCreatePageComponent } from './pages/trailer-create-page/trailer-create-page.component';
import { TrailerListComponent } from './components/trailer-list/trailer-list.component';
import { TrailerPageComponent } from './pages/trailer-page/trailer-page.component';
import { StartDayFormComponent } from './components/start-day-form/start-day-form.component';
import { StartTripFormComponent } from './components/start-trip-form/start-trip-form.component';
import { EndDayFormComponent } from './components/end-day-form/end-day-form.component';
import { TripSettingsComponent } from './components/trip-settings/trip-settings.component';
import { TripInfoFormComponent } from './components/trip-info-form/trip-info-form.component';
import { EndTripFormComponent } from './components/end-trip-form/end-trip-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AccessoryPageComponent } from './pages/accessory-page/accessory-page.component';
import { AccessoryCreatePageComponent } from './pages/accessory-create-page/accessory-create-page.component';
import { AccessoryFormComponent } from './components/accessory-form/accessory-form.component';
import { AccessoryListComponent } from './components/accessory-list/accessory-list.component';
import { CleaningPageComponent } from './pages/cleaning-page/cleaning-page.component';
import { CleaningCreatePageComponent } from './pages/cleaning-create-page/cleaning-create-page.component';
import { CleaningListComponent } from './components/cleaning-list/cleaning-list.component';
import { CleaningFormComponent } from './components/cleaning-form/cleaning-form.component';
import { ProductPageComponent } from './pages/product-page/product-page.component';
import { ProductCreatePageComponent } from './pages/product-create-page/product-create-page.component';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { CompanyCreatePageComponent } from './pages/company-create-page/company-create-page.component';
import { CompanyListComponent } from './components/company-list/company-list.component';
import { CompanyFormComponent } from './components/company-form/company-form.component';
import { ActivePauseComponent } from './components/active-pause/active-pause.component';
import { DayHistoryPageComponent } from './pages/day-history-page/day-history-page.component';
import { DayTimelineComponent } from './components/day-timeline/day-timeline.component';
import { TripHistoryPageComponent } from './pages/trip-history-page/trip-history-page.component';
import { TripTimelineComponent } from './components/trip-timeline/trip-timeline.component';
import { ModelCardComponent } from './components/model-card/model-card.component';
import { DayEventPageComponent } from './pages/day-event-page/day-event-page.component';
import { DayInfoFormComponent } from './components/day-info-form/day-info-form.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DriverLogsPageComponent } from './pages/driver-logs-page/driver-logs-page.component';
import { DriversListComponent } from './components/drivers-list/drivers-list.component';
import { SortTableComponent } from './components/sort-table/sort-table.component';
import { NgbdSortableHeader } from './components/sortable.directive';
import { DriversWorkdaysPageComponent } from './pages/drivers-workdays-page/drivers-workdays-page.component';
import { SortTableWorkdaysComponent } from './components/sort-table-workdays/sort-table-workdays.component';
import { ColumnsPageComponent } from './pages/columns-page/columns-page.component';
import { ColumnsListComponent } from './components/columns-list/columns-list.component';
import { ColumnCardComponent } from './components/column-card/column-card.component';
import { SortTableModalComponent } from './components/sort-table-modal/sort-table-modal.component';
import { ActiveDriversListComponent } from './components/active-drivers-list/active-drivers-list.component';
import { ActiveDriversPageComponent } from './pages/active-drivers-page/active-drivers-page.component';
import { GoInfoComponent } from './components/go-info/go-info.component';
import { OrdersPageComponent } from './pages/orders-page/orders-page.component';
import { OrdersFilterComponent } from './components/orders-filter/orders-filter.component';
import { OrderFormComponent } from './components/order-form/order-form.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { StartOrderFormComponent } from './components/start-order-form/start-order-form.component';
import { EndOrderFormComponent } from './components/end-order-form/end-order-form.component';
import { OrderEventPageComponent } from './pages/order-event-page/order-event-page.component';
import { OrderInfoFormComponent } from './components/order-info-form/order-info-form.component';
import { GoOrderInfoComponent } from './components/go-order-info/go-order-info.component';
import { NavigationPageComponent } from './pages/navigation-page/navigation-page.component';
import { EconomicPageComponent } from './pages/economic-page/economic-page.component';
import { EconomicTableComponent } from './components/economic-table/economic-table.component';
import { ClientUpdateModalComponent } from './components/client-update-modal/client-update-modal.component';
import { EproductEditPageComponent } from './pages/eproduct-edit-page/eproduct-edit-page.component';
import { EproductsPageComponent } from './pages/eproducts-page/eproducts-page.component';
import { EproductListComponent } from './components/eproduct-list/eproduct-list.component';
import { EproductFormComponent } from './components/eproduct-form/eproduct-form.component';
import { CoreModule } from '../core/core.module';
import { ParkedTripsModalComponent } from './components/parked-trips-modal/parked-trips-modal.component';
import { DeletedTripsPageComponent } from './pages/deleted-trips-page/deleted-trips-page.component';
import { DeletetedTripsListComponent } from './components/deleteted-trips-list/deleteted-trips-list.component';
import { TripNavigationPageComponent } from './pages/trip-navigation-page/trip-navigation-page.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OfficeNoteFormComponent } from './components/office-note-form/office-note-form.component';
import { OfficeAddressFormComponent } from './components/office-address-form/office-address-form.component';
import { TemplatesPageComponent } from './pages/templates-page/templates-page.component';
import { CreateTemplatePageComponent } from './pages/create-template-page/create-template-page.component';
import { TemplatesListComponent } from './components/templates-list/templates-list.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NotesInfoModalComponent } from './components/notes-info-modal/notes-info-modal.component';
import { TripChangeDriverModalComponent } from './components/trip-change-driver-modal/trip-change-driver-modal.component';
import { CommentphotosFormComponent } from './components/commentphotos-form/commentphotos-form.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';

@NgModule({
  declarations: [
    GoPageComponent,
    TripSettingsPageComponent,
    TripEventPageComponent,
    VehicleFormComponent,
    VehicleListComponent,
    VehiclePageComponent,
    VehicleCreatePageComponent,
    TrailerFormComponent,
    TrailerCreatePageComponent,
    TrailerListComponent,
    TrailerPageComponent,
    StartDayFormComponent,
    StartTripFormComponent,
    EndDayFormComponent,
    TripSettingsComponent,
    TripInfoFormComponent,
    EndTripFormComponent,
    AccessoryPageComponent,
    AccessoryCreatePageComponent,
    AccessoryFormComponent,
    AccessoryListComponent,
    CleaningPageComponent,
    CleaningCreatePageComponent,
    CleaningListComponent,
    CleaningFormComponent,
    ActivePauseComponent,
    ProductPageComponent,
    ProductCreatePageComponent,
    ProductFormComponent,
    ProductListComponent,
    CompanyPageComponent,
    CompanyCreatePageComponent,
    CompanyListComponent,
    CompanyFormComponent,
    DayHistoryPageComponent,
    DayTimelineComponent,
    TripHistoryPageComponent,
    TripTimelineComponent,
    ModelCardComponent,
    DayEventPageComponent,
    DayInfoFormComponent,
    DashboardComponent,
    DriverLogsPageComponent,
    DriversListComponent,
    SortTableComponent,
    NgbdSortableHeader,
    DriversWorkdaysPageComponent,
    SortTableWorkdaysComponent,
    ColumnsPageComponent,
    ColumnsListComponent,
    ColumnCardComponent,
    SortTableModalComponent,
    ActiveDriversListComponent,
    ActiveDriversPageComponent,
    GoInfoComponent,
    OrdersPageComponent,
    OrdersFilterComponent,
    OrderFormComponent,
    StartOrderFormComponent,
    EndOrderFormComponent,
    OrderEventPageComponent,
    OrderInfoFormComponent,
    GoOrderInfoComponent,
    NavigationPageComponent,
    EconomicPageComponent,
    EconomicTableComponent,
    ClientUpdateModalComponent,
    EproductEditPageComponent,
    EproductsPageComponent,
    EproductListComponent,
    EproductFormComponent,
    ParkedTripsModalComponent,
    EproductFormComponent,
    DeletedTripsPageComponent,
    DeletetedTripsListComponent,
    TripNavigationPageComponent,
    OfficeNoteFormComponent,
    OfficeAddressFormComponent,
    TemplatesPageComponent,
    CreateTemplatePageComponent,
    TemplatesListComponent,
    NotesInfoModalComponent,
    TripChangeDriverModalComponent,
    CommentphotosFormComponent,
    FileUploaderComponent,
  ],
  imports: [
    GooglePlaceModule,
    CommonModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule.forRoot(),
    NzButtonModule,
  ],
  providers: [DecimalPipe, DatePipe],
  exports: [
    GoPageComponent,
    TripSettingsPageComponent,
    TripEventPageComponent,
    VehiclePageComponent,
    VehicleCreatePageComponent,
    TrailerCreatePageComponent,
    TrailerPageComponent,
    AccessoryPageComponent,
    AccessoryCreatePageComponent,
    CleaningPageComponent,
    CleaningCreatePageComponent,
    ProductPageComponent,
    ProductCreatePageComponent,
    CompanyPageComponent,
    CompanyCreatePageComponent,
    DayHistoryPageComponent,
    TripHistoryPageComponent,
    DayEventPageComponent,
    DashboardComponent,
    DriverLogsPageComponent,
    DriversWorkdaysPageComponent,
    ClientUpdateModalComponent,
    ParkedTripsModalComponent,
  ],
})
export class TruckModule {}
