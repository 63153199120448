<div class="row mb-3" *ngIf="endNote">   
    <div class="form-group form-floating mb-3">
        <textarea readonly id="textbox" [innerHtml]="endNote" class="form-control"></textarea>
        <label class="ms-2" for="driverNote" i18n="driverNote">Driver note</label>                 
    </div>                  
</div>

<div class="row mb-3">   
    <div class="form-group form-floating mb-3">
        <textarea id="textbox" [(ngModel)]="officeNote" class="form-control"></textarea>          
        <label class="ms-2" for="officeNote" i18n="officeNote">Office note</label>          
    </div>                  
</div>
<div class="form-floating mb-3">
    <div style="width:auto;overflow-x:scroll;overflow-y:scroll;" class="table-responsive custom-scroll">
        <div class="modal-footer justify-content-center">                    
            <button class="btn btn-primary w-100"
                (click)="save()" i18n="Save button">Save</button>
            <button class="btn btn-secondary w-100"
                (click)="cancel()" i18n="@@cancel">Cancel</button>
        </div>
    </div>
</div>