import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleColumn } from 'src/app/state/columns.repository';
import { TripEventsService } from 'src/app/state/tripevents.service';
import {
  PhotoModalEventDto,
  TripEventDto,
  TripEventFile,
  TripPhoto,
} from 'src/app/state/trips.repository';

@Component({
  selector: 'app-sort-table-modal',
  templateUrl: './sort-table-modal.component.html',
  styleUrls: ['./sort-table-modal.component.scss'],
})
export class SortTableModalComponent implements OnInit {
  display: string = 'block !important';
  opacity: string = '1 !important';
  @Output() takePhotos = new EventEmitter<TripEventFile>();
  selectedId = '';
  existingRow: HTMLElement | null = null;
  @Input() tripEvents: TripEventDto[] | null = null;
  @Input() muted = true;
  @Input() clickedRows: number[] | null = null;

  @Input() simpleLoadUnloadColumns: SimpleColumn[] | null = null;
  @Input() isDarkMode: boolean | null = null;
  @Input() isAdminPage: boolean | null = null;
  @Output() modalPhotoEmmiter = new EventEmitter<PhotoModalEventDto>();
  urls = new Array<string>();
  usualEvents = new Array<TripEventDto>();
  constructor(
    private tripEventService: TripEventsService,
    public router: Router
  ) {}

  ngOnInit(): void {}

  addImage($event: any) {
    let pushFiles = new Array<File>();
    let files = $event.target.files;
    if (pushFiles && this.selectedId) {
      this.takePhotos.emit({ files, id: this.selectedId });
      this.obliterate();
    }
  }

  obliterate() {
    this.tripEvents = [];
    this.clickedRows?.pop();
  }

  getUrls(files?: TripPhoto[], event?: TripEventDto) {
    this.urls = [];
    this.usualEvents = [];
    if (files) {
      this.urls = files?.map((x) => x.url);
    }
    if (event) {
      this.usualEvents.push(event);
    }
  }

  navigateTo(str: string) {
    this.tripEventService.loadOne(str).subscribe((x) => {
      if (x) {
        this.router.navigate(['/trips/edit/' + x?.id + '/' + this.isAdminPage]);
      }
    });
  }

  getModalPhotos($event: PhotoModalEventDto) {
    if ($event.url && this.urls) {
      const index = this.urls.indexOf($event.url);
      if (index > -1) {
        this.urls.splice(index, 1);
      }
      this.modalPhotoEmmiter.emit($event);
      let eventToUpdate = this.usualEvents[0];
      let photoToRemove = eventToUpdate.photos?.find(
        (x) => x.url === $event.url
      );
      this.tripEvents?.map((x) => {
        return (x.photos = x.photos?.filter(
          (a) => a.url !== photoToRemove?.url
        ));
      });
    }
  }

  getDanishEventType(type: string): string {
    if (type === 'TripStart') {
      return 'Start';
    }
    if (type === 'TripEnd') {
      return 'Slut';
    }
    if (type === 'Load') {
      return 'Laste';
    }
    if (type === 'Unload') {
      return 'Losse';
    }
    return '';
  }

  ngAfterContentChecked() {
    this.tripEvents?.forEach((x, y) => {
      let row = document.getElementById(`orderingunload${y}`);
      this.existingRow = row;
      this.simpleLoadUnloadColumns?.forEach((a, b) => {
        let sortedColumnName = `${a.name}${y}`;
        let unsortedColumnName = row?.children.item(b)?.id;
        if (row) {
          let sortedColumn = row.querySelector(`#${sortedColumnName}`);
          let unsortedColumn = row.querySelector(`#${unsortedColumnName}`);
          if (sortedColumn) {
            row.insertBefore(sortedColumn, unsortedColumn);
          }
        }
        if (!a.isVisible && !this.isAdminPage) {
          let toRemove = `${a.name}${y}`;
          let toRemoveColumn = row?.querySelector(`#${toRemove}`);
          if (row && toRemoveColumn) {
            row.removeChild(toRemoveColumn);
          }
        }
        if (!a.isVisible && this.isAdminPage) {
          let toRemove = `${a.name}${y}`;
          let toRemoveColumn = row?.querySelector(`#${toRemove}`);
          if (row && toRemoveColumn) {
            row.removeChild(toRemoveColumn);
          }
        }
      });
    });
  }
}
