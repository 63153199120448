import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseService } from 'src/app/base-service';

@Component({
  selector: 'app-office-note-form',
  templateUrl: './office-note-form.component.html',
  styleUrls: ['./office-note-form.component.scss']
})
export class OfficeNoteFormComponent implements OnInit {
startNote: string | null = null;
endNote: string | null = null;
officeNote: string | null = null;
tripId: string | null = null;

constructor(private baseService: BaseService) {
  
}

ngOnInit(): void {
 
}


save() {
  this.baseService.sendSaveEvent({id:this.tripId, note: this.officeNote})
}

cancel() {
  this.baseService.sendCancelEvent(true);
  this.baseService.sendDestroyModal('note');
}
}
