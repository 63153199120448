import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { BaseService } from 'src/app/base-service';
import { LocationService } from 'src/app/modules/shared/services/location.service';

@Component({
  selector: 'app-office-address-form',
  templateUrl: './office-address-form.component.html',
  styleUrls: ['./office-address-form.component.scss']
})
export class OfficeAddressFormComponent {
  testsLocation = 'localhost:9876';
  @ViewChild('placePickRef') placePickRef: GooglePlaceDirective | null = null;
  @ViewChild('placeDelRef') placeDelRef: GooglePlaceDirective | null = null;
  myOptions = new Options({
    componentRestrictions: {
      country: 'Dk',
    },
  });
  form?: UntypedFormGroup;
  formattedaddress = ' ';
  pickUpAddress: string | null = null;
  deliveryAddress: string | null = null;
  tripId: string | null = null;
  constructor(private baseService: BaseService, 
    private locationService: LocationService, 
    private formBuilder: UntypedFormBuilder) {
  
  }
  
  ngOnInit(): void {
    this.form = this.formBuilder.group({ 
      pickUpAddress: [this.pickUpAddress],
      deliveryAddress: [this.deliveryAddress],
    });
  }
  redirectToAddressOnMaps(destination: string) {
    if (!destination) {
      return;
    }
    const p = 'DK';
    var coordinates = this.locationService.getPosition();
    coordinates.then((x) => {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${x.latitude.valueOf()},${x.longitude.valueOf()}` +
          '&destination=' +
          destination +
          '&travelmode=driving',
        '_blank'
      );
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  handleAddressChange($event: any, control: string) {
    this.formattedaddress = $event.formatted_address;
    this.updateValue(control, this.formattedaddress);
  }
  locationCheck() {
    if (window.location.host !== this.testsLocation) {
      return true;
    }
    return false;
  }

  save() {
    this.baseService.saveOfficeAddressEvent({id:this.tripId, pickUpAddress: this.form?.value.pickUpAddress, deliveryAddress: this.form?.value.deliveryAddress})
  }
  
  cancel() {
    this.baseService.sendCancelOfficeAddressEvent(true);
    this.baseService.sendDestroyModal('address');
  }
}
