import { Injectable } from '@angular/core';
import {
  Store,
  createState,
  withProps,
  select,
  createStore,
} from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  upsertEntities,
  selectEntity,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { combineLatest, map } from 'rxjs';
import {
  sortBy,
  SortOption,
  SortState,
} from 'src/app/modules/shared/pipes/sort.pipe';
import { UserRoles } from './auth.repository';
import { BaseRepository, SortProps } from './abstract/base.repository';
import { withPagination } from '@ngneat/elf-pagination';

export interface User {
  id: string;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  title: string;
  location: string;
  birthday?: Date;
  tenantId?: string;
  createdAt: Date;
  image: File | null;
  imagePath: string;
  roles: UserRoles[];
  vehicleId: string;
  vehicleName: string;
  trailerId: string;
  trailerName: string;
  deletedAt?: string;
}

export const userSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  { label: $localize`:Sort label Surname:Surname`, property: 'surname' },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
];
// function buildBasicStore<T extends { id: string }>(
//   name: string,
//   defaultSort: SortState
// ) {
//   return createStore(
//     { name },
//     withEntities<T>(),
//     withProps<SortProps>({
//       sortBy: defaultSort,
//     }),
//     withRequestsCache(),
//     withRequestsStatus(),
//     withPagination()
//   );
// }

@Injectable({ providedIn: 'root' })
export class UsersRepository extends BaseRepository<User> {
  constructor() {
    super('users', userSortOptions);
  }

  // protected storeDrivers = buildBasicStore<User>(
  //   this.name,
  //   this.defaultSort || { parameter: this.sortOptions[0], direction: 'asc' }
  // );

  // allDeleted$ = this.sto.pipe(selectAllEntities());
}
