import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  PipeTransform,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  PhotoEventDto,
  PhotoModalEventDto,
  SearchResult,
  SearchState,
  TenantNoteEvent,
  Trip,
  TripDto,
  TripEvent,
  TripEventDto,
  TripEventFile,
  TripLoadDto,
  TripPhoto,
  TripsRepository,
  TripUnloadDto,
} from '../../../../state/trips.repository';
import {
  SortColumn,
  SortDirection,
  SortEvent,
  NgbdSortableHeader,
} from '../sortable.directive';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  map,
  tap,
  debounceTime,
  switchMap,
  delay,
  merge,
  filter,
} from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { User } from '../../../../state/users.repository';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import { Company } from 'src/app/state/companies.repository';
import { Product } from '../../../../state/products.repository';
import * as dayjs from 'dayjs';
import { UiRepository } from '../../../../state/ui.repository';
import { TripsService } from '../../../../state/trips.service';
import { combineLatest } from 'rxjs';
import { AuthRepository } from 'src/app/state/auth.repository';
import { FilterOptions } from 'src/app/state/workdays.repository';
import { Column, SimpleColumn } from 'src/app/state/columns.repository';
import { Router } from '@angular/router';
import { TripEventsRepository } from 'src/app/state/tripevents.repository';
import { TripEventsService } from 'src/app/state/tripevents.service';
import { OrdersService } from 'src/app/state/orders.service';

function dateFormater(dt: Date): string {
  const year = dt.getFullYear().toString().padStart(4, '0');
  const month = (dt.getMonth() + 1).toString().padStart(2, '0');
  const date = dt.getDate().toString().padStart(2, '0');
  const hours = dt.getHours().toString().padStart(2, '0');
  const minutes = dt.getMinutes().toString().padStart(2, '0');
  const seconds = dt.getSeconds().toString().padStart(2, '0');
  return `${date}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

const compare = (v1: string | number | any, v2: string | number | any) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function convertDurationToSeconds(durationString: string | undefined) {
  var hours = 0;
  var minutes = 0;
  var seconds = 0;

  if (durationString) {
    var parts = durationString.split('. ');
    for (var i = 0; i < parts.length; i++) {
      var part = parts[i];
      if (part.indexOf('h') !== -1) {
        hours = parseInt(part);
      } else if (part.indexOf('m') !== -1) {
        minutes = parseInt(part);
      } else if (part.indexOf('s') !== -1) {
        seconds = parseInt(part);
      }
    }

    var totalSeconds = hours * 60 * 60 + minutes * 60 + seconds;
    return totalSeconds;
  }
  return undefined;
}

function convertDateStringSeconds(dateString: string | undefined) {
  if (dateString) {
    const dateComponents = dateString.split(/[ :\-]/);
    const year = parseInt(dateComponents[2], 10);
    const month = parseInt(dateComponents[1], 10) - 1;
    const day = parseInt(dateComponents[0], 10);
    const hour = parseInt(dateComponents[3], 10);
    const minute = parseInt(dateComponents[4], 10);
    const second = parseInt(dateComponents[5], 10);
    const date = new Date(year, month, day, hour, minute, second);
    const seconds = isNaN(date.getTime()) ? NaN : date.getTime() / 1000;
    return seconds;
  }
  return undefined;
}

function sort(
  dtos: TripDto[],
  column: SortColumn,
  direction: string,
  unloadPosition: string
): TripDto[] {
  let coulumn1: string = '';
  let coulumn2: string = '';
  switch (column) {
    case 'ClientName':
      coulumn1 = 'clientNameStart';
      coulumn2 = 'clientNameEnd';
      break;
    case 'ClientID':
      coulumn1 = 'clientIdNumberStart';
      coulumn2 = 'clientIdNumberEnd';
      break;
    case 'Weight':
      coulumn1 = 'weightStart';
      coulumn2 = 'weightEnd';
      break;
    case 'TripStartKm':
      coulumn1 = 'kmStart';
      coulumn2 = 'kmStart';
      break;
    case 'TripTotalKm':
      coulumn1 = 'tripKm';
      coulumn2 = 'tripKm';
      break;
    case 'ProductName':
      coulumn1 = 'productNameStart';
      coulumn2 = 'productNameStartEnd';
      break;
    case 'TripEndKm':
      coulumn1 = 'kmEnd';
      coulumn2 = 'kmEnd';
      break;
    case 'Cleaning':
      coulumn1 = 'cleaningStart';
      coulumn2 = 'cleaningStartEnd';
      break;
    case 'TenantNote':
      coulumn1 = 'tenantNote';
      coulumn2 = 'tenantNote';
      break;
    case 'TripStarttime':
      coulumn1 = 'startEventTime';
      coulumn2 = 'startEventTime';
      break;
    case 'TripEndTime':
      coulumn1 = 'endEventTime';
      coulumn2 = 'endEventTime';
      break;
    case 'Totaltime':
      coulumn1 = 'tripTime';
      coulumn2 = 'tripTime';
      break;
    case 'Note':
      coulumn1 = 'noteStart';
      coulumn2 = 'noteStartEnd';
      break;
    case 'Reference':
      coulumn1 = 'referenceStart';
      coulumn2 = 'referenceStartEnd';
      break;
    case 'Orderinfo':
      coulumn1 = 'commentStart';
      coulumn2 = 'commentStartEnd';
      break;
    case 'VendorName':
      coulumn1 = 'vendorNameStart';
      coulumn2 = 'vendorNameStartEnd';
      break;
    case 'Accessory':
      coulumn1 = 'accessoryNameStart';
      coulumn2 = 'accessoryNameStartEnd';
      break;

    default:
      coulumn1 = column;
      coulumn2 = column;
      break;
  }

  if (direction === '') {
    return dtos;
  } else {
    return [...dtos].sort((a: any, b: any) => {
      if (column === 'Totaltime') {
        let afirst = convertDurationToSeconds(a[coulumn1]);
        let asecond = convertDurationToSeconds(a[coulumn2]);
        let bfirst = convertDurationToSeconds(b[coulumn1]);
        let bsecond = convertDurationToSeconds(b[coulumn2]);
        const res = compare(
          (afirst === undefined ? 0 : afirst) +
            (asecond === undefined ? 0 : asecond),
          (bfirst === undefined ? 0 : bfirst) +
            (bsecond === undefined ? 0 : bsecond)
        );
        return direction === 'asc' ? res : -res;
      }
      if (column === 'TripStarttime' || column === 'TripEndTime') {
        let afirst = convertDateStringSeconds(a[coulumn1]);
        let asecond = convertDateStringSeconds(a[coulumn2]);
        let bfirst = convertDateStringSeconds(b[coulumn1]);
        let bsecond = convertDateStringSeconds(b[coulumn2]);
        const res = compare(
          (afirst === undefined ? 0 : afirst) +
            (asecond === undefined ? 0 : asecond),
          (bfirst === undefined ? 0 : bfirst) +
            (bsecond === undefined ? 0 : bsecond)
        );
        return direction === 'asc' ? res : -res;
      }

      const res = compare(
        (a[coulumn1] === undefined ? '' : a[coulumn1]) +
          (a[coulumn2] === undefined ? '' : a[coulumn2]),
        (b[coulumn1] === undefined ? '' : b[coulumn1]) +
          (b[coulumn2] === undefined ? '' : b[coulumn2])
      );

      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(dto: TripDto, term: string, pipe: PipeTransform) {
  if (dto.clientNameStart || dto.clientNameEnd) {
    return (
      dto.clientNameStart?.toLowerCase().includes(term.toLowerCase()) ||
      dto.clientNameEnd?.toLowerCase().includes(term.toLowerCase()) ||
      dto.driverName?.toLowerCase().includes(term.toLowerCase()) ||
      dto.carNumber?.toLowerCase().includes(term.toLowerCase()) ||
      dto.kmStart?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.kmEnd?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.weightStart?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.weightEnd?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.referenceEnd?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.referenceStart
        ?.toString()
        .toLowerCase()
        .includes(term.toLowerCase()) ||
      dto.vendorNameStart
        ?.toString()
        .toLowerCase()
        .includes(term.toLowerCase()) ||
      dto.vendorNameEnd
        ?.toString()
        .toLowerCase()
        .includes(term.toLowerCase()) ||
      dto.commentEnd?.toString().toLowerCase().includes(term.toLowerCase()) ||
      dto.commentStart?.toString().toLowerCase().includes(term.toLowerCase())
    );
  }
  return null;
}

@Component({
  selector: 'app-sort-table',
  templateUrl: './sort-table.component.html',
  styleUrls: ['./sort-table.component.scss'],
})
export class SortTableComponent implements OnInit, AfterContentChecked {
  initialEvent$?: Observable<TripEvent | undefined>;
  @Output() takePhotos = new EventEmitter<TripEventFile>();
  @Input() isUserTripsPage: boolean = false;
  @Input() set parkedOverviewSet(value: boolean) {
    this.isParkedOverview = value;
    this.simpleColumns?.forEach((x) => {
      if (x.name == 'TripInfo' || x.name == 'Time') {
        if (!value) {
          x.isVisible = false;
        }
      }
    });
  }
  isParkedOverview: boolean = false;

  @Input() set columns(value: Column[] | null) {
    if (!value) {
      this.columns = null;
    } else {
      this.standartVariableNames = value
        .filter((x) => x.isStandartColumn)
        .map((x) => x.name.replace(/\s/g, '').split(',')[0]);

      let dataLink = new SimpleColumn();
      dataLink.name = 'modal';
      dataLink.danskName = 'Vis alle data';
      dataLink.isVisible = true;
      this.simpleColumns = [dataLink];
      this.standartVariableNames = [dataLink.danskName].concat(
        this.standartVariableNames
      );
      value
        .filter((x) => x.isStandartColumn)
        .map((x) => {
          this.simpleColumns?.push({
            name: x.name.replace(/\s/g, '').split(',')[0],
            danskName: x.danskName.split(',')[0],
            isVisible:
              this.isUserTripsPage &&
              (x.name == 'Trip Info' || x.name == 'Time')
                ? false
                : x.isVisible,
          });
        });

      let approved = this.simpleColumns.find((x) => x.name === 'Approved');
      if (!this.isAdminPage && approved) {
        this.standartVariableNames = this.standartVariableNames.filter(
          (x) => x !== 'Approved' && x !== 'TenantNote'
        );
        let index = this.simpleColumns.indexOf(approved);
        if (index !== -1) {
          this.simpleColumns.splice(index, 1);
        }
      }
      let tenantNote = this.simpleColumns.find((x) => x.name === 'TenantNote');
      if (!this.isAdminPage && tenantNote) {
        let index = this.simpleColumns.indexOf(tenantNote);
        if (index !== -1) {
          this.simpleColumns.splice(index, 1);
        }
      }
      this.simpleLoadUnloadColumns = [];
      value
        .filter((x) => x.isUnloadColumn)
        .map((x) =>
          this.simpleLoadUnloadColumns?.push({
            name:
              x.name.replace(/\s/g, '').split(',')?.length > 1
                ? x.name.replace(/\s/g, '').split(',')[1]
                : x.name.replace(/\s/g, '').split(',')[0],
            danskName:
              x.danskName.replace(/\s/g, '').split(',')?.length > 1
                ? x.danskName
                    .split(',')[1]
                    .replace('losning', '')
                    .replace('Losning', '')
                    .replace('aflosset', '')
                    .replace('fotos', 'Fotos')
                : x.danskName.split(',')[0],
            isVisible: x.isVisible,
          })
        );
    }
  }

  simpleColumns: SimpleColumn[] | null = null;
  simpleLoadUnloadColumns: SimpleColumn[] | null = null;
  standartVariableNames!: string[] | [''];

  @Input() timeLogs: Observable<Trip[]> | undefined;
  @Input() driverId: string | null = null;
  urls = new Array<string>();
  clickedRows = new Array<number>();
  @Input() isAdminPage: boolean = true;
  @Input() set drivers(value: User[] | null) {
    if (!value) {
      this.driverOptions = null;
    } else {
      this.allDrivers = value;
      this.driverOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));

      this.driverStaticOptions = this.driverOptions;
    }
  }
  allDrivers: User[] | null = null;

  @Input() driversOptions: User | null = null;

  @Input() set clients(value: Company[] | null | any) {
    if (!value) {
      this.clientOptions = null;
    } else {
      this.clientOptions = value.map((x: any) => ({
        value: x.id,
        label: x.name,
      }));
      this.clientOptionsStatic = this.clientOptions;
    }
  }

  @Input() set products(value: Product[] | null) {
    if (!value) {
      this.productsOptions = null;
    } else {
      this.productsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
      this.productsStaticOptions = this.productsOptions;
      this._search();
    }
  }

  @Input() dateType?: string;
  @Output() tripApprove = new EventEmitter<TripDto>();

  driverOptions: DatalistOption[] | null = null;
  clientOptions: DatalistOption[] | null = null;
  productsOptions: DatalistOption[] | null = null;

  driverStaticOptions: DatalistOption[] | null = null;
  clientOptionsStatic: DatalistOption[] | null = null;
  productsStaticOptions: DatalistOption[] | null = null;

  selectedId = '';
  driverSelected?: string;
  clientSelected?: string;
  productSelected?: string[];

  tripDtos: TripDto[] | null = null;
  numbersForUnloadLoop: number[] | null = null;
  numbersForLoadLoop: number[] | null = null;
  dateFrom?: string;
  dateTo?: string;
  isApprovedSearch?: boolean;
  showAllData?: boolean;

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _countries$ = new BehaviorSubject<TripDto[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  OutPutVales: TripDto[] | null = null;
  weighEvents = new Array<TripDto>();
  usualEvents = new Array<TripDto>();
  tripUnloads = new Array<TripUnloadDto>();
  tripLoads = new Array<TripLoadDto>();
  tripEvents = new Array<TripEventDto>();
  titleTenantNote: string | null = null;
  textTenantNote: string[] | null = null;
  eventIdToUpdateTenantNote: string | null = null;
  @Output() tenantNoteEmmiter = new EventEmitter<TenantNoteEvent>();
  options?: FilterOptions;
  exportFactory = () => this.service.featuredExport(this.options);
  exportPhotoFactory = () => this.service.featuredPhotosExport(this.options);
  @Output() weighPhotoEmmiter = new EventEmitter<PhotoEventDto>();
  @Output() usualPhotoEmmiter = new EventEmitter<PhotoEventDto>();
  @Output() modalPhotoEmmiter = new EventEmitter<PhotoModalEventDto>();
  pages: number[] = [1];

  private _state: SearchState = {
    page: 1,
    pageSize: 20,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    unloadPosition: '',
  };

  changeShow() {
    localStorage.setItem('showAllData', JSON.stringify(this.showAllData));
  }

  @ViewChildren(NgbdSortableHeader) headers:
    | QueryList<NgbdSortableHeader>
    | undefined;

  constructor(
    private pipe: DecimalPipe,
    public ngDay: DayjsService,
    public ui: UiRepository,
    public service: TripsService,
    public repos: TripsRepository,
    private tripEventService: TripEventsService,
    public authRepo: AuthRepository,
    public router: Router,
    private ordersService: OrdersService
  ) {
    // this._state.searchTerm = localStorage.getItem('searchTerm')?.toString() || "";
    this._state.page = Number(localStorage.getItem('page')) || 1;
    this._state.sortColumn =
      localStorage.getItem('sortColumn')?.toString() || '';
    this._state.sortDirection =
      (localStorage.getItem('sortDirection')?.toString() as SortDirection) ||
      '';
    this.dateFrom = localStorage.getItem('dateFrom')?.toString() || '';
    this.dateTo = localStorage.getItem('dateTo')?.toString() || '';
    this.clientSelected =
      localStorage.getItem('clientSelected')?.toString() || '';
    this.driverSelected =
      localStorage.getItem('driverSelected')?.toString() || '';
    this.isApprovedSearch = JSON.parse(
      localStorage.getItem('isApprovedSearch')?.toString() || 'false'
    );
    if (localStorage.getItem('productSelected') != undefined) {
      this.productSelected = JSON.parse(
        localStorage.getItem('productSelected') || ''
      );
    }
    this.showAllData = JSON.parse(
      localStorage.getItem('showAllData')?.toString() || 'false'
    );

    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(300),
        switchMap(() => this._search()),
        delay(1000),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result: any) => {
        this._countries$.next(result.countries);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get countries$() {
    return this._countries$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<SearchState>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  onSort({ column, direction, unloadPosition }: SortEvent) {
    this._state.unloadPosition = unloadPosition;
    this._state.sortColumn = column;
    this._state.sortDirection = direction;
    // resetting other headers
    if (this.headers) {
      this.headers.forEach((header) => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });
    }
    this.changeState();
  }

  selectedTrip: TripDto | null = null;

  openDriversModal(trip: TripDto) {
    this.selectedTrip = trip;
  }

  updateParkedTrip(driverId: string) {
    var driver = this.allDrivers?.find((x) => x.id == driverId);
    var tripId = this.selectedTrip?.tripId;
    if (tripId) {
      this.OutPutVales?.forEach((x) => {
        if (x.tripId == tripId) {
          x.driverName = driver?.name + ' ' + driver?.surname;
          x.driverId = driverId;
        }
      });
      this.updateParkedDriver(tripId, driverId);
    }
    this.selectedTrip = null;
  }

  updateParkedDriver(orderId: string, driverId: string) {
    this.ordersService
      .updateParkedDriverWidthChilds(orderId, driverId)
      .subscribe();
  }

  changePage(page: number) {
    this.page = page;
    this._search();
  }

  changeApprove(event: TripDto) {
    if (event.isApproved === true) {
      event.isApproved = false;
    } else {
      event.isApproved = true;
    }
    this.onUpdateApprove(event);
  }

  navigateTo(str: string) {
    this.tripEventService.loadOneWithoutCache(str).subscribe((x) => {
      if (x) {
        this.router.navigate(['/trips/edit/' + x?.id + '/' + this.isAdminPage]);
      }
    });
  }

  addImage($event: any) {
    let pushFiles = new Array<File>();
    let files = $event.target.files;

    if (pushFiles && this.selectedId) {
      this.takePhotos.emit({ files, id: this.selectedId });
    }
  }

  onUpdateApprove(event: Partial<TripDto>) {
    var el = this.tripDtos?.filter((x) => x.tripId === event.tripId)[0];
    if (el) {
      el.isApproved = event.isApproved;
      let objIndex = this.tripDtos?.findIndex(
        (obj) => obj.tripId === el?.tripId
      );
      if (objIndex !== undefined && this.tripDtos !== null) {
        if (
          this.tripDtos[objIndex] !== null &&
          this.tripDtos[objIndex] !== undefined
        ) {
          this.tripDtos[objIndex].isApproved = el.isApproved;
        }
      }
    }
    this.service.updateApprove(event).subscribe();
    this._search();
  }

  changeState() {
    //trigger timeLogs
    this._search$.next();
  }

  ngOnInit(): void {
    if (this.timeLogs) {
      if (this.isAdminPage) {
        this.timeLogs.subscribe((x) => {
          if (x) {
            if (this.isParkedOverview) {
              this.InitializeTripDtos(
                x.filter(
                  (f) =>
                    f.parkedAt &&
                    (!f.resumedAt ||
                      new Date(f.resumedAt) < new Date(f.parkedAt)) &&
                    !f.tripEvents?.filter((x) => x.type === 'TripEnd')[0]
                )
              );
            } else {
              this.InitializeTripDtos(x);
            }
            this._search();
          }
        });
      } else {
        this.timeLogs.subscribe((x) => {
          if (x) {
            x = x?.filter((y) => y.userId === this.driverId);
            if (this.isParkedOverview) {
              this.InitializeTripDtos(
                x.filter(
                  (f) =>
                    f.parkedAt &&
                    (!f.resumedAt ||
                      new Date(f.resumedAt) < new Date(f.parkedAt)) &&
                    !f.tripEvents?.filter((x) => x.type === 'TripEnd')[0]
                )
              );
            } else {
              this.InitializeTripDtos(x);
            }
            this._search();
          }
        });
      }
    }
    this._search();

    if (this.dateType) {
      if (this.dateType) {
        this.setDatePicker(this.dateType);
      } else {
        this.setDatePicker('month');
      }
    } else if (!this.dateTo && !this.dateFrom) {
      this.setDatePicker('month');
    }
  }

  ngAfterContentChecked() {
    this.OutPutVales?.forEach((x, y) => {
      let row = document.getElementById(`ordering${y}`);
      this.simpleColumns?.forEach((a, b) => {
        let sortedColumnName = `${a.name}${y}`;
        let unsortedColumnName = row?.children.item(b)?.id;
        if (row) {
          let sortedColumn = row.querySelector(`#${sortedColumnName}`);
          let unsortedColumn = row.querySelector(`#${unsortedColumnName}`);
          if (sortedColumn) {
            row.insertBefore(sortedColumn, unsortedColumn);
          }
        }
        if (!a.isVisible && !this.isAdminPage) {
          let toRemove = `${a.name}${y}`;
          let toRemoveColumn = row?.querySelector(`#${toRemove}`);
          if (row && toRemoveColumn) {
            row.removeChild(toRemoveColumn);
          }
        }
        if (!a.isVisible && this.isAdminPage) {
          let toRemove = `${a.name}${y}`;
          let toRemoveColumn = row?.querySelector(`#${toRemove}`);
          if (row && toRemoveColumn) {
            row.removeChild(toRemoveColumn);
          }
        }
      });
    });
  }

  setDatePicker(dateType: string) {
    if (dateType === 'day') {
      const dayObj = this.ngDay.dayjs.utc().local();
      this.dateFrom = dayObj.format('YYYY-MM-DD');
      this.dateTo = dayObj.format('YYYY-MM-DD');
    }
    if (dateType === 'week') {
      const weekStart = this.ngDay.dayjs().startOf('week');
      const weekEnd = this.ngDay.dayjs().endOf('week');
      this.dateFrom = weekStart.format('YYYY-MM-DD');
      this.dateTo = weekEnd.format('YYYY-MM-DD');
    }
    if (dateType === 'month') {
      let monthStart = this.ngDay.dayjs().startOf('month');
      let monthEnd = this.ngDay.dayjs().endOf('month');
      if (this.isAdminPage) {
        monthStart = monthStart.subtract(1, 'month');
        monthEnd = monthStart.endOf('month');
      }
      this.dateFrom = monthStart.format('YYYY-MM-DD');
      this.dateTo = monthEnd.format('YYYY-MM-DD');
    }
  }

  countriesStatic: any;

  checkIfLoadUnloadInEvents(events: TripEventDto[]): boolean {
    let loadsUnloads = events.filter(
      (x) => x.type === 'Load' || x.type === 'Unload'
    );
    if (loadsUnloads && loadsUnloads.length > 0) {
      return true;
    }
    return false;
  }

  private _search(): Observable<SearchResult> | any {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
      unloadPosition,
    } = this._state;

    // 1. sort

    if (this.tripDtos) {
      let countries = sort(
        this.tripDtos,
        sortColumn,
        sortDirection,
        unloadPosition
      );

      // 2. filter
      let dateToCustom = new Date(this.dateTo!);
      dateToCustom.setDate(dateToCustom.getDate() + 1);
      countries = countries
        ?.filter((country) => matches(country, searchTerm, this.pipe))
        .filter(
          (x) =>
            x.startEventTimeForFilter! <= dateToCustom &&
            x.startEventTimeForFilter! >= new Date(this.dateFrom!)
        );

      this.countriesStatic = countries;

      if (this.clientSelected) {
        countries = countries?.filter(
          (x) =>
            this.clientSelected?.includes(x.clientIdStart!) ||
            this.clientSelected?.includes(x.clientIdEnd!)
        );
      }
      if (this.driverSelected && !this.isUserTripsPage) {
        countries = countries?.filter((x) =>
          this.driverSelected?.includes(x.driverId!)
        );
      }

      if (this.productSelected?.length! > 0) {
        countries = countries?.filter(
          (x) =>
            this.productSelected?.some((r) =>
              x.productIdStart?.split(',')?.includes(r)
            ) ||
            this.productSelected?.some((r) =>
              x.productIdEnd?.split(',')?.includes(r)
            )
        );
      }

      if (this.isApprovedSearch) {
        countries = countries?.filter((x) => x.isApproved === false);
      }

      const total = countries.length;

      let opt: FilterOptions = new FilterOptions();
      opt.resultIds = countries.map((x) => x.tripId || '');

      /// 3. paginate
      countries = countries.slice(
        (page - 1) * pageSize,
        (page - 1) * pageSize + pageSize
      );
      this.OutPutVales = countries;

      var cl1: any[] = [];
      var cl2: any[] = [];

      var b1: any[] = [];

      var d1: any[] = [];
      this.countriesStatic.forEach((x: any) => {
        cl1.push(x.clientIdStart);
        cl1.push(x.clientIdEnd);
        cl2 = cl2.concat(x.tripEvents?.map((f: any) => f.clientId));

        b1.push(x.driverId);

        d1.push(x.productIdStart);
        d1.push(x.productIdEnd);
      });
      cl2 = cl1.concat(cl2);
      cl2 = cl2.filter((x, i, a) => a.indexOf(x) == i);

      this.clientOptions = this.clientOptionsStatic!.filter((x: any) =>
        cl2.includes(x.value)
      );

      b1 = b1.filter((x, i, a) => a.indexOf(x) == i);
      this.driverOptions =
        this.driverStaticOptions?.filter((x: any) => b1.includes(x.value)) ??
        null;

      d1 = d1.filter((x, i, a) => a.indexOf(x) == i);

      this.productsOptions =
        this.productsStaticOptions?.filter((x: any) => d1.includes(x.value)) ??
        null;

      // this.tripDtos = countries;
      let numberOfPages = Math.floor(total / this.pageSize);
      if (total % this.pageSize !== 0) {
        numberOfPages = numberOfPages + 1;
      }
      this.pages = Array.from(
        { length: numberOfPages ? numberOfPages : 1 },
        (_, i) => i + 1
      );

      if (!this.pages.includes(this.page)) {
        this.page = this.pages.length;
      }

      if (this.clientSelected) {
        opt.clientSelected = this.clientSelected;
      }
      if (this.driverSelected) {
        opt.driverSelected = this.driverSelected;
      }
      if (this.productSelected) {
        opt.productSelected = this.productSelected;
      }
      opt.isApproved = this.isApprovedSearch;
      opt.searchWord = this.searchTerm;
      opt.dateFrom = this.dateFrom;
      opt.dateTo = this.dateTo;
      opt.order = sortDirection;
      opt.orderColumn = sortColumn;

      this.options = opt;

      this.service.featuredExport(this.options);

      //localStorage.setItem('searchTerm', this.searchTerm);
      localStorage.setItem('page', this.page.toString());
      localStorage.setItem('sortColumn', this._state.sortColumn);
      localStorage.setItem('sortDirection', this._state.sortDirection);
      localStorage.setItem('dateFrom', this.dateFrom || '');
      localStorage.setItem('dateTo', this.dateTo || '');
      localStorage.setItem('clientSelected', this.clientSelected || '');
      localStorage.setItem('driverSelected', this.driverSelected || '');
      localStorage.setItem('searchTerm', this.searchTerm);
      localStorage.setItem(
        'productSelected',
        JSON.stringify(this.productSelected || '')
      );
      localStorage.setItem(
        'isApprovedSearch',
        JSON.stringify(this.isApprovedSearch)
      );
      return of({ countries, total });
    }
  }

  clientIds: any;
  driverIds: any;
  productIds: any;

  getWeightPhotos($event: PhotoEventDto) {
    if ($event.url && this.urls) {
      const index = this.urls.indexOf($event.url);
      if (index > -1) {
        this.urls.splice(index, 1);
      }
      this.weighPhotoEmmiter.emit($event);
    }
  }

  getUsualPhotos($event: PhotoEventDto) {
    if ($event.url && this.urls) {
      const index = this.urls.indexOf($event.url);
      if (index > -1) {
        this.urls.splice(index, 1);
      }
      this.usualPhotoEmmiter.emit($event);
    }
  }

  getModalPhotos($event: PhotoModalEventDto) {
    if ($event.url && this.urls) {
      this.modalPhotoEmmiter.emit($event);
    }
  }

  getUrls(files?: TripPhoto[], type?: string, event?: TripDto) {
    this.urls = [];
    this.weighEvents = [];
    this.usualEvents = [];
    if (files) {
      this.urls = files?.map((x) => x.url);
    }
    if (event && type !== 'weigh') {
      this.usualEvents.push(event);
    }
    if (event && type === 'weigh') {
      this.weighEvents.push(event);
    }
  }

  compareStrings(start?: string | number, end?: string | number): string {
    if (start && end && start === end) {
      return start.toString();
    }
    if (start && end && start !== end) {
      return `${start + '/'} \n ${end}`;
    }
    if (start && !end) {
      return `${start}/`;
    }
    if (!start && end) {
      return `/${end}`;
    }
    return '';
  }

  openTenantComment(OutPutValues: TripDto | null) {
    this.eventIdToUpdateTenantNote = '';
    if (OutPutValues) {
      this.textTenantNote = OutPutValues.tenantNote
        ? [OutPutValues.tenantNote]
        : [''];
      this.titleTenantNote = 'Vognmand Bemærkning';
      this.eventIdToUpdateTenantNote = OutPutValues.tripEventIdStart!;
    }
  }

  cancel() {
    this.textTenantNote = null;
    this.titleTenantNote = null;
  }

  addTenantNote($event: string) {
    if (this.eventIdToUpdateTenantNote) {
      let toUpdate = new TenantNoteEvent();
      toUpdate.tripEventId = this.eventIdToUpdateTenantNote;
      toUpdate.tenantNote = $event;
      this.tenantNoteEmmiter.emit(toUpdate);
      this.cancel();
    }
  }

  getDataToSortModal(OutPutValues: TripDto | null, index: number) {
    this.tripUnloads = [];
    this.tripLoads = [];
    this.tripEvents = [];
    this.clickedRows = [];
    if (OutPutValues) {
      let outputEvents = OutPutValues.tripEvents;
      outputEvents?.forEach((b) => {
        if (b.type !== 'Weighing' && b.type !== 'Total') {
          this.tripEvents?.push(b);
        }
      });
      this.tripEvents.map((x) => {
        x.isApproved = OutPutValues.isApproved;
        x.carNumber = OutPutValues.carNumber;
        x.tripTime = OutPutValues.tripTime;
        x.tripKm = OutPutValues.tripKm;
        x.driverName = OutPutValues.driverName;
        x.trailerNumber = OutPutValues.trailerNumber;
      });
    }
    this.clickedRows.push(index);
  }

  InitializeTripDtos(trips: Trip[] | null) {
    if (trips) {
      let tripDtos: TripDto[] = [];
      trips?.forEach(function (trip) {
        if (trip) {
          var dto = new TripDto();
          dto.driverId = trip.userId;
          dto.driverName = trip.userName;
          dto.tripId = trip.id;
          dto.isApproved = trip.isApproved;
          dto.isOrder = trip.isOrder;
          dto.carNumber = trip.vehicle?.numberplate;
          dto.trailerNumber = trip.trailer?.numberplate
            ? trip.trailer?.numberplate
            : '';
          let start;
          let end;
          let tripEventStart = trip.tripEvents?.filter(
            (x) => x.type === 'TripStart'
          )[0];
          if (tripEventStart) {
            dto.tripEventIdStart = tripEventStart.id;
            dto.clientNameStart = tripEventStart.client?.name;
            dto.clientIdStart = tripEventStart.client?.id;
            dto.clientIdNumberStart = tripEventStart.client?.idNumber;
            dto.startEventTimeForFilter = tripEventStart.eventTime;
            start = tripEventStart.eventTime;
            dto.startEventTime = dateFormater(tripEventStart.eventTime);
            dto.kmStart = tripEventStart.km;
            dto.startPosition = `https://maps.google.com/?q=${tripEventStart.latitude},${tripEventStart.longitude}`;
            dto.startEventPhotos = tripEventStart.tripPhotos;
            dto.startPhotoUrls = getUrls(tripEventStart.tripPhotos);
            dto.weightStart = tripEventStart.weight;
            dto.tenantNote = tripEventStart.tenantNote;
            if (tripEventStart.products) {
              if (tripEventStart.products[0]) {
                dto.productNameStart = tripEventStart.products
                  .map((x) => x.name)
                  .toString();
                dto.productIdStart = tripEventStart.products
                  .map((x) => x.id)
                  .toString();
              }
            }
            dto.cleaningStart = tripEventStart.cleaning?.name;
            dto.noteStart = tripEventStart.note;
            dto.referenceStart = tripEventStart.reference;
            dto.commentStart = tripEventStart.comment;
            dto.vendorNameStart = tripEventStart.vendor?.name;
            dto.vendorIdNumberStart = tripEventStart.vendor?.idNumber;
            dto.accessoryNameStart = tripEventStart.accessory?.name;
            dto.parkDuration = calculateDurationForParked(trip);
          }

          let tripEventEnd = trip.tripEvents?.filter(
            (x) => x.type === 'TripEnd'
          )[0];

          if (tripEventEnd) {
            dto.tripEventIdEnd = tripEventEnd.id;
            end = tripEventEnd.eventTime;
            dto.clientNameEnd = tripEventEnd.client?.name;
            dto.clientIdEnd = tripEventEnd.client?.id;
            dto.clientIdNumberEnd = tripEventEnd.client?.idNumber;
            dto.endEventTime = dateFormater(tripEventEnd.eventTime);
            dto.kmEnd = tripEventEnd.km;
            dto.endPosition = `https://maps.google.com/?q=${tripEventEnd.latitude},${tripEventEnd.longitude}`;
            dto.endEventPhotos = tripEventEnd.tripPhotos;
            dto.endPhotoUrls = getUrls(tripEventEnd.tripPhotos);
            dto.noteEnd = tripEventEnd.note;
            dto.referenceEnd = tripEventEnd.reference;
            dto.commentEnd = tripEventEnd.comment;
            dto.cleaningEnd = tripEventEnd.cleaning?.name;
            dto.weightEnd = tripEventEnd.weight;
            dto.vendorIdNumberEnd = tripEventEnd.vendor?.idNumber;
            dto.vendorNameEnd = tripEventEnd.vendor?.name;
            dto.accessoryNameEnd = tripEventEnd.accessory?.name;

            if (tripEventEnd.products) {
              if (tripEventEnd.products[0]) {
                dto.productNameEnd = tripEventEnd.products
                  .map((x) => x.name)
                  .toString();
                dto.productIdEnd = tripEventEnd.products
                  .map((x) => x.id)
                  .toString();
              }
            }
          }
          if (dto.endEventTime && dto.startEventTime) {
            let duration = dayjs
              .duration(dayjs(end).diff(dayjs(start)))
              .asMilliseconds();
            dto.tripTime = convertMS(
              duration - (trip?.parkDuration ? trip!.parkDuration : 0)
            );
          }

          dto.tripKm = (dto.kmEnd ?? 0) - (dto.kmStart ?? 0);

          let tripEventWeighing = trip.tripEvents?.filter(
            (x) => x.type === 'Weighing'
          );
          dto.weighingPhotos = new Array<TripPhoto>();
          if (tripEventWeighing) {
            tripEventWeighing.forEach((x) => {
              x.tripPhotos?.forEach((y) => {
                dto.weighingPhotos?.push(y);
              });
            });
          }
          let tripEventsDto: TripEventDto[] = [];
          trip.tripEvents
            ?.filter((x) => x.type !== 'Weighing')
            .forEach(function (event) {
              var eventDto = new TripEventDto();
              eventDto.tripEventId = event.id;
              eventDto.type = event.type;
              eventDto.eventTime = dateFormater(event.eventTime);
              eventDto.km = event.km;
              eventDto.note = event.note;
              eventDto.reference = event.reference;
              eventDto.comment = event.comment;
              eventDto.accessoriesName = event.accessory?.name;
              eventDto.vendorName = event.vendor?.name;
              eventDto.vendorIdNumber = event.vendor?.idNumber;
              eventDto.cleaning = event.cleaning?.name;

              eventDto.weight = event.weight;
              if (event.clientId) {
                eventDto.clientId = event.clientId;
              }
              if (event.client) {
                eventDto.clientName = event.client.name;
                eventDto.clientIdNumber = event.client?.idNumber;
              }

              if (event.products) {
                if (event.products[0]) {
                  eventDto.productName = event.products
                    .map((x) => x.name)
                    .toString();
                }
              }
              eventDto.position = `https://maps.google.com/?q=${event.latitude},${event.longitude}`;
              eventDto.photos = event.tripPhotos;
              eventDto.photoUrls = getUrls(event.tripPhotos);
              eventDto.duration = getDurationForLoadUnload(trip, event);
              eventDto.diffKm = getKmDiff(trip, event).toString();
              tripEventsDto.push(eventDto);
            });
          dto.tripEvents = tripEventsDto;
          tripDtos.push(dto);
        }
      });

      this.tripDtos = tripDtos;
    }
  }
}

function getDurationForLoadUnload(trip: Trip, event: TripEvent): string {
  let actualEvents = trip.tripEvents.filter((x) => x.type !== 'Weighing');
  let index = actualEvents.indexOf(event);
  let previous = actualEvents[index - 1];
  if (
    event.eventTime &&
    previous &&
    previous.eventTime &&
    previous.eventTime <= event.eventTime
  ) {
    let durationMs = dayjs
      .duration(dayjs(event.eventTime).diff(dayjs(previous.eventTime)))
      .asMilliseconds();
    return convertMS(durationMs);
  }
  return '';
}

function calculateDurationForParked(trip: Trip): string {
  if (trip.tripEvents && trip.tripEvents.length > 0 && trip.parkedAt) {
    let tripStartTime = trip.tripEvents.find(
      (x) => x.type === 'TripStart'
    )?.eventTime;
    let duration = dayjs
      .duration(dayjs(trip.parkedAt).diff(dayjs(tripStartTime)))
      .asMilliseconds();
    return convertMS(duration - (trip?.parkDuration ?? 0));
  }
  return '';
}

function getKmDiff(trip: Trip, event: TripEvent): string {
  let index = trip.tripEvents.indexOf(event);
  let actualEvents = trip.tripEvents.filter((x) => x.type !== 'Weighing');
  let previous = actualEvents[index - 1];
  if (event && previous && event.km && previous.km) {
    return (event.km - previous.km).toString();
  }
  return '';
}
function convertMS(duration: number): string {
  let d, h, m, s;
  s = Math.floor(duration / 1000);
  m = Math.floor(s / 60);
  s = s % 60;
  h = Math.floor(m / 60);
  m = m % 60;
  d = Math.floor(h / 24);
  h = h % 24;
  h += d * 24;
  return `${h}h. ${m}m. ${s}s. `;
}

function getUrls(files?: TripPhoto[]): string[] {
  let urls = new Array<string>();
  if (files) {
    urls = files?.map((x) => x.url);
  }
  return urls;
}
