import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  filter,
  map,
  Observable,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { Column, ColumnsRepository } from 'src/app/state/columns.repository';
import { ColumnsService } from 'src/app/state/columns.service';
import { TripEventsRepository } from 'src/app/state/tripevents.repository';
import { TripEventsService } from 'src/app/state/tripevents.service';
import { User, UsersRepository } from 'src/app/state/users.repository';
import { UsersService } from 'src/app/state/users.service';
import {
  CompaniesRepository,
  Company,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import {
  Product,
  ProductsRepository,
} from '../../../../state/products.repository';
import { ProductsService } from '../../../../state/products.service';
import {
  PhotoEventDto,
  PhotoModalEventDto,
  TenantNoteEvent,
  Trip,
  TripEventFile,
  TripsRepository,
} from '../../../../state/trips.repository';
import { TripsService } from '../../../../state/trips.service';

@Component({
  selector: 'app-trip-history-page',
  templateUrl: './trip-history-page.component.html',
  styleUrls: ['./trip-history-page.component.scss'],
})
export class TripHistoryPageComponent implements OnInit {
  public id: string | null = null;
  user$?: Observable<User>;
  trips$?: Observable<Trip>;
  dateType?: 'day' | ' week' | 'month';
  drivers: User[] | null = null;
  clients: Company[] | null | any = null;
  products: Product[] | null | any = null;
  trips: Trip[] | null | any = null;
  columns: Column[] | null = null;

  isColumnsLoaded: boolean = false;
  isProductsLoaded: boolean = false;
  isClientsLoaded: boolean = false;

  //exportFactory = () => this.id
  //  ? this.service.exportByUser(this.id)
  //  : this.service.export();

  constructor(
    public authRepo: AuthRepository,
    public repo: TripsRepository,
    public service: TripsService,
    public router: ActivatedRoute,
    public usersRepo: UsersRepository,
    public usersService: UsersService,
    public companyRepo: CompaniesRepository,
    private companyService: CompaniesService,
    public productsRep: ProductsRepository,
    public productsService: ProductsService,
    private urlId: UrlIdResolverService,
    public auth: AuthRepository,
    private columnsService: ColumnsService,
    public columnsRepo: ColumnsRepository,
    private tripEventService: TripEventsService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.service
      .loadOverview()
      .subscribe((x) => (this.trips = this.repo.trips$));
    this.dateType = this.router.snapshot.data.dateType;
    this.usersService.loadDrivers().subscribe((x) => (this.drivers = x));
    this.auth.id$.pipe(map((x) => (this.id = x))).subscribe();

    this.companyService.loadAllAsList().subscribe((x) => {
      this.clients = x.filter((f) => f.canBeClient);
      this.isClientsLoaded = true;
    });

    this.productsService.loadAllAsList().subscribe((x) => {
      this.products = x;
      this.isProductsLoaded = true;
    });

    this.columnsService.loadAll().subscribe((x) => {
      this.columns = x;
      this.isColumnsLoaded = true;
    });
  }

  checkIfDataLoaded() {
    return (
      this.isColumnsLoaded && this.isProductsLoaded && this.isClientsLoaded
    );
  }

  updateTenantNote($event: TenantNoteEvent) {
    this.tripEventService.updateTenantNote($event).subscribe({
      complete: () => {
        this.service.loadOverview().subscribe({
          complete: () => {
            this.route.navigate(['/trips']);
          },
        });
      },
    });
  }

  addPhotos($event: TripEventFile) {
    this.service.addPhoto($event.id, $event.files).subscribe({
      complete: () => {
        this.service.loadOverview().subscribe({
          complete: () => {
            this.route.navigate(['/trips']);
          },
        });
      },
    });
  }

  updatePhotoEvent($event: PhotoEventDto | PhotoModalEventDto) {
    if ($event.url) {
      this.service.changePhotoEvent($event.url).subscribe({
        complete: () => {
          this.service.loadOverview().subscribe({
            complete: () => {
              this.route.navigate(['/trips']);
            },
          });
        },
      });
    }
  }
}
