import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { User, UsersRepository } from './users.repository';

const API = '/api/users';
export type UserCreateUpdateDto = Partial<User> & { password?: string };

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService<User> {
  constructor(http: HttpClient, repo: UsersRepository) {
    super(API, http, repo);
  }

  loadDrivers(): Observable<User[]> {
    return this.http
      .get<Observable<User[]>>(`/api/usersSummary/alldivers`)
      .pipe(this.repo.track());
  }

  loadDriversDeleted(): Observable<User[]> {
    return this.http
      .get<Observable<User[]>>(`/api/usersSummary/alldivers/deleted`)
      .pipe(
        tap((res: any) => this.repo.setDeleted(res)),
        this.repo.trackDeleted()
      );
  }

  loadDriversForOrder(): Observable<User[]> {
    return this.http.get<User[]>(`/api/orders/alldrivers`).pipe(
      tap((res) => this.repo.set(res)),
      this.repo.track()
    );
  }

  loadDriversForPage(driverIds?: string[]): Observable<User[]> {
    return this.http.patch<User[]>('/api/orders/loaddrivers', driverIds);
  }

  loadDriversPage(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<PaginationData & { data: User[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: User[] }>(
        `${API}/drivers?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  reloadDriversPage(
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<(PaginationData & { data: User[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadDriversPage(data.currentPage, data.perPage);
    }
    return this.loadDriversPage(1, defaultTake);
  }

  add(user: UserCreateUpdateDto) {
    const formData = new FormData();
    formData.append('file', user.image!);

    return this.http.post<User>(API, user).pipe(
      tap((user) => this.repo.upsert(user)),
      switchMap((user) =>
        this.http.post<User>(`${API}/${user.id}/file`, formData)
      ),
      this.tapReloadPage(),
      this.repo.track('add')
    );
  }

  update(id: string, model: Partial<User>) {
    return this.http.patch<User>(`${API}/${id}`, model).pipe(
      tap((user) => this.repo.upsert(user)),
      switchMap((user) => {
        if (model.image) {
          const formData = new FormData();
          formData.append('file', model.image);
          return this.http.post<User>(`${API}/${id}/file`, formData);
        }
        return of(user);
      }),
      this.repo.trackOne(id)
    );
  }

  reloadPage(
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<(PaginationData & { data: User[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(data.currentPage, data.perPage);
    }
    return this.loadPage(1, defaultTake);
  }

  protected tapReloadPage<R>() {
    return switchMap((sourceResponse: R) =>
      combineLatest([of(sourceResponse), this.reloadPage()]).pipe(
        map(([res, _]) => res)
      )
    );
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${API}/${id}`).pipe(
      tap(() => this.repo.remove(id)),
      this.tapReloadPage(),
      this.repo.trackOne(id)
    );
  }
}
