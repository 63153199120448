import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  withActiveId,
  selectActiveEntity,
  setActiveId,
  upsertEntities,
  getEntity,
  getAllEntities,
  getActiveEntity,
} from '@ngneat/elf-entities';
import {
  withRequestsCache,
  withRequestsStatus,
  createRequestsStatusOperator,
  createRequestsCacheOperator,
  updateRequestCache,
  updateRequestsStatus,
  selectRequestStatus,
} from '@ngneat/elf-requests';
import { combineLatest, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { sortBy } from '../modules/shared/pipes/sort.pipe';
import { DayjsService } from '../modules/shared/services/dayjs.service';

export type WorkdayEventType = 'DayStart' | 'DayEnd' | 'Pause';

export interface Workday {
  id: string;
  createdAt: Date;
  workdayEvents: WorkdayEvent[];
  userName?: string;
  userId?: string;
  deletedAt?: string;
  isApproved?: boolean;
}

export class FilterOptions {
  isApproved?: boolean;
  searchWord?: string;
  dateFrom?: string;
  dateTo?: string;
  driverSelected?: string;
  clientSelected?: string;
  productSelected?: string[];
  isTripPage?: boolean;
  order?: string;
  orderColumn?: string;
  resultIds?: string[];
}

export interface WorkdayEvent {
  id: string;
  createdAt: Date;
  eventTime: Date;
  type: WorkdayEventType;
  duration?: number | null;
  workDayId: string;
  tripId?: string;
  eventLocation?: string;
}

export class WorkdaysDto {
  workdayId?: string;
  userName?: string;
  date?: Date;
  dateForShown?: string;
  startTime?: string;
  stopTime?: string;
  pause?: number;
  workTime?: string;
  workTimeShow?: string;
  isApprove?: boolean;
  userId?: string;
  userDeleted?: string;
  eventSrartId?: string;
  eventEndId?: string;
  eventPauseId?: string;
  stopDate?: string;
}

const store = createStore(
  { name: 'workdays' },
  withEntities<Workday>(),
  withActiveId(),
  withRequestsCache(),
  withRequestsStatus()
);
export const trackWorkdaysRequestsStatus = createRequestsStatusOperator(store);
export const skipWhileWorkdaysCached = createRequestsCacheOperator(store);

function comparePausesEndTime(a: WorkdayEvent, b: WorkdayEvent): number {
  return (
    a.eventTime.getTime() -
    b.eventTime.getTime() +
    60 * 1000 * ((a.duration || 0) - (b.duration || 0))
  );
}

@Injectable({ providedIn: 'root' })
export class WorkdaysRepository {
  constructor(public ngDay: DayjsService) {}

  name = store.name;

  activeWorkday$ = store.pipe(selectActiveEntity());
  workdays$ = store.pipe(selectAllEntities());
  events$ = this.workdays$.pipe(
    map((days) =>
      sortBy(
        days.reduce(
          (events, day) => events.concat(day.workdayEvents),
          [] as WorkdayEvent[]
        ),
        { parameter: { property: 'eventTime' }, direction: 'desc' }
      )
    )
  );
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  isAdding$ = store.pipe(
    selectRequestStatus(`${this.name}_add`),
    map((x) => x.value === 'pending')
  );
  isLoadingCurrent$ = store.pipe(
    selectRequestStatus(`${this.name}_current`),
    map((x) => x.value === 'pending')
  );
  isLoadingOne$ = (id: Workday['id']) =>
    store.pipe(
      selectRequestStatus(id),
      map((x) => x.value === 'pending')
    );
  activePause$ = combineLatest([this.activeWorkday$, timer(0, 1000)]).pipe(
    map(([day, _]) => {
      if (!day) {
        return null;
      }
      return day.workdayEvents
        .filter(
          (x) =>
            x.type === 'Pause' &&
            this.ngDay
              .dayjs(x.eventTime)
              .add(x.duration || 0, 'minutes')
              .isAfter(this.ngDay.dayjs())
        )
        .sort(comparePausesEndTime)
        .pop();
    })
  );

  getActiveWorkday() {
    return store.query(getActiveEntity());
  }

  getWorkday(id: string) {
    return store.query(getEntity(id));
  }

  getWorkdayByEvent(id: string) {
    return store
      .query(getAllEntities())
      .find((x) => !!x.workdayEvents.find((e) => e.id === id));
  }

  setActiveId(id: Workday['id'] | null) {
    store.update(setActiveId(id));
  }

  setWorkdays(days: Workday[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(days),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upsertWorkday(day: Workday, customStatus?: string) {
    store.update(
      updateRequestCache(day.id),
      upsertEntities([day]),
      updateRequestsStatus([customStatus ?? day.id], 'success')
    );
  }
}
