import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionOverStateComponent } from './components/session-over-state/session-over-state.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthlayoutComponent } from './layouts/authlayout/authlayout.component';
import { ApphomelayoutComponent } from './layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from './layouts/appinnerlayout/appinnerlayout.component';
import { SigninComponent } from './pages/signin/signin.component';
import { ForgetpasswordComponent } from './pages/forgetpassword/forgetpassword.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { VerifyComponent } from './layouts/authlayout/verify/verify.component';
import { FooterinfoComponent } from './components/footerinfo/footerinfo.component';
import { HeaderbackComponent } from './components/headerback/headerback.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { PagenotauthorizedComponent } from './pages/pagenotauthorized/pagenotauthorized.component';
import { TenantsCreatePageComponent } from './pages/tenants-create-page/tenants-create-page.component';
import { ProfileEditComponent } from './pages/profile-edit/profile-edit.component';
import { UsersCreatePageComponent } from './pages/users-create-page/users-create-page.component';
import { HeadermenuComponent } from './components/headermenu/headermenu.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TenantsPageComponent } from './pages/tenants-page/tenants-page.component';
import { UsersListPageComponent } from './pages/users-list-page/users-list-page.component';
import { ImpersonationModalComponent } from './components/impersonation-modal/impersonation-modal.component';
import { TenantFormComponent } from './components/tenant-form/tenant-form.component';
import { UserBasicInfoFormComponent } from './components/user-basic-info-form/user-basic-info-form.component';
import { UserHeaderImageComponent } from './components/user-header-image/user-header-image.component';
import { TenantListComponent } from './components/tenant-list/tenant-list.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LogoComponent } from './components/logo/logo.component';
import { SharedModule } from '../shared/shared.module';
import { OfflineLabelComponent } from './components/offline-label/offline-label.component';
import { PwaInstallButtonComponent } from './components/pwa-install-button/pwa-install-button.component';
import { UnsyncedChangesComponent } from './components/unsynced-changes/unsynced-changes.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { CurrentTripComponent } from './components/current-trip/current-trip.component';
import { ProductCardComponent } from './components/product-card/product-card.component';

@NgModule({
  declarations: [
    SigninComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    VerifyComponent,
    SessionOverStateComponent,
    AuthlayoutComponent,
    ApphomelayoutComponent,
    AppinnerlayoutComponent,
    FooterinfoComponent,
    HeaderbackComponent,
    PagenotfoundComponent,
    PagenotauthorizedComponent,
    TenantsCreatePageComponent,
    ProfileEditComponent,
    UsersCreatePageComponent,
    HeadermenuComponent,
    SidebarComponent,
    ProfileComponent,
    TenantsPageComponent,
    UsersListPageComponent,
    ImpersonationModalComponent,
    TenantFormComponent,
    UserBasicInfoFormComponent,
    UserHeaderImageComponent,
    TenantListComponent,
    UserCardComponent,
    UsersListComponent,
    LogoComponent,
    OfflineLabelComponent,
    PwaInstallButtonComponent,
    UnsyncedChangesComponent,
    ChangePasswordFormComponent,
    CurrentTripComponent,
    ProductCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgCircleProgressModule.forRoot(),
    SharedModule
  ],
  exports: [
    SigninComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    VerifyComponent,
    SessionOverStateComponent,
    AuthlayoutComponent,
    ApphomelayoutComponent,
    AppinnerlayoutComponent,
    FooterinfoComponent,
    HeaderbackComponent,
    PagenotfoundComponent,
    PagenotauthorizedComponent,
    TenantsCreatePageComponent,
    ProfileEditComponent,
    UsersCreatePageComponent,
    HeadermenuComponent,
    SidebarComponent,
    ProfileComponent,
    TenantsPageComponent,
    UsersListPageComponent,
    OfflineLabelComponent,
    PwaInstallButtonComponent,
    CurrentTripComponent,
    UnsyncedChangesComponent,
    ProductCardComponent

  ]
})
export class CoreModule { }
