import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { CompaniesRepository, Company } from './companies.repository';
import { PaginationData } from '@ngneat/elf-pagination';
import { SortState } from '../modules/shared/pipes/sort.pipe';

const API = '/api/companies';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService extends BaseService<Company> {
  constructor(http: HttpClient, repo: CompaniesRepository) {
    super(API, http, repo);
  }

  loadAllForGoPage() {
    return this.http.get<Company[]>(`${API}/allaslistforgopage`).pipe(
      tap((res) => this.repo.set(res)),
      this.repo.track(),
      this.repo.skipWhileCached()
    );
  }

  loadCompanies(): Observable<Company[]> {
    return this.http.get<Observable<Company[]>>(API).pipe(this.repo.track());
  }

  sortT(sortBy: SortState, take = 10, isVendor = false) {
    this.repo.setSort(sortBy);
    return this.reloadPageT(take, isVendor);
  }

  reloadPageT(
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    isVendor: boolean = false,
    search = ''
  ): Observable<(PaginationData & { data: Company[] }) | null> {
    return this.loadPageT(1, defaultTake, isVendor, search);
  }

  updateTemplates(id: string, companyIds: string[]) {
    return this.http
      .patch<Company[]>(`${API}/template`, { id: id, companyIds: companyIds })
      .pipe(
        tap((t) => this.repo.set(t)),
        this.repo.track()
      );
  }

  loadPageT(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    isVendor: boolean = false,
    search = ''
  ): Observable<PaginationData & { data: Company[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `search=${search}`,
      `direction=${sortOrder.direction}`,
      `isVendor=${isVendor}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: Company[] }>(
        `/api/companies/canbevendor?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track()
        //this.repo.skipWhilePageCached(page)
      );
  }

  exportByCompany(
    canBeClient: boolean,
    canBeVendor: boolean
  ): Observable<string> {
    return this.http
      .get<Blob>(`${API}/csv/${canBeClient}/${canBeVendor}`, {
        responseType: 'blob' as any,
      })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }
}
