<div class="row mb-4" *ngVar="user$ | async as user">
  <div class="col">
    <h2>
      <ng-container i18n="Label for trips log">Trips log</ng-container>
      <span class="text-muted" *ngIf="user">
        <i class="bi bi-person ms-3 me-1"></i>
        <ng-container *ngIf="user.name || user.surname"
          >{{ user.name }} {{ user.surname }} ({{ user.email }})</ng-container
        >
        <ng-container *ngIf="!user.name && !user.surname">{{
          user.email
        }}</ng-container>
      </span>
    </h2>
  </div>
  <!--<div class="col-auto d-flex align-items-center" *ngIf="auth.isTenantAdmin$ | async">
    <app-import-export [filenamePrefix]="(user?.email || 'my') + '-trips'"
                       [exportFactory]="exportFactory"></app-import-export>
  </div>-->
</div>
<div class="row mb-4">
  <div class="col" *ngIf="(repo.trips$ | async) && (auth.id$ | async)">
    <app-spinner
      *ngIf="!checkIfDataLoaded()"
      [showWithText]="true"
    ></app-spinner>
    <app-sort-table
      *ngIf="checkIfDataLoaded()"
      (tenantNoteEmmiter)="updateTenantNote($event)"
      (tenantNoteEmmiter)="updateTenantNote($event)"
      (usualPhotoEmmiter)="updatePhotoEvent($event)"
      (weighPhotoEmmiter)="updatePhotoEvent($event)"
      (modalPhotoEmmiter)="updatePhotoEvent($event)"
      (takePhotos)="addPhotos($event)"
      [isUserTripsPage]="true"
      [dateType]="dateType"
      [products]="products"
      [isAdminPage]="false"
      [clients]="clients"
      [drivers]="drivers"
      [timeLogs]="repo.trips$"
      [driverId]="auth.id$ | async"
      [columns]="columns"
    >
    </app-sort-table>
  </div>
</div>
